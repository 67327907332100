import * as React from 'react';
import { observer } from 'mobx-react';
import DialogContent from '@material-ui/core/DialogContent';
import AddProductCodeMapForm from 'src/ProductMaps/AddProductCodeMapForm';

interface Props {}

@observer
export class ModalBody extends React.Component<Props, {}> {
  public render(): React.ReactNode {
    return (
      <DialogContent className="modal-content-root">
        <AddProductCodeMapForm />
      </DialogContent>
    );
  }
}

export default ModalBody;
