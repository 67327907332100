import * as React from 'react';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from '../../Shared/Constants';
import { version } from '../../../package.json';
import SettingStore from './SettingStore';
import { Switch, FormLabel } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { OptionModel, VulcanGroupSelect } from '@vulcan/vulcan-materialui-theme/build/components';

interface Props {
  settingStore?: SettingStore;
}

@inject('settingStore')
@observer
export class SettingFooter extends React.Component<Props, {}> {
  public async componentDidMount(): Promise<void> {
    await this.props.settingStore!.init();
  }

  private handleSaleBranchChange = (selectedBranch: OptionModel[]): void => {
    this.props.settingStore!.selectedSaleBranchChanged(selectedBranch);
  };

  public render(): JSX.Element {
    const { settingStore } = this.props;
    return (
      <>
        <div className="setting-container">
          <div className="title">Settings </div>
          <div className="section">
            <span>
              <p className='section-title'>
                Sidebar Mini Mode
                <Switch
                  color="primary"
                  checked={settingStore!.miniActive}
                  onChange={settingStore!.handelMiniModeToggle}
                />
              </p>
            </span>
          </div>
          <div className="section">
            <span>
              <p className='section-title'>
                Details default Collapse All
                <Switch
                  color="primary"
                  checked={settingStore!.isDetailsPageCollapseAllByDefault}
                  onChange={settingStore!.handelDetailsPageCollapseAllByDefault}
                />
              </p>
            </span>
          </div>
          <div className="section">
            <span>
              <p className='section-title'>
                Details Filter by Branch
                <Switch
                  color="primary"
                  checked={settingStore!.isDetailsPageFilterBySalesBranch}
                  onChange={settingStore!.handelDetailsPageFilterBySalesBranchToggle}
                />
              </p>
            </span>
          </div>
          <div className="section">
            <FormLabel className='section-title'>User Default Branch</FormLabel>
            <VulcanGroupSelect
              placeholder="All Branches"
              options={settingStore!.allBranchDataSource}
              handleChange={this.handleSaleBranchChange}
              isMulti={true}
              closeMenuOnSelect={false}
              isLoading={settingStore!.loading}
              isDisabled={false}
              allowSelectAll={true}
              selected={settingStore!.selectedSaleBranchCodes}
              badgeShowMaxSelectionNumber={1}
            />
          </div>
        </div>

        <div className="setting-footer-container">
          <div className="title">About</div>
          <Icon className="logo" icon={IconResources.WebsiteLogo} />
          <div className="details">
            <span>Purchasing Website</span>
            <span>Version: {version}</span>
            <span>2019-{this.props.settingStore!.CurrentYear} © Vulcan Steel Ltd.</span>
          </div>
        </div>
      </>
    );
  }
}
export default SettingFooter;
