import { useState, useCallback } from 'react';
import { getTenantUrl, getTokenAsync } from 'src/App/Infrastructure/API';

export interface SearchRequest {
  search: string;
  filter: string;
  skip: number;
  top: number;
  orderBy: string;
}

export interface ProcurementLineResponse {
  confirmationDate: string;
  confirmationNumber: string;
  createdBy: string;
  createdDateTimeUtc: string;
  dutyAccrualTotal: number;
  etaDate: string;
  etaStatus: string;
  etdDate: string;
  heraAccrualTotal: number;
  inwardsFreightAccrualTotal: number;
  lineId: string;
  lineNumber: string;
  lineState: string;
  modifiedBy: string;
  modifiedDateTimeUtc: string;
  originalConfirmationEtaDate: string;
  otherAccrualTotal: number;
  overseasFreightAccrualTotal: number;
  product: {
    class: string;
    code: string;
    description: string;
    id: string;
    size: string;
  };
  purchasedStockQuantity: number;
  purchasedWeightTotalKG: number;
  rebateTotal: number;
  remainingStockQuantity: number;
  remainingWeightTotalKG: number;
}

export interface ProcurementOrderResponse {
  procurementOrderDocumentNumber: string;
  line: ProcurementLineResponse;
}

export interface ProcurementOrdersResponse {
  attention: { email: string; name: string };
  branchCode: string;
  countryOfOrigin: string;
  currencyCode: string;
  exchangeRate: number;
  incoTerms: string;
  paymentTerms: string;
  purchaseOrderDocumentNumber: string;
  shippingMarks: string;
  status: string;
  vendorCode: string;
  warehouseId: string;
}

export interface WarehouseResponse {
  code: string;
  id: string;
  name: string;
}

export interface ProcurementViewResponse {
  facets: {};
  relatedData: {
    branches;
    procurementOrders: ProcurementOrdersResponse;
    vendors;
    warehouses: WarehouseResponse;
  };
  results: ProcurementOrderResponse[];
  totalCount: number;
}

export const usePurchaseingApi = () => {
  const [loading, setLoading] = useState(true);

  const procurementViewSearchAsync = useCallback(
    async (searchRequest: Partial<SearchRequest>): Promise<ProcurementViewResponse | null> => {
      setLoading(true);
      const purchasingUrl = getTenantUrl(window.appSettings.purchasingApiUrl);
      const token = await getTokenAsync();

      const filter = searchRequest.filter ?? '';
      const searchText = searchRequest.search ?? '';
      const orderBy = searchRequest.orderBy ?? '';
      const skip = searchRequest.skip ?? 0;
      const top = searchRequest.top ?? 1000;

      const url = `${purchasingUrl}/api/v12/procurementLines/search`;
      const filterText = `(headerInfo/status ne 'Cancelled') and (line/lineState ne 'Deleted') ${
        filter.length > 0 ? `and ${filter}` : ''
      }`;
      const querySettings = { filterText, searchText, orderBy };
      const pageSettings = { skip, top };
      const facetsToRetrieve = [
        'headerInfo/warehouse/code',
        'headerInfo/vendor/code',
        'line/lineState',
        'line/createdDateTimeUtc,interval:month',
        'line/etdDate,interval:month',
        'line/etaDate,interval:month',
      ];

      const request = { querySettings, pageSettings, facetsToRetrieve };

      try {
        const response = await fetch(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${token}`,
          },
          body: JSON.stringify(request),
        });
        const data =
          response.status === 200 ? ((await response.json()) as ProcurementViewResponse) : null;
        return data;
      } catch (e) {
        return null;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { procurementViewSearchAsync, loading };
};
