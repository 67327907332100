import * as React from 'react';
import { observable, action } from 'mobx';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { ModalHeader } from 'src/ProductMaps/Modal/ModalHeader';
import { ModalBody } from 'src/ProductMaps/Modal/ModalBody';
import { AddProductMapModalFooter } from 'src/ProductMaps/Modal/ModalFooter';
import { observer, inject } from 'mobx-react';
import { withWidth, Theme, withStyles, WithStyles } from '@material-ui/core';
import { WithWidth } from '@material-ui/core/withWidth';
import { ProductMapsStore } from 'src/ProductMaps/ProductMapsStore';

const productMapModalStyles = (theme: Theme) => ({
  modalWithBreakPoints: {
    [theme.breakpoints.up('xs')]: {
      minWidth: 'unset',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '800px',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '800px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '800px',
    },
  },
});

function Transition(props): JSX.Element {
  return <Slide direction="down" {...props} />;
}

interface Props extends WithStyles<typeof productMapModalStyles>, WithWidth {
  open: boolean;
  handleAddProductMap: Function;
  handleClose: Function;
  productMapsStore?: ProductMapsStore;
}

@inject('productMapsStore')
@observer
class WrappedModal extends React.Component<Props, {}> {
  @observable public progressbar = false;
  @observable public deleteProgressbar = false;

  public render(): React.ReactNode {
    const { classes, width } = this.props;
    const fullScreen = width === 'xs' || width === 'sm';
    const modalWrapperClass = width === 'xs' || width === 'sm' ? '' : 'product-map-modal';
    const disabled = !this.props.productMapsStore!.activeModel.isValid;

    return (
      <Dialog
        scroll="paper"
        className={modalWrapperClass}
        fullScreen={fullScreen}
        maxWidth={false}
        classes={{ paper: classes.modalWithBreakPoints }}
        open={this.props.open}
        disableBackdropClick={false}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={this.handleClose}
        aria-labelledby="productmap-modal-slide-title"
        aria-describedby="productmap-modal-slide-description"
      >
        <ModalHeader headerText="Product Code Map" handleClose={this.handleClose} />

        <ModalBody />

        <AddProductMapModalFooter
          disabled={disabled}
          handleAddMapClick={this.handleAddMapClick}
          showProgressBar={this.progressbar}
        />
      </Dialog>
    );
  }

  @action showProgressbar = (): void => {
    this.progressbar = true;
  };

  @action hideProgressbar = (): void => {
    this.progressbar = false;
  };

  private handleClose = (): void => {
    return this.props.handleClose();
  };

  private handleAddMapClick = async (): Promise<void> => {
    this.showProgressbar();
    await this.props.handleAddProductMap(this.props.productMapsStore!.activeModel);
    this.hideProgressbar();
    this.props.handleClose();
  };
}

const StyledProductMapModal = withStyles(productMapModalStyles)(WrappedModal);
const ProductMapModal = withWidth()(StyledProductMapModal);
export default ProductMapModal;
