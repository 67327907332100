import React, { useCallback, useEffect, useState } from 'react';
import { GridItem } from '@vulcan/vulcan-materialui-theme';
import { InputLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import { utcTimetoLocalTime, toLocalTime } from 'src/Shared/Utils';

export interface FacetDateItemProps {
  useUTC: boolean;
  facetItems: { count: number; value: string }[];
  title: string;
  field: string;
  onFilterChange: Function;
}

export const FacetDateItem = (props: FacetDateItemProps): JSX.Element => {
  const { useUTC, facetItems, title, field, onFilterChange } = props;
  const defaultFacetItemSize = 5;
  const [showMore, setShowMore] = useState(false);
  const [facetItemSize, setFacetItemSize] = useState(defaultFacetItemSize);

  useEffect(() => {
    if (facetItems && facetItems.length > defaultFacetItemSize) {
      setShowMore(true);
    }
  }, [facetItems, setShowMore]);

  const handleLineDateFacet = useCallback(
    (value) => {
      const date = moment(value).format();
      const nextMonthDate = moment(value).add(1, 'month').format();
      const filter = `((${field} ge ${date}) and (${field} lt ${nextMonthDate}))`;
      onFilterChange(filter);
    },
    [onFilterChange, field]
  );

  const handleShowMore = useCallback(() => {
    setShowMore(false);
    setFacetItemSize(facetItems.length);
  }, [facetItems.length]);

  const handleLessMore = useCallback(() => {
    setShowMore(true);
    setFacetItemSize(defaultFacetItemSize);
  }, []);

  return facetItems.length > 0 ? (
    <GridItem lg={12} xs={2}>
      <div className="label-panel">
        <InputLabel className="label">{title}</InputLabel>
      </div>
      {facetItems
        .reverse()
        .slice(0, facetItemSize)
        .map((x) => (
          <div className="count-row" key={x.value}>
            <Typography
              className="item"
              variant="caption"
              gutterBottom={true}
              onClick={(): void => handleLineDateFacet(x)}
            >
              {useUTC
                ? utcTimetoLocalTime(x.value).format('MMMM YYYY')
                : toLocalTime(x.value).format('MMMM YYYY')}
            </Typography>
            <span>({x.count})</span>
          </div>
        ))}
      {showMore === true && facetItems.length > defaultFacetItemSize && (
        <div className="count-row">
          <Typography
            className="item"
            variant="caption"
            gutterBottom={true}
            onClick={handleShowMore}
          >
            show more options
          </Typography>
        </div>
      )}
      {showMore === false && facetItems.length > defaultFacetItemSize && (
        <div className="count-row">
          <Typography
            className="item"
            variant="caption"
            gutterBottom={true}
            onClick={handleLessMore}
          >
            show less options
          </Typography>
        </div>
      )}
    </GridItem>
  ) : (
    <></>
  );
};
