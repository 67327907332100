import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, Telemetry } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' } as any);
const reactPlugin = new ReactPlugin();

let ai: ApplicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

export const configureApplicationInsights = (user) => {
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: 'a4820e56-de51-4e91-b641-f195107ccf39',
      connectionString: window.appSettings.applicationInsightsKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
    },
  });

  ai.loadAppInsights();
  // T O D O: Work out what field is meant to be the account Id, emails are unique so they'll work as a user ID.
  ai.setAuthenticatedUserContext(user.userName, undefined, true);
  ai.addTelemetryInitializer((item) => {
    if (
      item.name === Telemetry.PageView.envelopeType ||
      item.name === Telemetry.PageViewPerformance.envelopeType
    ) {
      item.baseData!.name = setItemName(item.baseData!.uri);
    }
    return true;
  });
};

const setItemName = (uri: string) => {
  const pathVals = uri.split('/');
  // we want the last val of the path to determine what name to give the page.
  switch (pathVals[pathVals.length - 1]) {
    case 'draftinvoices':
      return 'Draft Invoices';
    case 'matchedinvoices':
      return 'Matched Invoices';
    case 'productmap':
      return 'Product Map';
    case 'reorder':
      return 'Reorder';
    case 'stock':
      return 'Stock';
    case 'request':
      return 'Request';
    case 'history':
      return 'History';
    case 'procurement-lines':
      return 'Procurement Lines';
    case 'index':
      return 'Tenant Selection';
    default:
      // we're not told where it's coming from, so just say it's somewhere in the website
      return 'Purchasing Website';
  }
};

const withAppInsights = (component) => withAITracking(reactPlugin, component);
export default withAppInsights;
export const appInsights = () => ai;
