import React, { useState } from 'react';
import { TextField, createStyles, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@vulcan/vulcan-materialui-theme';

const useStyles = makeStyles(
  createStyles({
    searchBar: {
      display: 'flex',
    },
    searchInput: {
      backgroundColor: 'white',
    },
    searchButton: {
      margin: '16px 0px 8px 8px',
    },
  })
);

export interface SearchBarProps {
  onSearch: Function;
}

export const SearchBar = (props: SearchBarProps): JSX.Element => {
  const { onSearch } = props;
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  return (
    <form
      className={classes.searchBar}
      onSubmit={(e) => {
        onSearch(searchText);
        e.preventDefault();
      }}
    >
      <TextField
        className={classes.searchInput}
        label="Search field"
        type="search"
        margin="normal"
        fullWidth={true}
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Button
        className={classes.searchButton + ' action-button'}
        type="button"
        color="warning"
        onClick={() => onSearch(searchText)}
      >
        <SearchIcon /> Search
      </Button>
    </form>
  );
};
