import {
  SupplierInvoiceResponse,
  SupplierInvoiceLineItemResponse,
  SupplierInvoiceState,
} from '@vulcan/purchasing-api-client/lib/models';
import { computed } from 'mobx';
import InvoiceType from './InvoiceType';

export class SupplierInvoiceModel implements SupplierInvoiceResponse {
  public supplierInvoiceId: number;
  public supplierCode: string;
  public invoiceType: string;
  public invoiceState: InvoiceType | SupplierInvoiceState;
  public invoiceNumber: string;
  public currencyCode: string;
  public documentId: string;
  public issuedDate: string;
  public dueDate?: string | undefined;
  public lineItems: SupplierInvoiceLineItemResponse[];

  constructor(fields?: Partial<SupplierInvoiceModel>) {
    this.supplierInvoiceId = -1;
    this.supplierCode = '';
    this.invoiceType = '';
    this.invoiceState = InvoiceType.Draft;
    this.invoiceNumber = '';
    this.currencyCode = '';
    this.issuedDate = '';
    this.documentId = '';
    this.dueDate = '';
    this.lineItems = [];
    Object.assign(this, fields);
  }

  @computed public get total(): number {
    let totalAmount = 0;
    this.lineItems.forEach((x) => (totalAmount += x.totalAmount));
    return totalAmount;
  }

  @computed public get quantity(): number {
    let totalQuantity = 0;
    this.lineItems.forEach((i) => (totalQuantity += i.quantity || 0));
    return totalQuantity;
  }
}

export default SupplierInvoiceModel;
