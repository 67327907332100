import * as React from 'react';
import { Button, Icon } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from '../Shared/Constants';

interface Props {
  mode: string;
  actions: Record<string, Function>;
}

const editModes = {
  view: (props): JSX.Element => (
    <div className="actions-cell-buttons-container">
      <Button
        justIcon={true}
        round={true}
        simple={true}
        onClick={props.onEdit}
        className="actions-cell-edit-icon"
      >
        {' '}
        <Icon icon={IconResources.EDIT} />{' '}
      </Button>
      <Button justIcon={true} round={true} simple={true} onClick={props.onDelete} color="danger">
        {' '}
        <Icon icon={IconResources.CROSS} />{' '}
      </Button>
    </div>
  ),
  edit: (props): JSX.Element => (
    <div className="actions-cell-buttons-container">
      <Button
        className="actions-cell-save-icon"
        justIcon={true}
        round={true}
        simple={true}
        onClick={props.onSubmit}
      >
        {' '}
        <Icon icon={IconResources.TICK} />{' '}
      </Button>
      <Button
        className="actions-cell-close-icon"
        justIcon={true}
        round={true}
        simple={true}
        onClick={props.onCancel}
      >
        {' '}
        <Icon icon={IconResources.CROSS} />{' '}
      </Button>
    </div>
  ),
};

export default function ActionsCell(props: Props): JSX.Element {
  const {
    mode,
    actions: { onEdit, onCancel, onSubmit, onDelete },
  } = props;
  const Buttons = editModes[mode];

  return <Buttons onSubmit={onSubmit} onEdit={onEdit} onCancel={onCancel} onDelete={onDelete} />;
}
