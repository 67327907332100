import * as React from 'react';
import { Icon, Button, modalStyle } from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import RequestStore from './RequestStore';
import { StockTableStore } from '../Stock/StockTable/StockTableStore';

interface Props extends WithStyles<typeof modalStyle> {
  requestStore?: RequestStore;
  stockTableStore?: StockTableStore;
}

@inject('requestStore', 'stockTableStore')
@observer
class RequestConfirmationDialog extends React.Component<Props> {
  public render(): React.ReactNode {
    const { classes } = this.props;
    const confirmRequest = async (): Promise<void> => {
      this.props.requestStore!.hideConfirmDialog();
      await this.props.requestStore!.saveAmendedQuantity(false);
      await this.props.requestStore!.confirmRequest();
      await this.props.stockTableStore!.reloadTableControl();
    };

    return (
      <Dialog open={this.props.requestStore!.isConfirmDialogOpen} onClose={this.handleClose}>
        <DialogTitle className={classes.modalHeader} disableTypography={true}>
          <Button
            className={classes.modalCloseButton}
            justIcon={true}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={this.handleClose}
          >
            <Icon className={classes.modalClose} icon={IconResources.CROSS} />
          </Button>
          <h4 className={classes.modalTitle}>Are you sure you want to ‘Confirm’ this request?</h4>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once confirmed, Stock & Reorder Request screens will be reset and data will be saved as
            a report to the ‘Request History’ tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmRequest} color="warning">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    this.props.requestStore!.hideConfirmDialog();
  };
}

export default withStyles(modalStyle)(RequestConfirmationDialog);
