import { BaseStore } from './Infrastructure/BaseStore';
import { AuthStore } from '.';

export class UserProfileStore extends BaseStore {
  private authStore: AuthStore;

  constructor(authStore: AuthStore) {
    super();
    this.authStore = authStore;
  }

  public get userName(): string {
    return this.authStore && this.authStore.getAuthContext()
      ? this.authStore.getAuthContext().getCachedUser().profile.name
      : '';
  }
}

export default UserProfileStore;
