import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { sweetAlertStyle } from '@vulcan/vulcan-materialui-theme';
import SweetAlert from 'react-bootstrap-sweetalert';

interface Props extends WithStyles<typeof sweetAlertStyle> {
  title: string;
  show: boolean;
  message?: string;
  onSuccess: Function;
  hideAlert: Function;
}

export class WrappedSweetAlert extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    title: 'Are you sure?',
  };

  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <SweetAlert
        show={this.props.show}
        warning={true}
        style={{ display: 'block', marginTop: '-100px' }}
        title={this.props.title}
        // tslint:disable-next-line: jsx-no-lambda
        onConfirm={(): void => this.onSuccess()}
        // tslint:disable-next-line: jsx-no-lambda
        onCancel={(): void => this.hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
        cancelBtnCssClass={classes.button + ' ' + classes.warning}
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        showCancel={true}
      >
        {this.props.message || ''}
      </SweetAlert>
    );
  }

  private onSuccess = (): void => {
    this.props.onSuccess();
  };

  private hideAlert = (): void => {
    this.props.hideAlert();
  };
}

const CustomSweetAlert = withStyles(sweetAlertStyle)(WrappedSweetAlert);
export default CustomSweetAlert;
