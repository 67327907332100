import React, { FunctionComponent, useState } from 'react';
import Input from '@material-ui/core/Input';

interface Props {
  value: string;
  onChange: Function;
}

export const NumberInput: FunctionComponent<Props> = (prop: Props): JSX.Element => {
  const { value, onChange } = prop;
  const [numberValue, setNumberValue] = useState(value);

  return (
    <Input
      type="number"
      inputProps={{ min: 1, max: 2147483646 }}
      value={numberValue}
      onBeforeInput={(event: React.CompositionEvent<HTMLInputElement>): void => {
        const onlyNumbers = /^\d+$/;
        if (!onlyNumbers.test(event.data)) {
          event.preventDefault();
        }
      }}
      onChange={(e): void => {
        const newValue = e.target.value;
        if (Number(newValue) > 0 || !newValue) {
          setNumberValue(newValue);
          onChange(newValue);
        }
      }}
    />
  );
};
