import * as React from 'react';
import SupplierInvoiceStore from '../SupplierInvoiceStore';
import { observer, inject } from 'mobx-react';
import SupplierInvoiceModel from '../SupplierInvoiceModel';
import { List } from '@material-ui/core';
import InvoiceType from '../InvoiceType';
import InvoiceCard from './InvoiceCard';
import LoadMoreData from '../Shared/LoadMoreData';

interface InvoiceCardListProps {
  supplierInvoiceStore?: SupplierInvoiceStore;
  handleInvoiceClick: Function;
  type: InvoiceType;
  show: boolean;
}

@inject('supplierInvoiceStore')
@observer
class InvoiceCardList extends React.Component<InvoiceCardListProps, {}> {
  private loadingMoreData: boolean;

  constructor(props: InvoiceCardListProps) {
    super(props);
    this.loadingMoreData = false;
  }

  public componentDidUpdate(prevProps: InvoiceCardListProps): void {
    if (this.props.type !== prevProps.type) {
      this.loadingMoreData = false;
    }
  }

  public render(): JSX.Element | null {
    if (!this.props.show) {
      return null;
    }
    let invoices: SupplierInvoiceModel[] = [];
    if (this.props.supplierInvoiceStore!.searching) {
      invoices = this.props.supplierInvoiceStore!.supplierInvoicesSearchResults;
    } else {
      if (this.props.type === InvoiceType.Draft) {
        invoices = this.props.supplierInvoiceStore!.draftSupplierInvoices;
      } else {
        invoices = this.props.supplierInvoiceStore!.matchedSupplierInvoices;
      }
    }

    invoices = invoices.sort(this.props.supplierInvoiceStore!.applyFilters);

    const showLoadMoreButton =
      this.props.supplierInvoiceStore!.currentPage < this.props.supplierInvoiceStore!.pages &&
      !this.props.supplierInvoiceStore!.searching;

    const res =
      invoices.length > 0 &&
      invoices.map((invoice: SupplierInvoiceModel, index) => {
        const selectedInvoice = this.props.supplierInvoiceStore!.selectedInvoice;
        const isSelected =
          (selectedInvoice && selectedInvoice.supplierInvoiceId === invoice.supplierInvoiceId) ||
          false;
        return (
          <InvoiceCard
            isSelected={isSelected}
            invoice={invoice}
            key={index}
            index={index}
            handleInvoiceClick={this.props.handleInvoiceClick}
            animate={this.loadingMoreData}
          />
        );
      });

    return (
      <List>
        {res}
        <LoadMoreData
          isLoading={this.props.supplierInvoiceStore!.isLoading}
          show={showLoadMoreButton}
          loadData={this.handleLoadMoreDataClick}
        />
      </List>
    );
  }

  private handleLoadMoreDataClick = async (): Promise<void> => {
    this.loadingMoreData = true;
    await this.props.supplierInvoiceStore!.loadMoreData(this.props.type);
  };
}

export default InvoiceCardList;
