import BaseStore from 'src/App/Infrastructure/BaseStore';
import { action, observable } from 'mobx';
import { StringResources } from 'src/Shared/Constants/StringResources';
import {
  ProductCodeMapSearchResponse,
  ProductCodeMapsSearchProductCodeMapsOptionalParams,
  ProductCodeMapResponse,
} from '@vulcan/purchasing-api-client/esm/models';
import ProductCodeMapModel from './ProductCodeMapModel';
import { Filter } from 'react-table';
import AlertType from 'src/Shared/Alert/AlertType';

export class ProductMapsStore extends BaseStore {
  @observable public activeModel: ProductCodeMapModel;
  @observable public snackbar: boolean;
  @observable public snackbarType: AlertType;
  @observable public snackbarMessage: string;

  constructor() {
    super();
    this.activeModel = new ProductCodeMapModel();

    this.snackbar = false;
    this.snackbarType = AlertType.success;
    this.snackbarMessage = '';
  }

  @action public showSnackbar = (type: AlertType, message: string): void => {
    this.snackbarType = type;
    this.snackbarMessage = message;
    this.snackbar = true;
    // hide the snackbar after 2 seconds
    setTimeout(() => this.hideSnackbar(), 2000);
  };

  @action public hideSnackbar(): void {
    this.snackbar = false;
  }

  @action public setActiveModel(model?: ProductCodeMapModel): void {
    this.activeModel = model || new ProductCodeMapModel();
  }

  public async addProductMap(model: ProductCodeMapModel): Promise<ProductCodeMapResponse | null> {
    this.setIsLoading(true);
    try {
      if (!model.isValid) {
        throw new Error(StringResources.InvalidProductMapModel);
      }
      const client = await this.getPurchasingClient();
      const response = await client.productCodeMaps.createProductCodeMap(model);
      this.setIsLoading(false);
      this.showSnackbar(AlertType.success, StringResources.AddedProductCodeMap);
      return JSON.parse(response._response.bodyAsText) as ProductCodeMapResponse;
    } catch (e) {
      this.setIsLoading(false);
      this.showSnackbar(AlertType.danger, StringResources.ErrorAddingProductMap);
      this.log(StringResources.ErrorAddingProductMap, e as Error);
      return null;
    }
  }

  public async updateProductMap(
    model: ProductCodeMapModel
  ): Promise<ProductCodeMapResponse | null> {
    this.setIsLoading(true);
    try {
      if (!model.isValid || !model.productCodeMapId) {
        throw new Error(StringResources.InvalidProductMapModel);
      }
      const client = await this.getPurchasingClient();
      const response = await client.productCodeMaps.updateProductCodeMap(
        model.productCodeMapId!,
        model
      );
      this.setIsLoading(false);
      this.showSnackbar(AlertType.success, StringResources.EditedProductCodeMap);
      return JSON.parse(response._response.bodyAsText) as ProductCodeMapResponse;
    } catch (e) {
      this.setIsLoading(false);
      this.showSnackbar(AlertType.danger, StringResources.ErrorEditingProductMap);
      this.log(StringResources.ErrorEditingProductMap, e as Error);
      return null;
    }
  }

  @action public async deleteProductMap(productCodeMapId: number): Promise<void> {
    this.setIsLoading(true);
    try {
      const client = await this.getPurchasingClient();
      await client.productCodeMaps.deleteProductCodeMap(productCodeMapId);
      this.setIsLoading(false);
      this.showSnackbar(AlertType.success, StringResources.RemovedProductCodeMap);
    } catch (e) {
      this.setIsLoading(false);
      this.showSnackbar(AlertType.danger, StringResources.ErrorDeletingProductCodeMap);
      this.log(`${StringResources.ErrorDeletingProductCodeMap}`, e as Error, { productCodeMapId });
    }
  }

  @action public async searchProductMaps(
    filtered: Filter[],
    pageSize: number,
    page: number,
    count?: boolean
  ): Promise<ProductCodeMapSearchResponse> {
    this.setIsLoading(true);
    let result: ProductCodeMapSearchResponse;

    try {
      const options: ProductCodeMapsSearchProductCodeMapsOptionalParams = {
        query: undefined,
        skip: pageSize * page,
        top: pageSize,
        count: count || true,
      };

      if (filtered.length > 0 && filtered[0].value) {
        options.query = filtered[0].value;
      }
      const client = await this.getPurchasingClient();
      const response = await client.productCodeMaps.searchProductCodeMaps(options);
      result = JSON.parse(response._response.bodyAsText) as ProductCodeMapSearchResponse;
    } catch (e) {
      result = {
        skip: 0,
        top: 0,
        productCodeMaps: [],
        totalResults: 0,
      } as ProductCodeMapSearchResponse;
      this.log(`${StringResources.ErrorRetrievingData}`, e as Error);
    }

    this.setIsLoading(false);
    return result;
  }
}

export default ProductMapsStore;
