import { startCase } from 'lodash';

export function toFixedNumber(value: number | undefined, precision: number): number {
  const p1 = Math.pow(10, precision + 1);
  const p2 = Math.pow(10, precision);
  return value ? Math.round((value * p1) / 10) / p2 : 0;
}

export function roundForDisplay(value: number): number {
  return value >= 100 ? toFixedNumber(value, 0) : toFixedNumber(value, 2);
}

// Safari not supported e.g. NZ$12,232.32
export function toNewZealandCurrency(value: number): string {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'NZD' }).format(value);
}

// Safari not supported e.g. 12,232.32 KG
export function tokilograms(value: number): string {
  return new Intl.NumberFormat().format(value) + ' KG';
}

// Safari not supported e.g. 12,232.32 m
export function toMeters(value: number): string {
  return new Intl.NumberFormat().format(value) + ' m';
}

// Safari not supported e.g. 12,232.32 KG
export function toCurrency(value: number): string {
  return value === 0 ? '-' : '$' + new Intl.NumberFormat().format(value);
}

export function localeCompare(a: string, b: string): number {
  return a.localeCompare(b, undefined, { ignorePunctuation: true, sensitivity: 'base' });
}

export function formatEmailAsName(email?: string): string {
  if (!email) {
    return '';
  }
  const emailUsername = email.substring(0, email.lastIndexOf('@'));
  const emailUsernameWithSpaces = emailUsername.replace('.', ' ');
  return startCase(emailUsernameWithSpaces);
}

export function dashIfZero(value: number): string {
  return value === 0 ? '-' : value.toString();
}

export function stringContainsFilterMethod(
  filter: { id: string; value: string },
  row: any
): boolean {
  return row[filter.id].toLowerCase().includes(filter.value.trim().toLowerCase());
}

// Safari not supported
export function toNumberFormat(value: number): string {
  if (value === 0) {
    return '-';
  }
  return value ? new Intl.NumberFormat().format(value) : '';
}
