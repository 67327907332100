export class RequestHistoryTableDataRowModel {
  public id = 0;
  public reportName = '';
  public confirmedByDate: string | undefined;
  public confirmedBy = '';

  constructor(fields?: Partial<RequestHistoryTableDataRowModel>) {
    Object.assign(this, fields);
  }
}

export default RequestHistoryTableDataRowModel;
