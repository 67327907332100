import React from 'react';
import { CellInfo } from 'react-table';
import { ExportableTableColumn } from '@vulcan/component-lib/dist/utils';
import { formatDisplayDate, formatDisplayUTCtoLocalTime } from 'src/Shared/Utils/date-utils';
import { ProcurementLineRow } from './Model/ProcurementLineRow';

export const exportableTableColumns = (): ExportableTableColumn<ProcurementLineRow>[] => {
  const exportColumnDefault = (value: string | number | object): string | number | Date | object =>
    value;
  const exportTypeGeneral = '';
  const exportTypeDate = 'd/mm/yyyy'; // bad hard code here, should find a way to use window.appSettings.excelDateFormat;
  const exportColumnDate = (value: string | number | object): string | number | Date =>
    value ? formatDisplayDate(value.toString()) : '';

  const rightAlignHeaderStyle = { display: 'flex', justifyContent: 'flex-end' };
  const rightAlignStyle = { whiteSpace: 'unset', justifyContent: 'flex-end' };

  return [
    {
      column: {
        id: 'line/lineState',
        Header: 'Status',
        accessor: 'Status',
        width: 120,
        sortable: true,
        Cell: (cell: CellInfo): JSX.Element => (
          <div
            className="purchase-line-status-cell"
            style={{ backgroundColor: `${cell.original.StatusColor}` }}
          >
            {cell.value}
          </div>
        ),
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'PO #',
        accessor: 'PurchaseOrderNumber',
        width: 70,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        id: 'headerInfo/vendor/code',
        Header: 'Supplier',
        accessor: 'SupplierCode',
        width: 90,
        sortable: true,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'User',
        accessor: 'User',
        width: 80,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        id: 'headerInfo/warehouse/code',
        Header: 'Whse',
        accessor: 'Warehouse',
        width: 80,
        sortable: true,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'Class',
        accessor: 'Class',
        width: 70,
        Cell: (cell: CellInfo): JSX.Element => (
          <div
            className="product-class-cell"
            style={{ backgroundColor: `${cell.original.StockClassColor}` }}
          >
            {cell.value}
          </div>
        ),
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'Product Code',
        accessor: 'ProductCode',
        width: 200,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'Description',
        accessor: 'Description',
        width: 200,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'Qty',
        accessor: 'Quantity',
        width: 70,
        headerStyle: rightAlignHeaderStyle,
        style: rightAlignStyle,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        Header: 'Ton',
        accessor: 'Ton',
        width: 70,
        headerStyle: rightAlignHeaderStyle,
        style: rightAlignStyle,
      },
      exportColumn: exportColumnDefault,
      exportType: exportTypeGeneral,
    },
    {
      column: {
        id: 'line/createdDateTimeUtc',
        Header: 'Creation Date',
        accessor: 'CreationDate',
        width: 140,
        headerStyle: rightAlignHeaderStyle,
        style: rightAlignStyle,
        sortable: true,
        Cell: (cell: CellInfo): string => formatDisplayUTCtoLocalTime(cell.value),
      },
      exportColumn: exportColumnDate,
      exportType: exportTypeDate,
    },
    {
      column: {
        id: 'line/etdDate',
        Header: 'ETD Date',
        accessor: 'ETDDate',
        width: 110,
        headerStyle: rightAlignHeaderStyle,
        style: rightAlignStyle,
        sortable: true,
        Cell: (cell: CellInfo): string => formatDisplayUTCtoLocalTime(cell.value),
      },
      exportColumn: exportColumnDate,
      exportType: exportTypeDate,
    },
    {
      column: {
        id: 'line/etaDate',
        Header: 'ETA Date',
        accessor: 'ETADate',
        width: 110,
        headerStyle: rightAlignHeaderStyle,
        style: rightAlignStyle,
        sortable: true,
        Cell: (cell: CellInfo): string => formatDisplayUTCtoLocalTime(cell.value),
      },
      exportColumn: exportColumnDate,
      exportType: exportTypeDate,
    },
  ];
};

export const TableColumns = exportableTableColumns().map(
  (exportableTableColumn) => exportableTableColumn.column
);
