import moment from 'moment';

function coverToMoment(date: moment.Moment | string): moment.Moment {
  let dateMoment: moment.Moment;
  if (typeof date === 'string') {
    dateMoment = moment(date);
  } else {
    dateMoment = date;
  }
  return dateMoment;
}

export function isSameDay(
  date1: moment.Moment | string | undefined,
  date2: moment.Moment | string | undefined
): boolean {
  if (!date1 || !date2) {
    return false;
  }

  const date1Moment: moment.Moment = coverToMoment(date1);
  const date2Moment: moment.Moment = coverToMoment(date2);

  return date1Moment.isSame(date2Moment, 'day');
}

export function isAfterDay(
  date1: moment.Moment | string | undefined,
  date2: moment.Moment | string | undefined
): boolean {
  if (!date1 || !date2) {
    return false;
  }

  const date1Moment: moment.Moment = coverToMoment(date1);
  const date2Moment: moment.Moment = coverToMoment(date2);

  return date1Moment.isAfter(date2Moment, 'day');
}

// used .isoWeekday instead of .weekday because it doesn't depend on the locale
// (.weekday(0) can be either Monday or Sunday).
export function workdayCount(start: moment.Moment, end: moment.Moment): number {
  if (isSameDay(start, end)) {
    return 1;
  }

  // swap start and end if end date early
  if (isAfterDay(start, end)) {
    const temp = start;
    start = end;
    end = temp;
  }

  const first = start.clone().endOf('isoWeek'); // end of first week
  const last = end.clone().startOf('isoWeek'); // start of last week
  const days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7

  let wfirst = first.isoWeekday() - start.isoWeekday() + 1; // check first week total days
  if (start.isoWeekday() === 7) {
    --wfirst; // -1 if start with Sunday
  } else {
    wfirst = wfirst - 2; // -2 if start with not Sunday
  }

  let wlast = end.isoWeekday() - last.isoWeekday() + 1; // check last week total days
  if (end.isoWeekday() === 6) {
    --wlast; // -1 if end with Saturday
  }
  if (end.isoWeekday() === 7) {
    wlast = wlast - 2; // -2 if end with Sunday
  }
  return wfirst + Math.floor(days) + wlast; // get the total
}

export function formatDisplayDate(date?: string): string {
  return toFormatDate(window.appSettings.displayDateFormat, date);
}

export function toFormatDate(format: string, date?: string): string {
  return date
    ? moment(date.includes('T00:00:00') ? date.replace('T00:00:00', '') : date).format(format)
    : '---';
}

export function tableDateTimeSortMethod(a: string, b: string): number {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined) {
    return 1;
  } else if (b === undefined) {
    return -1;
  }
  return moment(a).isBefore(moment(b)) ? 1 : -1;
}

// e.g. a = 27/01/1999 and b = 28/01/1999, returns 1
//      a = 29/01/1999 and b = 28/01/1999, returns -1
//      a = 28/01/1999 and b = 28/01/1999, returns -1
//      a = undefined and b = 28/01/1999, returns 1
//      a = 28/01/1999 and b = undefined, returns -1
//      a = undefined and b = undefined, returns 0
export function tableDateSortMethod(a: string, b: string): number {
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined) {
    return 1;
  } else if (b === undefined) {
    return -1;
  }
  return moment(formatDisplayDate(a), window.appSettings.displayDateFormat).isBefore(
    moment(formatDisplayDate(b), window.appSettings.displayDateFormat)
  )
    ? 1
    : -1;
}

export function utcTimetoLocalTime(utcTime: moment.Moment | string): moment.Moment {
  return moment.utc(utcTime).local();
}

export function toLocalTime(utcTime: moment.Moment | string): moment.Moment {
  return moment(utcTime).local();
}

export function formatDisplayUTCtoLocalTime(utcTime: moment.Moment | string): string {
  return utcTime ? utcTimetoLocalTime(utcTime).format(window.appSettings.displayDateFormat) : '---';
}
