import { action, observable, runInAction } from 'mobx';
import { BaseStore } from 'src/App/Infrastructure';
import AlertType from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';
import { StockDetailTableModel } from './StockDetailTableModel';
import {
  StockLevelForReorderReportResponse,
  StockLevelForReorderReportRequest,
} from '@vulcan/inventory-api-client/lib/models';
import {
  ProductSalesHistoryRequest,
  ProductSalesHistoryResponse,
} from '@vulcan/sales-api-client/esm/models';
import { forEach, groupBy, sumBy } from 'lodash';
import moment from 'moment';
import { roundForDisplay, toFixedNumber } from 'src/Shared/Utils';
import {
  ReorderEntryResponse,
  ReorderQuantityRequest,
  RequestReorderQuantitiesListRequest,
  RequestReorderQuantityRequest,
  DeleteReorderQuantitiesListRequest,
  DeleteReorderQuantityRequest,
  MarkProductEntriesAsReviewed,
  ProcurementViewSearchRequest,
  ProcurementLinesSearchResponse,
  ProcurementLine,
} from '@vulcan/purchasing-api-client/esm/models';
import { RestResponse } from '@azure/ms-rest-js';
import { StockTableModel } from '../StockTable/StockTableModel';

export class StockDetailTableStore extends BaseStore {
  @observable public tableDataLoading = false;
  @observable public tableData: StockDetailTableModel[] = [];
  @observable public expanded = {};
  @observable public row: StockTableModel | undefined;

  @action
  public setRequestedQuantity(
    salesProfitCentreCode: string,
    index: number,
    requestedQuantity: string
  ): void {
    const targetTable = this.tableData.find((d) => d.salesBranchCode === salesProfitCentreCode);
    if (targetTable) {
      const targetTableData = targetTable.children;
      targetTableData[index] = {
        ...targetTableData[index],
        requestedQty: requestedQuantity !== '' ? Number(requestedQuantity) : null,
        isEdited: true,
      };
    }
  }

  @action
  public setComment(
    salesProfitCentreCode: string,
    index: number,
    comment: string
  ): void {
    const targetTable = this.tableData.find((d) => d.salesBranchCode === salesProfitCentreCode);
    if (targetTable) {
      const targetTableData = targetTable.children;
      targetTableData[index] = {
        ...targetTableData[index],
        comment: comment,
        isEdited: true,
      };
    }
  }

  private async saveRequestedQuantity(
    requestedQtyItems: StockDetailTableModel[]
  ): Promise<RestResponse> {
    const purchasingClient = await this.getPurchasingClient();
    const request: RequestReorderQuantitiesListRequest = {
      entries: requestedQtyItems.map(
        (item) =>
        ({
          productId: item.productId,
          branchCode: !!item.salesBranchCode ? item.salesBranchCode : item.branchCode,
          productGroupName: item.productGroupName,
          requestedQuantity: item.requestedQty,
          comment: item.comment
        } as RequestReorderQuantityRequest)
      ),
    };
    return purchasingClient.reorder.requestReorderQuantity(request);
  }

  private async deleteRequestedQuantity(
    requestedDeleteItems: StockDetailTableModel[]
  ): Promise<RestResponse> {
    const purchasingClient = await this.getPurchasingClient();
    const deleteRequest: DeleteReorderQuantitiesListRequest = {
      entries: requestedDeleteItems.map(
        (item) =>
        ({
          productId: item.productId,
          branchCode: !!item.salesBranchCode ? item.salesBranchCode : item.branchCode,
        } as DeleteReorderQuantityRequest)
      ),
    };
    return purchasingClient.reorder.deleteReorderQuantity(deleteRequest);
  }

  private async reviewedRequestedProduct(
    reviewedItems: StockDetailTableModel[]
  ): Promise<RestResponse> {
    const reviewedProducts = reviewedItems.map((item) => ({
      productId: item.productId,
      branchCode: !!item.salesBranchCode ? item.salesBranchCode : item.branchCode,
      productGroupName: item.productGroupName
    }));
    const reviewedRequest = {
      reviewedProducts: reviewedProducts,
    } as MarkProductEntriesAsReviewed;

    const purchasingClient = await this.getPurchasingClient();
    return purchasingClient.reorder.markProductEntriesAsReviewedMethod(reviewedRequest);
  }

  @action
  public async saveChangesAndMarkReviewed(): Promise<void> {
    try {
      this.tableDataLoading = true;
      const requestedQtyItems: StockDetailTableModel[] = [];
      const requestedDeleteItems: StockDetailTableModel[] = [];
      const reviewedItems: StockDetailTableModel[] = [];

      this.tableData.forEach((data) => {
        data.children.forEach((tableDataEntry) => {
          if (tableDataEntry.isEdited) {
            if (tableDataEntry.requestedQty && tableDataEntry.requestedQty > 0) {
              requestedQtyItems.push(tableDataEntry);
            } else {
              requestedDeleteItems.push(tableDataEntry);
            }
          }

          if (tableDataEntry.showRequestedQtyInput) {
            reviewedItems.push(tableDataEntry);
          }
        });
        const requestedQty = sumBy(data.children, (d) => d.requestedQty || 0);
        data.requestedQty = requestedQty > 0 ? requestedQty : null;
      });

      const requestedQtyPromise = this.saveRequestedQuantity(requestedQtyItems);
      const requestedDeletePromise = this.deleteRequestedQuantity(requestedDeleteItems);
      await Promise.all([requestedQtyPromise, requestedDeletePromise]);
      await this.reviewedRequestedProduct(reviewedItems);
      this.showAlert(AlertType.success, StringResources.SavedReorderQuantity);
    } catch (e) {
      this.log(StringResources.ErrorSavingReorderQuantityException, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorSavingReorderQuantityException);
    } finally {
      runInAction(() => {
        this.tableDataLoading = false;
      });
    }
  }

  private async getStockLevelData(
    productId: number
  ): Promise<StockLevelForReorderReportResponse[]> {
    const inventoryClient = await this.getInventoryClient();
    const options: StockLevelForReorderReportRequest = { productIds: [productId] };
    const stockResponse = await inventoryClient.availableStock.getStockForReorderReport(options);
    return stockResponse._response.parsedBody;
  }

  private async getStockSalesData(productId: number): Promise<ProductSalesHistoryResponse[]> {
    if (productId <= 0) {
      return [];
    }

    const salesClient = await this.getSalesClient();
    const options: ProductSalesHistoryRequest = { productIds: [productId] };
    const salesHistoryResponse = await salesClient.dailySales.getProductSalesHistory(options);
    const salesHistoryList = salesHistoryResponse._response.parsedBody;
    return salesHistoryList;
  }

  private async getAllRequestData(productId: number): Promise<ReorderEntryResponse[]> {
    const purchasingClient = await this.getPurchasingClient();
    const options: ReorderQuantityRequest = { productIds: [productId] };
    const requestedEntriesResponse = await purchasingClient.reorder.getRequestedReorderQuantity(
      options
    );
    const response = requestedEntriesResponse._response.parsedBody;
    return response.filter((r) => r.requestedQuantity && r.requestedQuantity > 0);
  }

  private async getAllProcurementData(
    productCode: string
  ): Promise<ProcurementLinesSearchResponse> {
    const purchasingClient = await this.getPurchasingClient();

    const req = {
      querySettings: {
        filterText: '',
        searchText: `ProductCodes=[${productCode}]`,
        orderBy: '',
      },
      pageSettings: {
        skip: 0,
        top: 1000000,
      },
    } as ProcurementViewSearchRequest;

    return purchasingClient.procurementLines.search(req);
  }

  @action
  public updateExpanded(expanded: object): void {
    this.expanded = expanded;
  }

  @action
  public updateChildExpanded(newChildExpanded: object, index: number): void {
    const newExpanded = this.expanded;
    newExpanded[index] = newChildExpanded;
    this.expanded = newExpanded;
  }

  @action
  public async populateTableControl(row: StockTableModel): Promise<void> {
    try {
      this.tableDataLoading = true;
      this.tableData = [];
      this.expanded = {};
      this.row = row;
      const productId = row.productId;

      if (productId > 0) {
        const [stockLevelData, stockSalesData, requestData, procurementData] = await Promise.all([
          this.getStockLevelData(productId),
          this.getStockSalesData(productId),
          this.getAllRequestData(productId),
          this.getAllProcurementData(row.productCode),
        ]);

        // We want to preprocess the data to set the sale profit centres here?
        const tableData = this.getTableData(
          stockLevelData,
          stockSalesData,
          requestData,
          procurementData
        );
        const expanded = {};
        if (!this.GetDetailsPageCollapseAllByDefault) {
          let ind = 0;
          tableData.forEach((element) => {
            const children = element.children;
            children.forEach((child) => {
              expanded[ind] = child;
              ind++;
            });
          });
        }
        runInAction(() => {
          this.tableData = tableData;
          this.expanded = expanded;
        });
      }
    } catch (e) {
      this.log(StringResources.ErrorPopulateStockLevelTable, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorPopulateStockLevelTable);
    } finally {
      runInAction(() => {
        this.tableDataLoading = false;
      });
    }
  }

  private getTableData(
    stockLevelData: StockLevelForReorderReportResponse[],
    stockSalesData: ProductSalesHistoryResponse[],
    requestedQtyData: ReorderEntryResponse[],
    procurementData: ProcurementLinesSearchResponse
  ): StockDetailTableModel[] {
    const allStockLevel = this.getStockLevelTableModelRows(stockLevelData, requestedQtyData);
    const allStockSales = this.getStockSalesTableModelRows(stockSalesData, procurementData);

    const profitCentreGroupData = groupBy(allStockSales, 'salesProfitCentreCode');

    const profitCentreLevelRows: StockDetailTableModel[] = [];

    forEach(profitCentreGroupData, (group: StockDetailTableModel[], _key: string) => {
      const keyItem = group[0];
      const childrenRows = this.getSalesBranchLevelRows(group, allStockLevel);

      const availableStock = sumBy(childrenRows, (g) => g.availableStock as number);
      const availableSerialisedStock = sumBy(childrenRows, (g) => g.availableSerialStock || 0);
      const availableNonSerialisedStock = sumBy(
        childrenRows,
        (g) => g.availableNonSerialStock || 0
      );

      const onOrder = sumBy(childrenRows, (g) => g.onOrder || 0);
      const committed = sumBy(childrenRows, (g) => g.committed || 0);
      const total = availableStock + onOrder;
      const requestedQty = sumBy(childrenRows, (g) => g.requestedQty || 0);

      const averageMonthSales = sumBy(childrenRows, (g) => g.averageMonthSales || 0);
      const thisMonth = sumBy(childrenRows, (g) => g.thisMonth || 0);
      const oneMonthAgo = sumBy(childrenRows, (g) => g.oneMonthAgo || 0);
      const twoMonthsAgo = sumBy(childrenRows, (g) => g.twoMonthsAgo || 0);
      const threeMonthsAgo = sumBy(childrenRows, (g) => g.threeMonthsAgo || 0);
      const fourMonthsAgo = sumBy(childrenRows, (g) => g.fourMonthsAgo || 0);
      const fiveMonthsAgo = sumBy(childrenRows, (g) => g.fiveMonthsAgo || 0);
      const sixMonthsAgo = sumBy(childrenRows, (g) => g.sixMonthsAgo || 0);
      const sevenMonthsAgo = sumBy(childrenRows, (g) => g.sevenMonthsAgo || 0);
      const eightMonthsAgo = sumBy(childrenRows, (g) => g.eightMonthsAgo || 0);
      const nineMonthsAgo = sumBy(childrenRows, (g) => g.nineMonthsAgo || 0);
      const tenMonthsAgo = sumBy(childrenRows, (g) => g.tenMonthsAgo || 0);
      const elevenMonthsAgo = sumBy(childrenRows, (g) => g.elevenMonthsAgo || 0);

      const profitCentreLevelRow = {
        showBold: true,
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        salesBranchCode: keyItem.salesProfitCentreCode, // profit centre header is on the "Sales Branch"
        branchCode: '',
        warehouseCode: '',

        availableStock: roundForDisplay(availableStock),
        availableSerialStock: roundForDisplay(availableSerialisedStock),
        availableNonSerialStock: roundForDisplay(availableNonSerialisedStock),
        onOrder: roundForDisplay(onOrder),
        committed: roundForDisplay(committed),
        total: roundForDisplay(total),

        averageMonthSales: roundForDisplay(averageMonthSales),
        thisMonth: roundForDisplay(thisMonth),
        oneMonthAgo: roundForDisplay(oneMonthAgo),
        twoMonthsAgo: roundForDisplay(twoMonthsAgo),
        threeMonthsAgo: roundForDisplay(threeMonthsAgo),
        fourMonthsAgo: roundForDisplay(fourMonthsAgo),
        fiveMonthsAgo: roundForDisplay(fiveMonthsAgo),
        sixMonthsAgo: roundForDisplay(sixMonthsAgo),
        sevenMonthsAgo: roundForDisplay(sevenMonthsAgo),
        eightMonthsAgo: roundForDisplay(eightMonthsAgo),
        nineMonthsAgo: roundForDisplay(nineMonthsAgo),
        tenMonthsAgo: roundForDisplay(tenMonthsAgo),
        elevenMonthsAgo: roundForDisplay(elevenMonthsAgo),

        findMatched: false,
        requestedQty: requestedQty > 0 ? requestedQty : null,
        isEdited: false,
        children: [] as StockDetailTableModel[],
        levelNumber: 0,
        eta: null,
      } as StockDetailTableModel;

      profitCentreLevelRow.children = childrenRows;
      profitCentreLevelRows.push(profitCentreLevelRow);
    });

    // sorting at the front sorts No Sales to a place where it wouldn't make sense, so let's sort in here (alternatively we implement a custom sorting function)
    profitCentreLevelRows.sort((left, right) =>
      left.salesBranchCode.localeCompare(right.salesBranchCode)
    );

    // currently, hanging values are simply pushed onto the end. We need to add a category for them first, then push these values as the children of this new field.
    const remainingLevels = allStockLevel.filter((item) => item.findMatched === false);
    if (remainingLevels.length > 0) {
      profitCentreLevelRows.push(this.groupRemainingWarehouses(remainingLevels));
    }
    profitCentreLevelRows.push(this.getTableDataSummary(profitCentreLevelRows));

    const filterBySalesBranch = this.GetDetailsPageFilterBySalesBranch && this.GetUserBranches && this.GetUserBranches.length > 0;
    if (filterBySalesBranch) {
      profitCentreLevelRows.forEach(p => p.children = p.children.filter(c => this.GetUserBranches.includes(c.salesBranchCode)));
      return profitCentreLevelRows.filter(p => p.children.length > 0);
    }

    return profitCentreLevelRows;
  }

  private getStockLevelTableModelRows(
    stockLevelData: StockLevelForReorderReportResponse[],
    requestedQtyData: ReorderEntryResponse[]
  ): StockDetailTableModel[] {
    const groupStockLevelData = groupBy(
      stockLevelData,
      (item) => `${item.productCode}-${item.warehouseCode}`
    );
    const allStockLevel: StockDetailTableModel[] = [];
    forEach(groupStockLevelData, (group: StockLevelForReorderReportResponse[], _key: string) => {
      const keyItem = group[0];

      const availableStock = sumBy(group, (g) => g.inStock - g.committed);
      const availableSerialisedStock = sumBy(
        group,
        (g) => g.serialisedInStock - g.serialisedCommitted
      );
      const availableNonSerialisedStock = sumBy(
        group,
        (g) => g.nonSerialisedInStock - g.nonSerialisedCommitted
      );
      const onOrder = sumBy(group, (g) => g.onOrder);
      const committed = sumBy(group, (g) => g.committed);
      const total = availableStock + onOrder;

      let groupEta: string | null = null;
      let groupEtaStatus = '';
      forEach(group, (item) => {
        // take latest ETA
        if (item.eta && (!groupEta || moment(item.eta).isBefore(moment(groupEta)))) {
          groupEta = item.eta;
          groupEtaStatus = item.etaStatus;
        }
      });

      const matchedRequestedQtyData = requestedQtyData.find(
        (data) => data.productId === keyItem.productId && data.branchCode === keyItem.branchCode
      );

      allStockLevel.push({
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        productGroupName: keyItem.productGroupName,
        salesProfitCentreCode: 'No Sales',
        salesBranchCode: '',
        salesWarehouseCode: '',
        branchCode: keyItem.branchCode,
        warehouseCode: keyItem.warehouseCode,
        stockClass: {
          code: keyItem.stockClassificationCode,
          color: keyItem.stockClassificationColour,
        },

        availableStock: roundForDisplay(availableStock),
        availableNonSerialStock: roundForDisplay(availableNonSerialisedStock),
        availableSerialStock: roundForDisplay(availableSerialisedStock),
        onOrder: roundForDisplay(onOrder),
        committed: roundForDisplay(committed),
        total: roundForDisplay(total),
        eta: groupEta,
        etaStatus: groupEtaStatus,

        averageMonthSales: null,
        thisMonth: null,
        oneMonthAgo: null,
        twoMonthsAgo: null,
        threeMonthsAgo: null,
        fourMonthsAgo: null,
        fiveMonthsAgo: null,
        sixMonthsAgo: null,
        sevenMonthsAgo: null,
        eightMonthsAgo: null,
        nineMonthsAgo: null,
        tenMonthsAgo: null,
        elevenMonthsAgo: null,

        findMatched: false,
        isEdited: false,
        children: [] as StockDetailTableModel[],
        levelNumber: 2,
        requestedQty: matchedRequestedQtyData ? matchedRequestedQtyData.requestedQuantity : null,
        comment: matchedRequestedQtyData ? matchedRequestedQtyData.comment : '',
        showRequestedQtyInput: true,
      } as StockDetailTableModel);
    });
    return allStockLevel;
  }

  private getStockSalesTableModelRows(
    stockSalesData: ProductSalesHistoryResponse[],
    procurementData: ProcurementLinesSearchResponse
  ): StockDetailTableModel[] {
    const groupStockSalesData = groupBy(
      stockSalesData,
      (item) => `${item.productCode}-${item.salesBranchCode}`
    );
    const allStockSales: StockDetailTableModel[] = [];
    const remainingQtys = this.mapRemainingQtys(procurementData);

    // refactory:  use reducer?
    forEach(groupStockSalesData, (group: ProductSalesHistoryResponse[], _key: string) => {
      const keyItem = group[0];

      // we don't need to check for warehouse values that don't actually have a sales branch, as the values would be 0 across the board anyway.
      const averageMonthSales = sumBy(group, (g) => g.averageMonthSales || 0);
      const thisMonth = sumBy(group, (g) => g.thisMonth || 0);
      const oneMonthAgo = sumBy(group, (g) => g.oneMonthAgo || 0);
      const twoMonthsAgo = sumBy(group, (g) => g.twoMonthsAgo || 0);
      const threeMonthsAgo = sumBy(group, (g) => g.threeMonthsAgo || 0);
      const fourMonthsAgo = sumBy(group, (g) => g.fourMonthsAgo || 0);
      const fiveMonthsAgo = sumBy(group, (g) => g.fiveMonthsAgo || 0);
      const sixMonthsAgo = sumBy(group, (g) => g.sixMonthsAgo || 0);
      const sevenMonthsAgo = sumBy(group, (g) => g.sevenMonthsAgo || 0);
      const eightMonthsAgo = sumBy(group, (g) => g.eightMonthsAgo || 0);
      const nineMonthsAgo = sumBy(group, (g) => g.nineMonthsAgo || 0);
      const tenMonthsAgo = sumBy(group, (g) => g.tenMonthsAgo || 0);
      const elevenMonthsAgo = sumBy(group, (g) => g.elevenMonthsAgo || 0);

      allStockSales.push({
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        salesProfitCentreCode: keyItem.salesProfitCentreCode,
        salesBranchCode: keyItem.salesBranchCode,
        salesWarehouseCode: group.map((g) => g.warehouseCode).join(','),
        branchCode: '',
        warehouseCode: '',

        availableStock: null,
        availableNonSerialStock: null,
        availableSerialStock: null,
        onOrder: null,
        committed: null,
        total: null,

        averageMonthSales: roundForDisplay(averageMonthSales),
        thisMonth: roundForDisplay(thisMonth),
        oneMonthAgo: roundForDisplay(oneMonthAgo),
        twoMonthsAgo: roundForDisplay(twoMonthsAgo),
        threeMonthsAgo: roundForDisplay(threeMonthsAgo),
        fourMonthsAgo: roundForDisplay(fourMonthsAgo),
        fiveMonthsAgo: roundForDisplay(fiveMonthsAgo),
        sixMonthsAgo: roundForDisplay(sixMonthsAgo),
        sevenMonthsAgo: roundForDisplay(sevenMonthsAgo),
        eightMonthsAgo: roundForDisplay(eightMonthsAgo),
        nineMonthsAgo: roundForDisplay(nineMonthsAgo),
        tenMonthsAgo: roundForDisplay(tenMonthsAgo),
        elevenMonthsAgo: roundForDisplay(elevenMonthsAgo),

        findMatched: false,
        requestedQty: null,
        remainingQty: !!remainingQtys.get(keyItem.salesBranchCode)
          ? remainingQtys.get(keyItem.salesBranchCode)!.remainingWeightTotalKG! / 1000.0
          : null,
        showRequestedQtyInput: false,
        isEdited: false,
        children: [] as StockDetailTableModel[],
        levelNumber: 1,
      } as StockDetailTableModel);
    });

    return allStockSales;
  }

  private getSalesBranchLevelRows(
    allStockSales: StockDetailTableModel[],
    allStockLevel: StockDetailTableModel[]
  ): StockDetailTableModel[] {
    const output: StockDetailTableModel[] = [];

    allStockSales.forEach((stockSales) => {
      const matchedStockLevels = allStockLevel.filter(
        (sl) =>
          sl.productCode === stockSales.productCode && sl.branchCode === stockSales.salesBranchCode
      );

      const matchedStockLevelsCount = matchedStockLevels.length;

      if (matchedStockLevelsCount > 0) {
        stockSales.findMatched = true;
        stockSales.showRequestedQtyInput = true;

        const totalAvailableStock = sumBy(matchedStockLevels, (l) => l.availableStock || 0);
        const totalAvailableNonSerialStock = sumBy(
          matchedStockLevels,
          (l) => l.availableNonSerialStock || 0
        );
        const totalAvailableSerialStock = sumBy(
          matchedStockLevels,
          (l) => l.availableSerialStock || 0
        );
        const totalOnOrder = sumBy(matchedStockLevels, (l) => l.onOrder || 0);
        const totalCommitted = sumBy(matchedStockLevels, (l) => l.committed || 0);
        const totalTotal = sumBy(matchedStockLevels, (l) => l.total || 0);

        stockSales.availableStock = roundForDisplay(totalAvailableStock);
        stockSales.availableNonSerialStock = roundForDisplay(totalAvailableNonSerialStock);
        stockSales.availableSerialStock = roundForDisplay(totalAvailableSerialStock);
        stockSales.onOrder = toFixedNumber(totalOnOrder, 0);
        stockSales.committed = roundForDisplay(totalCommitted);
        stockSales.total = roundForDisplay(totalTotal);

        if (matchedStockLevelsCount === 1) {
          matchedStockLevels[0].salesBranchCode = '';
          matchedStockLevels[0].findMatched = true;

          stockSales.productGroupName = matchedStockLevels[0].productGroupName;
          stockSales.warehouseCode = matchedStockLevels[0].warehouseCode;
          stockSales.stockClass = matchedStockLevels[0].stockClass;
          stockSales.etaStatus = matchedStockLevels[0].etaStatus;
          stockSales.eta = matchedStockLevels[0].eta;
          stockSales.requestedQty = matchedStockLevels[0].requestedQty;
          stockSales.comment = matchedStockLevels[0].comment;

        } else {
          // stockSales.warehouseCode default to ''
          let warehouseSameAsSalesBranch;
          const childRows: StockDetailTableModel[] = [];
          matchedStockLevels
            .sort((a, b) => (a.warehouseCode > b.warehouseCode ? 1 : -1))
            .forEach((matchedStockLevel) => {
              matchedStockLevel.salesBranchCode = '';
              matchedStockLevel.findMatched = true;
              matchedStockLevel.showRequestedQtyInput = false;

              // If the warehouse code matches the sales branch, set it as the top warehouse
              if (matchedStockLevel.warehouseCode === stockSales.salesBranchCode) {
                warehouseSameAsSalesBranch = matchedStockLevel;
              } else {
                childRows.push(matchedStockLevel);
              }

              // take latest ETA
              if (
                matchedStockLevel.eta &&
                (!stockSales.eta || moment(matchedStockLevel.eta).isBefore(moment(stockSales.eta)))
              ) {
                stockSales.eta = matchedStockLevel.eta;
                stockSales.etaStatus = matchedStockLevel.etaStatus;
              }
            });

          if (warehouseSameAsSalesBranch) {
            stockSales.stockClass = warehouseSameAsSalesBranch.stockClass;
            stockSales.children = [warehouseSameAsSalesBranch].concat(childRows); // always go top
          } else {
            stockSales.children = childRows;
          }

          stockSales.requestedQty = matchedStockLevels[0].requestedQty;
          stockSales.comment = matchedStockLevels[0].comment;
          stockSales.productGroupName = matchedStockLevels[0].productGroupName;
        }
      } else {
        // if you do not have a stock level, there is no warehouse anymore.
        // stockSales.warehouseCode default to ''
        // stockSales.showRequestedQtyInput default to false;
        // stockSales.requestedQty defualt to null;
      }

      output.push(stockSales);
    });
    return output;
  }

  private getTableDataSummary(list: StockDetailTableModel[]): StockDetailTableModel {
    const availableStock = sumBy(list, (g) => g.availableStock || 0);
    const availableNonSerialStock = sumBy(list, (g) => g.availableNonSerialStock || 0);
    const availableSerialStock = sumBy(list, (g) => g.availableSerialStock || 0);
    const onOrder = sumBy(list, (g) => g.onOrder || 0);
    const committed = sumBy(list, (g) => g.committed || 0);
    const total = availableStock + onOrder;
    const requestedQty = sumBy(list, (g) => g.requestedQty || 0);

    const averageMonthSales = sumBy(list, (g) => g.averageMonthSales || 0);
    const thisMonth = sumBy(list, (g) => g.thisMonth || 0);
    const oneMonthAgo = sumBy(list, (g) => g.oneMonthAgo || 0);
    const twoMonthsAgo = sumBy(list, (g) => g.twoMonthsAgo || 0);
    const threeMonthsAgo = sumBy(list, (g) => g.threeMonthsAgo || 0);
    const fourMonthsAgo = sumBy(list, (g) => g.fourMonthsAgo || 0);
    const fiveMonthsAgo = sumBy(list, (g) => g.fiveMonthsAgo || 0);
    const sixMonthsAgo = sumBy(list, (g) => g.sixMonthsAgo || 0);
    const sevenMonthsAgo = sumBy(list, (g) => g.sevenMonthsAgo || 0);
    const eightMonthsAgo = sumBy(list, (g) => g.eightMonthsAgo || 0);
    const nineMonthsAgo = sumBy(list, (g) => g.nineMonthsAgo || 0);
    const tenMonthsAgo = sumBy(list, (g) => g.tenMonthsAgo || 0);
    const elevenMonthsAgo = sumBy(list, (g) => g.elevenMonthsAgo || 0);

    return {
      showBold: true,
      salesBranchCode: 'Total',
      warehouseCode: '',

      availableStock: roundForDisplay(availableStock),
      availableNonSerialStock: roundForDisplay(availableNonSerialStock),
      availableSerialStock: roundForDisplay(availableSerialStock),
      onOrder: roundForDisplay(onOrder),
      committed: roundForDisplay(committed),
      total: roundForDisplay(total),

      averageMonthSales: roundForDisplay(averageMonthSales),
      thisMonth: roundForDisplay(thisMonth),
      oneMonthAgo: roundForDisplay(oneMonthAgo),
      twoMonthsAgo: roundForDisplay(twoMonthsAgo),
      threeMonthsAgo: roundForDisplay(threeMonthsAgo),
      fourMonthsAgo: roundForDisplay(fourMonthsAgo),
      fiveMonthsAgo: roundForDisplay(fiveMonthsAgo),
      sixMonthsAgo: roundForDisplay(sixMonthsAgo),
      sevenMonthsAgo: roundForDisplay(sevenMonthsAgo),
      eightMonthsAgo: roundForDisplay(eightMonthsAgo),
      nineMonthsAgo: roundForDisplay(nineMonthsAgo),
      tenMonthsAgo: roundForDisplay(tenMonthsAgo),
      elevenMonthsAgo: roundForDisplay(elevenMonthsAgo),
      requestedQty: requestedQty > 0 ? requestedQty : null,

      children: [] as StockDetailTableModel[],
    } as StockDetailTableModel;
  }

  private groupRemainingWarehouses(
    remainingLevels: StockDetailTableModel[]
  ): StockDetailTableModel {
    // let them order into the main warehouse on the branch, but nothing else.
    remainingLevels.forEach((level) => {
      level.showRequestedQtyInput = level.branchCode === level.warehouseCode;
    });
    return {
      showBold: true,
      productId: remainingLevels[0].productId,
      productCode: remainingLevels[0].productCode,
      salesBranchCode: 'No Sales',
      salesWarehouseCode: 'No Sales',
      branchCode: '',
      warehouseCode: '',

      availableStock: sumBy(remainingLevels, (item) => item.availableStock || 0),
      availableSerialStock: sumBy(remainingLevels, (item) => item.availableSerialStock || 0),
      availableNonSerialStock: sumBy(remainingLevels, (item) => item.availableNonSerialStock || 0),
      onOrder: sumBy(remainingLevels, (item) => item.onOrder || 0),
      committed: sumBy(remainingLevels, (item) => item.committed || 0),
      total: sumBy(remainingLevels, (item) => item.total || 0),

      averageMonthSales: null,
      thisMonth: null,
      oneMonthAgo: null,
      twoMonthsAgo: null,
      threeMonthsAgo: null,
      fourMonthsAgo: null,
      fiveMonthsAgo: null,
      sixMonthsAgo: null,
      sevenMonthsAgo: null,
      eightMonthsAgo: null,
      nineMonthsAgo: null,
      tenMonthsAgo: null,
      elevenMonthsAgo: null,

      findMatched: false,
      requestedQty: sumBy(remainingLevels, (level) => level.requestedQty || 0),
      isEdited: false,
      children: remainingLevels.filter(
        (level) => !!level.availableStock || !!level.committed || !!level.onOrder
      ),
      levelNumber: 0,
    } as StockDetailTableModel;
  }

  private mapRemainingQtys(
    procurementData: ProcurementLinesSearchResponse
  ): Map<string, ProcurementLine> {
    // Related to the other for the request to purchasing, we can probably remove this once we work out how to do the filtering in the azure search. This is
    // a temporary fix for sorting the return data.
    const lines = procurementData.results!.filter(
      (res) =>
        res.line!.lineState!.toLowerCase() !== 'completed' &&
        res.line!.lineState!.toLowerCase() !== 'cancelled' &&
        res.line!.lineState!.toLowerCase() !== 'deleted' &&
        res.line!.lineState!.toLowerCase() !== 'receipted'
    );
    const remainingQtys = new Map<string, any>();
    lines.forEach((line) => {
      const branch =
        procurementData.relatedData!.procurementOrders![
          parseInt(line!.procurementOrderDocumentNumber!)
        ].branchCode;
      if (
        !!remainingQtys.get(branch!) &&
        new Date(remainingQtys.get(branch!).etaDate) < new Date(line!.line!.etaDate!)
      ) {
        return;
      }

      remainingQtys.set(branch!, line.line);
    });

    return remainingQtys;
  }
}
