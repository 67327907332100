import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { toFixedNumber } from 'src/Shared/Utils';
import { Tooltip } from 'src/Shared/Tooltip/Tooltip';
import { TableCellHeading } from 'src/Shared/Tooltip/TableCellHeading';
import { TableContainer } from 'src/Shared/Tooltip/TableContainer';

export interface StockDetailEtaDateToolTipProps {
  etaStatus: string;
  remainingQty: number;
  value: string;
}
export const StockDetailEtaDateTooltip = ({
  etaStatus,
  remainingQty,
  value,
}: StockDetailEtaDateToolTipProps) => {
  const etaDateToolTip = (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellHeading>Quantity</TableCellHeading>
            <TableCellHeading>ETA Status</TableCellHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">
              {!remainingQty != null ? toFixedNumber(remainingQty, 2) + ' TON' : '-'}
            </TableCell>
            <TableCell>{etaStatus}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  return <Tooltip title={etaDateToolTip} placement="top" value={value} />;
};
