import { observable } from 'mobx';
import { CreateOrUpdateProductCodeMapRequest } from '@vulcan/purchasing-api-client/lib/models';

export class ProductCodeMapModel implements CreateOrUpdateProductCodeMapRequest {
  @observable public productCodeMapId: number | undefined;
  @observable public supplierCode: string;
  @observable public supplierItemCode: string;
  @observable public vulcanProductCode: string;
  @observable public vulcanProductTypeCode: string;

  constructor(fields?: Partial<ProductCodeMapModel>) {
    this.productCodeMapId = undefined;
    this.supplierCode = '';
    this.supplierItemCode = '';
    this.vulcanProductCode = '';
    this.vulcanProductTypeCode = '';
    Object.assign(this, fields);
  }

  public isValid(): boolean {
    return (
      this.supplierCode.length !== 0 &&
      this.supplierItemCode.length !== 0 &&
      this.vulcanProductCode.length !== 0 &&
      this.vulcanProductTypeCode.length !== 0
    );
  }

  public isSupplierCodeValid = (): boolean => {
    return this.supplierCode.length !== 0;
  };

  public isSupplierItemCodeValid = (): boolean => {
    return this.supplierItemCode.length !== 0;
  };

  public isVulcanProductCodeValid = (): boolean => {
    return this.vulcanProductCode.length !== 0;
  };

  public isVulcanProductTypeCodeValid = (): boolean => {
    return this.vulcanProductTypeCode.length !== 0;
  };
}

export default ProductCodeMapModel;
