import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { SupplierInvoiceStore } from './SupplierInvoiceStore';
import { RouteComponentProps } from 'react-router-dom';
import SecurityStore from '../App/Security/SecurityStore';
import InvoiceType from './InvoiceType';
import SupplierInvoiceLayout from './SupplierInvoiceLayout';

interface Props extends RouteComponentProps {
  supplierInvoiceStore: SupplierInvoiceStore;
  securityStore: SecurityStore;
}

@inject('supplierInvoiceStore')
@inject('securityStore')
@observer
export class SupplierInvoiceMatchedLayout extends React.Component<Props, {}> {
  public render(): React.ReactNode {
    return <SupplierInvoiceLayout {...this.props} type={InvoiceType.Complete} />;
  }
}
export default SupplierInvoiceMatchedLayout;
