import * as React from 'react';
import { modalStyle, Button, ProgressBar } from '@vulcan/vulcan-materialui-theme';
import { withStyles, WithStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';

interface FooterProps extends WithStyles<typeof modalStyle> {
  disabled: boolean;
}

interface AddFooterProps extends FooterProps {
  showProgressBar: boolean;
  handleAddMapClick: Function;
}

export const AddProductMapModalFooter = withStyles(modalStyle)(
  ({ disabled, handleAddMapClick, classes, showProgressBar }: AddFooterProps) => (
    <DialogActions className={classes.modalFooter + ' add-modal-footer'}>
      <div className="add-modal-button-group">
        <ProgressBar showProgressBar={showProgressBar} className="add-exception" />
        <Button
          disabled={disabled}
          className="action-button"
          color="warning"
          onClick={handleAddMapClick}
        >
          Add
        </Button>
      </div>
    </DialogActions>
  )
);
