import * as React from 'react';
import { IconResources } from '../../Shared/Constants';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, WithStyles } from '@material-ui/core';
import { Button, modalStyle, Icon } from '@vulcan/vulcan-materialui-theme';

interface Props extends WithStyles<typeof modalStyle> {
  headerText: string;
  handleClose: Function;
}

export const ModalHeader = withStyles(modalStyle)(({ handleClose, headerText, classes }: Props) => (
  <DialogTitle id="map-modal-slide-title" disableTypography={true} className={classes.modalHeader}>
    <Button
      justIcon={true}
      className={classes.modalCloseButton}
      key="close"
      aria-label="Close"
      color="transparent"
      onClick={handleClose}
    >
      <Icon icon={IconResources.CROSS} className={classes.modalClose} />
    </Button>
    <h4 className={classes.modalTitle}>{headerText}</h4>
  </DialogTitle>
));

export default ModalHeader;
