import React, { useCallback, useEffect, useState } from 'react';
import { GridItem } from '@vulcan/vulcan-materialui-theme';
import { InputLabel, Typography } from '@material-ui/core';

export interface FacetItemProps {
  facetItems: { count: number; value: string }[];
  title: string;
  feild: string;
  onFilterChange: Function;
}

export const FacetItem = (props: FacetItemProps): JSX.Element => {
  const { facetItems, title, feild, onFilterChange } = props;
  const defaultFacetItemSize = 5;
  const [showMore, setShowMore] = useState(false);
  const [facetItemSize, setFacetItemSize] = useState(defaultFacetItemSize);

  useEffect(() => {
    if (facetItems && facetItems.length > defaultFacetItemSize) {
      setShowMore(true);
    }
  }, [facetItems, setShowMore]);

  const handleLineFacet = useCallback(
    (value) => {
      const filter = `(${feild} eq '${value}')`;
      onFilterChange(filter);
    },
    [onFilterChange, feild]
  );

  const handleShowMore = useCallback(() => {
    setShowMore(false);
    setFacetItemSize(facetItems.length);
  }, [facetItems.length]);

  const handleLessMore = useCallback(() => {
    setShowMore(true);
    setFacetItemSize(defaultFacetItemSize);
  }, []);

  return facetItems.length > 0 ? (
    <GridItem lg={12} xs={2}>
      <div className="label-panel">
        <InputLabel className="label">{title}</InputLabel>
      </div>
      {facetItems.slice(0, facetItemSize).map((x: { count: number; value: string }) => (
        <div className="count-row" key={x.value}>
          <Typography
            className="item"
            variant="caption"
            gutterBottom={true}
            onClick={(): void => handleLineFacet(x.value)}
          >
            {x.value}
          </Typography>
          <span>({x.count})</span>
        </div>
      ))}
      {showMore && facetItems.length > defaultFacetItemSize && (
        <div className="count-row">
          <Typography
            className="item"
            variant="caption"
            gutterBottom={true}
            onClick={handleShowMore}
          >
            show more options
          </Typography>
        </div>
      )}
      {!showMore && facetItems.length > defaultFacetItemSize && (
        <div className="count-row">
          <Typography
            className="item"
            variant="caption"
            gutterBottom={true}
            onClick={handleLessMore}
          >
            show less options
          </Typography>
        </div>
      )}
    </GridItem>
  ) : (
    <></>
  );
};
