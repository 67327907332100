import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { StringResources } from 'src/Shared/Constants';
import ReactTable, { CellInfo, Column, FinalState, ReactTableDefaults } from 'react-table';
import _ from 'lodash';
import ReorderStore from './ReorderStore';
import moment from 'moment';
import { ReorderTableDataRowGroupModel } from './Models';
import {
  formatDisplayDate,
  tableDateSortMethod,
  tokilograms,
  stringContainsFilterMethod,
} from 'src/Shared/Utils';
import { ExportableTableColumn } from '@vulcan/component-lib/dist/utils';
import { reaction } from 'mobx';

interface ReorderTableProps {
  reorderStore?: ReorderStore;
}

@inject('reorderStore')
@observer
export class ReorderTable extends React.Component<ReorderTableProps> {
  private table: ReactTable<ReorderTableDataRowGroupModel> | undefined;

  public componentDidMount = (): void => {
    reaction(() => this.props.reorderStore!.tableData, this.showFirstPageOfTable);
  };

  public showFirstPageOfTable = (): void => {
    if (this.table) {
      this.table.setState({ ...this.table.state, page: 0 });
    }
  };

  public render(): JSX.Element {
    return (
      <ReactTable
        ref={(table: ReactTable<ReorderTableDataRowGroupModel>): void => {
          this.table = table;
        }}
        data={this.props.reorderStore!.tableData.slice()}
        columns={this.styledTableColumns}
        column={{
          ...ReactTableDefaults.column,
          style: { whiteSpace: 'unset' },
        }}
        loading={this.props.reorderStore!.tableDataLoading}
        noDataText={
          this.props.reorderStore!.tableDataLoading ? '' : StringResources.TableNoRowsFound
        }
        minRows={12}
        pageSize={12}
        className="react-table -striped -highlight"
        filterable={false}
        defaultFilterMethod={stringContainsFilterMethod}
        sortable={true}
        defaultSorted={[{ id: 'product-detail', desc: false }]}
        showPageJump={false}
        showPageSizeOptions={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        showPagination={true}
        collapseOnDataChange={false}
        collapseOnSortingChange={false}
        collapseOnPageChange={false}
        resizable={false}
      >
        {(state: FinalState, makeTable: () => React.ReactElement): ReactNode => {
          const tableData = _.flatMap(state.sortedData.slice(), (d) =>
            d._subRows ? d._subRows : [d]
          );
          const sortedTableData = tableData.map((d) => d._original).slice();
          this.props.reorderStore!.updateSortedTableData(sortedTableData);
          return makeTable();
        }}
      </ReactTable>
    );
  }

  private get styledTableColumns(): Column<ReorderTableDataRowGroupModel>[] {
    const columns = ReorderTable.exportableTableColumns.map(
      (exportableTableColumn) => exportableTableColumn.column
    );
    const classColumn = columns.find((column) => column.id === 'class');
    if (classColumn) {
      classColumn.Cell = (cell: CellInfo): JSX.Element => (
        <div className="product-class-cell" style={{ backgroundColor: cell.value.color }}>
          {cell.value.code}
        </div>
      );
    }
    return columns;
  }

  public static get exportableTableColumns(): ExportableTableColumn<ReorderTableDataRowGroupModel>[] {
    const exportColumnDefault = (
      value: string | number | object
    ): string | number | Date | object => value;
    const exportTypeGeneral = '';
    const exportTypeDate = window.appSettings.excelDateFormat;
    const exportColumnDate = (value: string | number | object): string | number | Date =>
      value ? formatDisplayDate(value.toString()) : '';
    const exportColumnNumber = (value: string | number | object): string | number | Date =>
      value < 0 ? '-' : value.toString();

    return [
      {
        column: {
          id: 'sales-branch',
          Header: 'Sales Branch',
          accessor: 'salesBranchCode',
          width: 130,
          sortable: false,
          filterable: true,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'warehouse',
          Header: 'Stock Warehouse',
          accessor: 'warehouseCode',
          width: 170,
          sortable: false,
          filterable: true,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'product-category',
          Header: 'Product Category',
          accessor: 'productCategory',
          width: 170,
          sortable: false,
          filterable: true,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'product-group',
          Header: 'Product Group',
          accessor: 'productGroup',
          width: 150,
          sortable: false,
          filterable: true,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'product-detail',
          Header: 'Product Detail',
          accessor: 'productCode',
          width: 180,
          filterable: true,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'class',
          Header: 'Class',
          accessor: 'stockClass',
          width: 60,
          sortable: false,
        },
        exportColumn: (value: string | number | object): string | number | Date | object => {
          const exportAccessor = 'code';
          return value[exportAccessor];
        },
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'available-stock',
          Header: 'Available Stock',
          accessor: 'availableStock',
          width: 150,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'committed',
          Header: 'Committed',
          accessor: 'committed',
          width: 120,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'pending',
          Header: 'Pending',
          accessor: 'pending',
          width: 100,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'on-order',
          Header: 'On Order',
          accessor: 'onOrder',
          width: 110,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'eta',
          Header: 'ETA',
          accessor: 'eta',
          width: 130,
          Cell: (cell: CellInfo): string => formatDisplayDate(cell.value),
          sortMethod: tableDateSortMethod,
        },
        exportColumn: exportColumnDate,
        exportType: exportTypeDate,
      },
      {
        column: {
          id: 'eta-status',
          Header: 'ETA Status',
          accessor: 'etaStatus',
          width: 120,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'avg-sales',
          Header: 'Avg Sales',
          accessor: 'averageMonthSales',
          width: 110,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'current-sales',
          Header: 'Current Sales',
          accessor: 'thisMonth',
          width: 140,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'one-month-ago',
          Header: moment().add(-1, 'month').format('MMM YY'),
          accessor: 'oneMonthAgo',
          width: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'two-months-ago',
          Header: moment().add(-2, 'month').format('MMM YY'),
          accessor: 'twoMonthsAgo',
          width: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'three-months-ago',
          Header: moment().add(-3, 'month').format('MMM YY'),
          accessor: 'threeMonthsAgo',
          width: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'four-months-ago',
          Header: moment().add(-4, 'month').format('MMM YY'),
          accessor: 'fourMonthsAgo',
          width: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'five-months-ago',
          Header: moment().add(-5, 'month').format('MMM YY'),
          accessor: 'fiveMonthsAgo',
          width: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'month-cover',
          Header: 'Month Cover',
          accessor: 'monthsCover',
          width: 140,
          Cell: (cell: CellInfo): string => (cell.value < 0 ? '-' : cell.value),
        },
        exportColumn: exportColumnNumber,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'stock-days',
          Header: 'Stock Days',
          accessor: 'stockDays',
          width: 130,
          Cell: (cell: CellInfo): string => (cell.value < 0 ? '-' : cell.value),
        },
        exportColumn: exportColumnNumber,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'lead-days',
          Header: 'Lead Days',
          accessor: 'leadDays',
          width: 120,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          id: 'each-weight',
          Header: 'Each Weight',
          accessor: 'eachWeight',
          style: { textAlign: 'right' },
          width: 130,
          Cell: (cell: CellInfo): string => tokilograms(cell.value),
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
    ];
  }
}
export default ReorderTable;
