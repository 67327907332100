import * as React from 'react';
import { Button } from '@vulcan/vulcan-materialui-theme';

const LoadMoreData = ({ show, loadData, isLoading }): JSX.Element =>
  show && (
    <Button disabled={isLoading} className="load-more-section" color="success" onClick={loadData}>
      {isLoading ? 'Loading...' : 'Load more'}
    </Button>
  );

export default LoadMoreData;
