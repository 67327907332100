import * as React from 'react';
import { Permissions } from 'src/App/Security/Permissions';
import { RouteModel } from '.';
import ProductMaps from 'src/ProductMaps/ProductMaps';
import { IconResources } from 'src/Shared/Constants';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import SupplierInvoiceMatchedLayout from 'src/Invoice/SupplierInvoiceMatchedLayout';
import SupplierInvoiceDraftLayout from 'src/Invoice/SupplierInvoiceDraftLayout';
import ReorderLayout from 'src/Reorder/ClassicReport/ReorderLayout';
import RequestLayout from 'src/Reorder/Request/RequestLayout';
import RequestHistoryLayout from 'src/Reorder/RequestHistory/RequestHistoryLayout';
import StockLayout from 'src/Reorder/Stock/StockLayout';
import Layout from 'src/ProcurementLine/Layout';

export const MainRoutes: RouteModel[] = [
  {
    collapse: false,
    permission: Permissions.SupplierInvoiceView,
    private: false,
    path: '/:prefix/draftinvoices',
    name: 'Draft',
    icon: (): JSX.Element => <Icon size={30} icon={IconResources.DRAFT} />,
    component: SupplierInvoiceDraftLayout,
    redirect: false,
    renderInSidebar: true,
    settingsRoute: false,
  },
  {
    collapse: false,
    permission: Permissions.SupplierInvoiceView,
    private: false,
    path: '/:prefix/matchedinvoices',
    name: 'Complete',
    icon: (): JSX.Element => <Icon size={30} icon={IconResources.COMPLETE} />,
    component: SupplierInvoiceMatchedLayout,
    redirect: false,
    renderInSidebar: true,
    settingsRoute: false,
  },
  {
    collapse: false,
    permission: Permissions.ProductCodeMapView,
    private: false,
    path: '/:prefix/productmap',
    name: 'ProductMap',
    icon: (): JSX.Element => <Icon size={30} icon={IconResources.PRODUCT_CODE_MAP} />,
    component: ProductMaps,
    redirect: false,
    renderInSidebar: true,
    settingsRoute: false,
  },
  {
    collapse: true,
    permission: Permissions.None,
    private: false,
    path: '/:prefix/reorder',
    name: 'Reorder',
    icon: (): JSX.Element => <Icon size={30} icon={IconResources.REORDER} />,
    redirect: false,
    renderInSidebar: true,
    settingsRoute: false,
    views: [
      {
        permission: Permissions.None,
        path: '/:prefix/reorder/reorder',
        name: 'Classic Report',
        mini: 'CR',
        component: ReorderLayout,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
      },
      {
        permission: Permissions.None,
        path: '/:prefix/reorder/stock',
        name: 'Stock',
        mini: 'S',
        component: StockLayout,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
      },
      {
        permission: Permissions.None,
        path: '/:prefix/reorder/request',
        name: 'Request',
        mini: 'R',
        component: RequestLayout,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
      },
      {
        permission: Permissions.None,
        path: '/:prefix/reorder/history',
        name: 'Request History',
        mini: 'RH',
        component: RequestHistoryLayout,
        redirect: false,
        renderInSidebar: true,
        settingsRoute: false,
      },
    ],
  },
  {
    collapse: false,
    permission: Permissions.None,
    private: false,
    path: '/:prefix/procurement-line',
    name: 'Procurement Line',
    icon: (): JSX.Element => <Icon size={30} icon={IconResources.PROCUREMENTLINE} />,
    component: Layout,
    redirect: false,
    renderInSidebar: true,
    settingsRoute: false,
  },
  {
    collapse: false,
    permission: Permissions.SupplierInvoiceView,
    private: false,
    redirect: true,
    path: '/:prefix/',
    pathTo: '/:prefix/reorder/reorder',
    name: 'HomePage',
    renderInSidebar: false,
    settingsRoute: false,
  },
];
export default MainRoutes;
