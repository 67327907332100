import * as React from 'react';
import SupplierInvoiceModel from 'src/Invoice/SupplierInvoiceModel';
import moment from 'moment';
import { ListItem } from '@material-ui/core';
import classNames from 'classnames';

interface InvoiceCardProps {
  invoice: SupplierInvoiceModel | null;
  index: number;
  handleInvoiceClick: Function;
  isSelected: boolean;
  animate: boolean;
}

class InvoiceCard extends React.Component<InvoiceCardProps, {}> {
  public shouldComponentUpdate(nextProps: InvoiceCardProps): boolean {
    return (
      this.props.isSelected !== nextProps.isSelected ||
      this.props.invoice!.invoiceNumber !== nextProps.invoice!.invoiceNumber
    );
  }

  public render(): JSX.Element | null {
    const { invoice, index, handleInvoiceClick, isSelected, animate } = { ...this.props };

    if (!invoice) {
      return null;
    }

    const listItemClass = classNames({
      'invoice-list-item': true,
      selected: isSelected,
      'fade-in': animate,
    });

    const dueDate = moment(invoice.dueDate, window.appSettings.dateFormat).format(
      window.appSettings.dateFormat
    );

    return (
      <>
        <ListItem
          onClick={(): void => handleInvoiceClick(invoice.supplierCode)}
          button={true}
          key={index}
          id={invoice.supplierCode}
          className={listItemClass}
        >
          <div className="invoice-card">
            <div className="left-column">
              <div className="invoice-card-line">
                <span>{invoice.supplierCode}</span> - <span>{invoice.invoiceNumber}</span>
              </div>
              <div>
                <span>PO:</span> {invoice.lineItems[0] && invoice.lineItems[0].purchaseOrderNumber}
              </div>
            </div>
            <div className="right-column">
              <div className="invoice-card-line-date">{dueDate}</div>
              <div>Qty: {invoice.quantity}</div>
            </div>
          </div>
        </ListItem>
      </>
    );
  }
}

export default InvoiceCard;
