import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { StringResources } from 'src/Shared/Constants';
import ReactTable, { CellInfo, Column, FinalState, ReactTableDefaults } from 'react-table';
import RequestStore from './RequestStore';
import UserProfileStore from 'src/App/UserProfileStore';
import { RequestTableDataRowModel } from './Models';
import { ExportableTableColumn } from '@vulcan/component-lib/dist/utils';
import { Input, Tooltip } from '@material-ui/core';
import { reaction } from 'mobx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface RequestTableProps {
  requestStore?: RequestStore;
  userProfileStore?: UserProfileStore;
}

@inject('requestStore', 'userProfileStore')
@observer
export class RequestTable extends React.Component<RequestTableProps> {
  state = {
    sorted: [],
  };

  private table: ReactTable<RequestTableDataRowModel> | undefined;

  public componentDidMount = (): void => {
    reaction(() => this.props.requestStore!.tableData, this.showFirstPageOfTable);
  };

  public shouldComponentUpdate(nextProps: RequestTableProps) {
    if (this.props.requestStore!.tableData !== nextProps.requestStore!.tableData) {
      const amendedQuantityChanged = this.props.requestStore!.tableData.some((data, index) =>
        data.amendedQuantity !== nextProps.requestStore!.tableData[index].amendedQuantity
      );
      if (amendedQuantityChanged) {
        return false;
      }
    }
    return true;
  }

  public showFirstPageOfTable = (): void => {
    if (this.table) {
      this.table.setState({ ...this.table.state, page: 0 });
    }
  };

  public render(): JSX.Element {
    const { requestStore: store } = this.props;
    return (
      <ReactTable
        ref={(table: ReactTable<RequestTableDataRowModel>): void => {
          this.table = table;
        }}
        data={store!.tableData.filter(d => d.requestedQuantity && d.requestedQuantity > 0)}
        columns={this.styledTableColumns}
        column={{
          ...ReactTableDefaults.column,
          style: { whiteSpace: 'unset' },
        }}
        loading={store!.loading}
        noDataText={store!.loading ? '' : StringResources.TableNoRowsFound}
        minRows={store!.tableData.filter(d => d.requestedQuantity && d.requestedQuantity > 0).length > 0 ? 0 : 16}
        pageSize={14}
        className="react-table -striped -highlight"
        filterable={false}
        sortable={true}
        defaultSorted={[{ id: 'amendedQuantity', desc: false }]}
        showPageJump={false}
        showPageSizeOptions={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        showPagination={true}
        collapseOnDataChange={false}
        collapseOnSortingChange={false}
        collapseOnPageChange={false}
        resizable={false}
        sorted={this.state.sorted}
        onSortedChange={sorted => this.setState({ sorted })}
      >
        {(state: FinalState, makeTable: () => React.ReactElement): ReactNode => {
          return makeTable();
        }}
      </ReactTable>
    );
  }

  private get styledTableColumns(): Column<RequestTableDataRowModel>[] {
    const columns = RequestTable.exportableTableColumns.map(
      (exportableTableColumn) => exportableTableColumn.column
    );
    const classColumn = columns.find((column) => column.accessor === 'stockClass');
    if (classColumn) {
      classColumn.Cell = (cell: CellInfo): JSX.Element => (
        <div className="product-class-cell" style={{ backgroundColor: cell.value.color }}>
          {cell.value.code}
        </div>
      );
    }

    const amendedQuantityColumn = columns.find((column) => column.accessor === 'amendedQuantity');
    if (amendedQuantityColumn) {
      // This uses `this.renderAmendedQuantity` to render the cell.
      // If you inline it the input will lose focus after each keypress.
      // https://github.com/tannerlinsley/react-table/issues/1346#issuecomment-533811901
      amendedQuantityColumn.Cell = this.renderAmendedQuantity;
    }

    const productCodeColumn = columns.find((column) => column.accessor === 'productCode');
    if (productCodeColumn) {
      productCodeColumn.Cell = this.renderProductCode;
    }

    return columns;
  }

  private renderAmendedQuantity = (cell: CellInfo): JSX.Element => {
    const amendedBy = this.props.userProfileStore!.userName;
    return (
      <Input
        type="number"
        inputProps={{ min: 0 }}
        value={cell.value ? cell.value : ''}
        onBeforeInput={(event: React.CompositionEvent<HTMLInputElement>): void => {
          const onlyNumbers = /^\d+$/;
          if (!onlyNumbers.test(event.data)) {
            event.preventDefault();
          }
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement>): void => {
          if (!event.target.value && event.target.value === '') {
            const { productCode, orderingBranchCode } = cell.original;
            this.props.requestStore!.rollbackAmendedQuantity(productCode, orderingBranchCode);
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const { productCode, orderingBranchCode } = cell.original;
          this.props.requestStore!.setAmendedQuantity(productCode, orderingBranchCode, event.target.value, amendedBy);
        }}
      />
    );
  };

  private renderProductCode = (cell: CellInfo): JSX.Element => {
    let tooltip: string | null = null;
    if (!cell.original.isBranchActive) {
      tooltip = 'There is no active warehouse for branch, please double check!';
    }
    if (!cell.original.isAllStockNotAvailable) {
      tooltip = 'The product is not available for this branch, please double check!';
    }

    if (tooltip) {
      return (
        <>
          <Tooltip
            title={<h4>{tooltip}</h4>}
            placement="right">
            <InfoOutlinedIcon style={{ marginRight: 5 }} fontSize='small' color='secondary' />
          </Tooltip>
          <span>{cell.value}</span>
        </>);
    } else {
      return cell.value;
    }
  };

  public static get exportableTableColumns(): ExportableTableColumn<RequestTableDataRowModel>[] {
    const exportColumnDefault = (
      value: string | number | object
    ): string | number | Date | object => value;
    const exportTypeGeneral = '';

    return [
      {
        column: {
          Header: 'Product Detail',
          accessor: 'productCode',
          width: 200,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Description',
          accessor: 'productDescription',
          minWidth: 200,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Product Group',
          accessor: 'productGroup',
          minWidth: 200,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Die Code',
          accessor: 'dieCode',
          minWidth: 100,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Weight',
          accessor: 'productWeight',
          minWidth: 90,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Branch',
          accessor: 'orderingBranchCode',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Class',
          accessor: 'stockClass',
          width: 70,
        },
        exportColumn: (value: string | number | object): string | number | Date | object => {
          const exportAccessor = 'code';
          return value[exportAccessor];
        },
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Available',
          accessor: 'availableStock',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Committed',
          accessor: 'committed',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Pending',
          accessor: 'pending',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'On Order',
          accessor: 'onOrder',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Avg Sales',
          accessor: 'averageMonthSales',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Req Qty',
          accessor: 'requestedQuantity',
          Cell: (cell: CellInfo): string => (cell.value <= 0 ? '-' : cell.value),
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Req By',
          accessor: 'requestedBy',
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Comment',
          accessor: 'comment',
          width: 200,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Amended Qty',
          accessor: 'amendedQuantity',
          width: 150,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
      {
        column: {
          Header: 'Amended By',
          accessor: 'amendedBy',
          width: 150,
        },
        exportColumn: exportColumnDefault,
        exportType: exportTypeGeneral,
      },
    ];
  }
}
export default RequestTable;
