import * as React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps, generatePath, Redirect } from 'react-router-dom';
import StyledLayout from './Layout/Layout';
import AppStore from './AppStore';
import TenantsList from './MultiTenant/TenantsList';
import { NotAuthorised, ProgressBar } from '@vulcan/vulcan-materialui-theme';

interface MatchParams {
  prefix: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  appStore: AppStore;
}

@observer
class SiteWrapper extends React.Component<Props, {}> {
  public render(): JSX.Element | null {
    if (!this.props.appStore.isSettingLoaded || !this.props.appStore.authStore.isLoggedIn) {
      return null;
    }

    const securityStore = this.props.appStore.securityStore;
    if (securityStore.userTenants.length === 0) {
      return securityStore.loadingTenants ? (
        <ProgressBar
          className="circular-progress-bar-container"
          showProgressBar={true}
          type="circular"
        />
      ) : (
        <NotAuthorised />
      );
    }

    const paramTenantPrefix = this.props.match.params.prefix;
    const tenantName = securityStore.getTenantNameByTenantPrefix(paramTenantPrefix);
    if (tenantName) {
      securityStore.setSelectedTenant(tenantName);
    } else if (!securityStore.isTenantSelected) {
      return <TenantsList />;
    } else {
      const validParamTenantPrefix = securityStore.getTenantPrefixFromCookieTenantName;
      if (validParamTenantPrefix) {
        const path = generatePath(this.props.match.path, { prefix: validParamTenantPrefix });
        return <Redirect key="valid-tenant" to={path} exact={true} />;
      } else {
        return <TenantsList />;
      }
    }

    return <StyledLayout />;
  }
}

// tslint:disable-next-line:no-any
export default withRouter<Props, any>(SiteWrapper);
