import { TokenCredentials } from '@azure/ms-rest-js';
import { PurchasingClient } from '@vulcan/purchasing-api-client';
import { PurchasingClientOptions } from '@vulcan/purchasing-api-client/lib/models';
import { Security } from '@vulcan/security-api-client';
import { SecurityOptions } from '@vulcan/security-api-client/lib/models';
import { StringResources } from '../../Shared/Constants/StringResources';
import { Sales } from '@vulcan/sales-api-client';
import { SalesOptions } from '@vulcan/sales-api-client/lib/models';
import { Inventory } from '@vulcan/inventory-api-client';
import { InventoryOptions } from '@vulcan/inventory-api-client/lib/models';
import Logger from './Logger';
import moment from 'moment';
import { observable, action } from 'mobx';
import SettingsService from '../Middleware/SettingsService';
import AlertType from 'src/Shared/Alert/AlertType';
import { appInsights } from 'src/Shared/AppInsights';
import * as Cookies from 'js-cookie';

export class BaseStore implements Logger {
  @observable public alert: boolean;
  @observable public alertType: AlertType;
  @observable public alertMessage: string;

  @observable private _isLoading: boolean;

  constructor() {
    this._isLoading = false;
    this.alert = false;
    this.alertType = AlertType.success;
    this.alertMessage = '';
  }

  @action
  public showAlert = (type: AlertType, message: string): void => {
    this.alertType = type;
    this.alertMessage = message;
    this.alert = true;
    // hide the alert after 2 seconds
    setTimeout(() => this.hideAlert(), 2000);
  };

  @action
  public hideAlert(): void {
    this.alert = false;
  }

  @action
  protected setIsLoading(status: boolean): void {
    this._isLoading = status;
  }

  protected get BrowserTodayDate(): moment.Moment {
    return moment();
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public log(message: string, error?: Error, info?: object): void {
    const customData = { message: message || '', info: info || {} };
    const ai = appInsights();
    ai.trackException({ id: message, exception: error });
    ai.flush();
    window.Raygun?.send(error || new Error('No Error Info'), customData);
  }

  public getSecurityClient = async (): Promise<Security> => {
    if (!window.getToken) {
      this.log(StringResources.NoGetTokenMethod);
      throw new Error(StringResources.UnexpectedError);
    }

    let cachedToken: string | null = null;
    try {
      cachedToken = await window.getToken();
    } catch (error) {
      this.log(StringResources.NoAuthToken, error as Error);
      throw new Error(StringResources.UnexpectedError);
    }

    const securityUrl =
      SettingsService.tenantsUrls.get(window.appSettings.securityApiUrl) ||
      window.appSettings.securityServiceUrl;

    if (!securityUrl) {
      this.log(StringResources.TenantUrlNotFound, new Error(StringResources.NoGetTokenMethod));
      throw new Error(StringResources.UnexpectedError);
    }

    const options: SecurityOptions = {
      baseUri: securityUrl,
    };
    const tokenCred = new TokenCredentials(cachedToken!);
    return new Security(tokenCred, options);
  };

  public getPurchasingClient = async (): Promise<PurchasingClient> => {
    if (!window.getToken) {
      this.log(StringResources.NoGetTokenMethod);
      throw new Error(StringResources.UnexpectedError);
    }

    let cachedToken: string | null = null;
    try {
      cachedToken = await window.getToken();
    } catch (error) {
      this.log(StringResources.NoAuthToken, error as Error);
      throw new Error(StringResources.UnexpectedError);
    }

    const purchasingUrl = SettingsService.tenantsUrls.get(window.appSettings.purchasingApiUrl);

    if (!purchasingUrl) {
      this.log(StringResources.TenantUrlNotFound, new Error(StringResources.TenantUrlNotFound));
      throw new Error(StringResources.UnexpectedError);
    }

    const options: PurchasingClientOptions = {
      baseUri: purchasingUrl,
    };
    const tokenCred = new TokenCredentials(cachedToken!);
    return new PurchasingClient(tokenCred, options);
  };

  public getSalesClient = async (): Promise<Sales> => {
    if (!window.getToken) {
      this.log(StringResources.NoGetTokenMethod);
      throw new Error(StringResources.UnexpectedError);
    }

    let cachedToken: string | null = null;
    try {
      cachedToken = await window.getToken();
    } catch (error: any) {
      this.log(StringResources.NoAuthToken, error);
      throw new Error(StringResources.UnexpectedError);
    }

    const salesUrl = SettingsService.tenantsUrls.get(window.appSettings.salesApiUrl);

    if (!salesUrl) {
      this.log(StringResources.TenantUrlNotFound, new Error(StringResources.TenantUrlNotFound));
      throw new Error(StringResources.UnexpectedError);
    }

    const options: SalesOptions = {
      baseUri: salesUrl,
    };
    const tokenCred = new TokenCredentials(cachedToken!);
    return new Sales(tokenCred, options);
  };

  public getInventoryClient = async (): Promise<Inventory> => {
    if (!window.getToken) {
      this.log(StringResources.NoGetTokenMethod);
      throw new Error(StringResources.UnexpectedError);
    }

    let cachedToken: string | null = null;
    try {
      cachedToken = await window.getToken();
    } catch (error: any) {
      this.log(StringResources.NoAuthToken, error);
      throw new Error(StringResources.UnexpectedError);
    }

    const inventoryUrl = SettingsService.tenantsUrls.get(window.appSettings.inventoryApiUrl);

    if (!inventoryUrl) {
      this.log(StringResources.TenantUrlNotFound, new Error(StringResources.TenantUrlNotFound));
      throw new Error(StringResources.UnexpectedError);
    }

    const options: InventoryOptions = {
      baseUri: inventoryUrl,
    };
    const tokenCred = new TokenCredentials(cachedToken!);
    return new Inventory(tokenCred, options);
  };

  public getToken = async (): Promise<string> => {
    if (!window.getToken) {
      this.log(StringResources.NoGetTokenMethod);
      throw new Error(StringResources.UnexpectedError);
    }

    try {
      return await window.getToken();
    } catch (error: any) {
      this.log(StringResources.NoAuthToken, error);
      throw new Error(StringResources.UnexpectedError);
    }
  };

  public get GetUserBranches(): string[] {
    return Cookies.get('userBranch')
      ? Object.values<string>(JSON.parse(Cookies.get('userBranch')))
      : [];
  }

  public get GetDetailsPageFilterBySalesBranch(): boolean {
    return Cookies.get('detailsPageFilterBySalesBranch')
      ? Cookies.get('detailsPageFilterBySalesBranch').toLowerCase() === 'true'
      : false;
  }

  public get GetDetailsPageCollapseAllByDefault(): boolean {
    return Cookies.get('detailsPageCollapseAllByDefault')
      ? Cookies.get('detailsPageCollapseAllByDefault').toLowerCase() === 'true'
      : false;
  }
}

export default BaseStore;
