export class RequestTableDataRowModel {
  public productCode = '';
  public productGroup = '';
  public productDescription = '';
  public dieCode = '';
  public productWeight = '';
  public orderingBranchCode = '';
  public stockClass: object = { code: '', color: '' };
  public availableStock = 0;
  public committed = 0;
  public pending = 0;
  public onOrder = 0;
  public averageMonthSales = 0;
  public requestedQuantity = 0;
  public requestedBy = '';
  public amendedQuantity: number | null = null;
  public amendedBy: string | null = null;
  public isEdited = false;
  public productId = 0;
  public comment = '';
  public isAllStockNotAvailable = false;
  public isBranchActive = false;

  constructor(fields?: Partial<RequestTableDataRowModel>) {
    Object.assign(this, fields);
  }
}

export default RequestTableDataRowModel;
