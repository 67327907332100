import { action, observable, runInAction } from 'mobx';
import { BaseStore } from 'src/App/Infrastructure';
import AlertType from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';
import { StockTableModel } from './StockTableModel';
import ReactTable, { DerivedDataObject, TableProps } from 'react-table';
import {
  ProductGroupDto,
  StockClassificationResponse,
} from '@vulcan/inventory-api-client/lib/models';
import { toFixedNumber } from 'src/Shared/Utils';
import { divide, uniqBy } from 'lodash';
import {
  ProductDetail,
  ReorderGetReorderEntriesV2Response,
  ReorderProductEntriesRequest,
  ReorderProductEntriesResponse,
} from '@vulcan/purchasing-api-client/esm/models';

interface RowNavigation {
  page: number;
  row: StockTableModel | undefined;
  index: number;
}

export class StockTableStore extends BaseStore {
  @observable public selectedRow!: StockTableModel;
  private firstRowIndex = 0;
  @observable public isDialogOpen = false;

  @observable public tableDataLoading = false;
  @observable public tableData: StockTableModel[] = [];

  // cached request parameters to reload
  private branchCodes: string[] = [];
  private productGroupIds: number[] = [];
  private productCategoryIds: number[] = [];
  private stockClassificationIds: number[] = [];

  private previousRow: RowNavigation = { page: 0, row: undefined, index: 0 };
  private nextUnreviewRow: RowNavigation = { page: 0, row: undefined, index: 0 };
  private tableRef: ReactTable<StockTableModel> | undefined;

  private allStockClassification: StockClassificationResponse[] = [];
  private allProductGroupAndCategory: ProductGroupDto[] = [];
  public onlyRefreshTableData = false;
  public setOnlyRefreshTableData(value: boolean): void {
    this.onlyRefreshTableData = value;
  }

  @action
  public async reloadTableControl(): Promise<void> {
    this.setOnlyRefreshTableData(true);
    await this.populateTableControl(
      this.branchCodes,
      this.productGroupIds,
      this.productCategoryIds,
      this.stockClassificationIds
    );
  }

  public updatePageRows(
    sortedData: DerivedDataObject[],
    tableRef?: ReactTable<StockTableModel>
  ): void {
    const currentSelectedIndex = sortedData.findIndex((d) => d._index === this.firstRowIndex);
    if (currentSelectedIndex >= 0) {
      const index = sortedData.findIndex(
        (d, i) => d.reviewedProduct === false && i > currentSelectedIndex
      );
      const prevIndex = currentSelectedIndex - 1;
      const pageSize = (tableRef?.state as TableProps)?.pageSize || 20;
      if (index >= 0) {
        const pageNumber = Math.floor(index / pageSize);
        const row = sortedData[index]._original as StockTableModel;
        this.nextUnreviewRow = { page: pageNumber, row, index: sortedData[index]._index };
      } else {
        const row = sortedData[currentSelectedIndex]._original as StockTableModel;
        this.nextUnreviewRow = { page: 0, row, index: sortedData[currentSelectedIndex]._index };
      }

      if (prevIndex >= 0) {
        const pageNumber = Math.floor(prevIndex / pageSize);
        const row = sortedData[prevIndex]._original as StockTableModel;
        this.previousRow = { page: pageNumber, row, index: sortedData[prevIndex]._index };
      } else {
        const row = sortedData[currentSelectedIndex]._original as StockTableModel;
        this.previousRow = { page: 0, row, index: sortedData[currentSelectedIndex]._index };
      }
    }
  }

  @action
  public openNextProductWithoutReviewed(): void {
    if (
      this.tableRef &&
      (this.tableRef?.state as TableProps<StockTableModel, StockTableModel>).page !==
        this.nextUnreviewRow.page
    ) {
      this.tableRef.setState({ ...this.tableRef.state, page: this.nextUnreviewRow.page });
    }

    if (this.nextUnreviewRow.row) {
      // update current row to next row
      this.selectedRow = this.nextUnreviewRow.row;
      this.firstRowIndex = this.nextUnreviewRow.index;
      this.isDialogOpen = true;
    }

    this.reloadTableControl();
  }

  @action
  public openPreviousProduct(): void {
    if (this.tableRef && (this.tableRef?.state as TableProps).page !== this.previousRow.page) {
      this.tableRef.setState({ ...this.tableRef.state, page: this.previousRow.page });
    }

    if (this.previousRow.row) {
      // update current row to previous row
      this.selectedRow = this.previousRow.row;
      this.isDialogOpen = true;
      this.firstRowIndex = this.previousRow.index;
    }
  }

  public get isFirstRow(): boolean {
    const sortedData = !!this.tableRef?.state ? (this.tableRef?.state as any).sortedData : null;
    if (!sortedData) {
      // default value, when in doubt enable the button
      return false;
    }
    if (sortedData && sortedData.length > 0) {
      return sortedData[0]._index === this.firstRowIndex;
    }
    // shouldn't hit. but incase sortedData doesn't exist
    return false;
  }

  @action
  public openDialog = (row: StockTableModel, rowIndex: number): void => {
    if (!row) {
      return;
    }
    this.isDialogOpen = true;
    this.selectedRow = row;
    this.firstRowIndex = rowIndex;
  };

  @action
  public closeDialog = (): void => {
    this.isDialogOpen = false;
  };

  @action
  public async populateTableControl(
    branchCodes: string[],
    productGroupIds: number[],
    productCategoryIds: number[],
    stockClassificationIds: number[]
  ): Promise<void> {
    try {
      this.tableDataLoading = true;
      this.branchCodes = branchCodes;
      this.productGroupIds = productGroupIds;
      this.productCategoryIds = productCategoryIds;
      this.stockClassificationIds = stockClassificationIds;

      const allStockClassification =
        this.allStockClassification.length > 0
          ? this.allStockClassification
          : await this.getAllStockClassification();
      const allProductGroupAndCategory =
        this.allProductGroupAndCategory.length > 0
          ? this.allProductGroupAndCategory
          : await this.getAllProductGroupAndCategory();

      const tableData = await this.getTableRowData(
        branchCodes,
        productGroupIds,
        productCategoryIds,
        stockClassificationIds,
        allStockClassification,
        allProductGroupAndCategory
      );

      runInAction(() => {
        this.tableData = tableData;
        this.allStockClassification = allStockClassification;
        this.allProductGroupAndCategory = allProductGroupAndCategory;
      });
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingReorderTableException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingReorderTableException);
    } finally {
      runInAction(() => {
        this.tableDataLoading = false;
      });
    }
  }

  private async getTableRowData(
    branchCodes: string[],
    productGroupIds: number[],
    productCategoryIds: number[],
    stockClassificationIds: number[],
    allStockClassification: StockClassificationResponse[],
    allProductGroupAndCategory: ProductGroupDto[]
  ): Promise<StockTableModel[]> {
    const branchCodesParam = branchCodes.length > 0 ? branchCodes : undefined;
    const productGroupIdsParam = productGroupIds.length > 0 ? productGroupIds : undefined;
    const productCategoryIdsParam = productCategoryIds.length > 0 ? productCategoryIds : undefined;
    const stockClassificationIdsParam =
      stockClassificationIds.length > 0 ? stockClassificationIds : undefined;

    if (
      !branchCodesParam &&
      !productGroupIdsParam &&
      !productCategoryIdsParam &&
      !stockClassificationIdsParam
    ) {
      return [];
    }

    const options: ReorderProductEntriesRequest = {
      branchCodes: branchCodesParam,
      productGroupIds: productGroupIdsParam,
      productCategoryIds: productCategoryIdsParam,
      stockClassificationIds: stockClassificationIdsParam,
    };

    const purchasingClient = await this.getPurchasingClient();
    const response = (await purchasingClient.reorder.getReorderEntriesV2(
      options
    )) as ReorderGetReorderEntriesV2Response;
    const data = response._response.parsedBody as ReorderProductEntriesResponse;

    const toMap = <T, K>(dataArray: T[] | undefined, getKey: (i: T) => K): Map<K, T> =>
      (dataArray || []).reduce((acc, d) => {
        acc.set(getKey(d), d);
        return acc;
      }, new Map());

    const branchMap = toMap(data.referenceData?.branches, (b) => b.branchId);
    const productMap = toMap(
      uniqBy(data.referenceData?.productDetails, (detail) => detail.productCode),
      (p) => p.productCode
    );
    const productGroupMap = toMap(allProductGroupAndCategory, (p) => p.productGroupId);
    const allProductCategory = allProductGroupAndCategory.map((d) => d.productCategory);
    const allProductCategoryWithUniqName = uniqBy(
      allProductCategory,
      (productCategory) => productCategory?.productCategoryName
    );
    const productCategoryMap = toMap(allProductCategoryWithUniqName, (p) => p?.productCategoryId);
    const entryMap = toMap(data.entries, (p) => p.reorderProductInfo?.productId);

    return (Array.from(productMap.values()) || []).map((r: ProductDetail) => {
      const entry: any = entryMap.get(r.productId);
      const { code: stockClass, colour: stockClassColor } =
        allStockClassification.find(
          (c) => c.id === entry.reorderProductInfo?.stockClassificationId
        ) || {};
      const { branchCode } = branchMap.get(entry.reorderProductInfo?.branchId) || {};
      const {
        productId,
        productCode,
        productDescription,
        dieCode,
        productCategoryId,
        productGroupId,
        productSize,
        productLength,
        productConversionFactor,
      } = r || {};

      return {
        productId,
        productCode,
        productDescription,
        dieCode,
        branchCode,
        stockClass,
        stockClassColor,
        productCategory: productCategoryMap.get(productCategoryId)?.productCategoryName,
        productGroup: productGroupMap.get(productGroupId)?.productGroupName,
        productSize: productSize,
        productLength: productLength,
        eachWeight: productConversionFactor
          ? toFixedNumber(divide(1000, productConversionFactor), 2)
          : undefined,
        unitsPerTonne: toFixedNumber(productConversionFactor, 2),
        requestedQty: entry.requestedQty,
        reviewedProduct: entry.reviewedProduct,
      } as StockTableModel;
    });
  }

  private getAllStockClassification = async (): Promise<StockClassificationResponse[]> => {
    const inventoryClient = await this.getInventoryClient();
    const response = await inventoryClient.availableStock.getStockClassifications();

    return JSON.parse(response._response.bodyAsText) as StockClassificationResponse[];
  };

  private getAllProductGroupAndCategory = async (): Promise<ProductGroupDto[]> => {
    const inventoryClient = await this.getInventoryClient();
    const response = await inventoryClient.productGroups.getProductGroups();

    return JSON.parse(response._response.bodyAsText) as ProductGroupDto[];
  };
}
