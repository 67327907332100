import SettingsService from 'src/App/Middleware/SettingsService';
import { StringResources } from 'src/Shared/Constants';

export function getTenantUrl(urlName: string): string {
  const apiTenantUrl = SettingsService.tenantsUrls.get(urlName);
  if (!apiTenantUrl) {
    throw new Error(StringResources.UnexpectedError);
  }
  return apiTenantUrl;
}
