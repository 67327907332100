import React, { useCallback, useEffect, useState } from 'react';
import {
  Icon,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  GridContainer,
  GridItem,
  Button,
} from '@vulcan/vulcan-materialui-theme';
import { ExcelGenerator } from '@vulcan/component-lib/dist/utils';
import { IconResources } from 'src/Shared/Constants';
import { Table } from './Table';
import { Facets } from './Facets';
import { useProcurementLine } from './hooks/useProcurementLine';
import { SearchBar } from './SearchBar';
import { ProcurementLineRow } from './Model/ProcurementLineRow';
import { exportableTableColumns } from './TableColumns';
import { SearchRequest } from './hooks/usePurchasingApi';

const Layout = (): JSX.Element => {
  const { searchAsync, tableData, facets, loading, totalCount } = useProcurementLine();
  const { searchAsync: exportSearchAsync } = useProcurementLine();

  const [filter, setFilter] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const pageSize = 12;

  const handleClearAll = useCallback(async () => {
    setFilter([]);
    setCurrentPage(0);
    const searchRequest = {
      search,
      filter: '',
      skip: 0,
      top: pageSize,
      orderBy,
    } as SearchRequest;
    await searchAsync(searchRequest);
  }, [search, orderBy, setFilter, setCurrentPage, searchAsync]);

  useEffect(() => {
    // IIFE design pattern https://developer.mozilla.org/en-US/docs/Glossary/IIFE
    (async function () {
      const searchRequest = {
        search: '',
        filter: '',
        skip: 0,
        top: pageSize,
        orderBy: 'line/createdDateTimeUtc asc',
      } as SearchRequest;
      await searchAsync(searchRequest);
    })();
  }, [searchAsync]);

  const handleFilterChange = useCallback(
    async (filterText) => {
      const pr = filter;
      const index = pr.includes(filterText);
      if (index === false) {
        pr.push(filterText);
      }
      setFilter(pr);
      setCurrentPage(0);
      const searchRequest = {
        search,
        filter: pr.join(' and '),
        skip: 0,
        top: pageSize,
        orderBy,
      } as SearchRequest;
      await searchAsync(searchRequest);
    },
    [search, filter, orderBy, setFilter, setCurrentPage, searchAsync]
  );

  const handleSearchChange = useCallback(
    async (searchText) => {
      setSearch(searchText);
      setCurrentPage(0);
      const searchRequest = {
        search,
        filter: filter.join(' and '),
        skip: 0,
        top: pageSize,
        orderBy,
      } as SearchRequest;
      await searchAsync(searchRequest);
    },
    [search, filter, orderBy, setSearch, setCurrentPage, searchAsync]
  );

  const handlePageChange = useCallback(
    async (pageNumber) => {
      setCurrentPage(pageNumber);
      const skip = pageSize * pageNumber;
      const searchRequest = {
        search,
        filter: filter.join(' and '),
        skip: skip,
        top: pageSize,
        orderBy,
      } as SearchRequest;
      await searchAsync(searchRequest);
    },
    [search, filter, orderBy, setCurrentPage, searchAsync]
  );

  const handleSortedChange = useCallback(
    async (orderByText) => {
      setOrderBy(orderByText);
      setCurrentPage(0);
      const searchRequest = {
        search,
        filter: filter.join(' and '),
        skip: 0,
        top: pageSize,
        orderBy: orderByText,
      } as SearchRequest;
      await searchAsync(searchRequest);
    },
    [search, filter, setOrderBy, setCurrentPage, searchAsync]
  );

  const handleExport = useCallback(async () => {
    const searchRequest = {
      search,
      filter: filter.join(' and '),
      skip: 0,
      top: 1000,
      orderBy,
    } as SearchRequest;
    const exportTableData = await exportSearchAsync(searchRequest);
    const generator = new ExcelGenerator<ProcurementLineRow>(
      'Procurement Lines',
      exportableTableColumns(),
      exportTableData
    );
    generator.generate();
  }, [exportSearchAsync, filter, orderBy, search]);

  return (
    <div className="container" style={{ marginTop: 0, height: '100%' }}>
      <SearchBar onSearch={handleSearchChange} />
      <Card className="card">
        <CardHeader color="primary" icon={true} className="card-header">
          <CardIcon className="card-icon" color="warning">
            <Icon icon={IconResources.PROCUREMENTLINE} />
          </CardIcon>
          <div className="card-header-section">
            <h4 className="card-title">Procurement Lines</h4>
            <Button
              disabled={tableData.length === 0}
              className="action-button export-excel-button"
              type="button"
              color="warning"
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            {tableData.length > 0 && (
              <GridItem lg={2} xs={12}>
                <Facets
                  filter={filter}
                  facets={facets}
                  onFilterChange={handleFilterChange}
                  clearAll={handleClearAll}
                />
              </GridItem>
            )}
            <GridItem lg={tableData.length > 0 ? 10 : 12} xs={12}>
              <Table
                pageSize={pageSize}
                currentPage={currentPage}
                totalCount={totalCount}
                loading={loading}
                data={tableData}
                onPageChange={handlePageChange}
                onSortedChange={handleSortedChange}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default Layout;
