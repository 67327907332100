import React, { Component, ReactNode } from 'react';
import { observer, inject } from 'mobx-react';
import {
  Icon,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  Snackbar,
} from '@vulcan/vulcan-materialui-theme';
import { IconResources } from 'src/Shared/Constants';
import { StockFilter } from 'src/Shared/StockFilterControl/StockFilter';
import { RouteComponentProps } from 'react-router-dom';
import { StockFilterStore } from 'src/Shared/StockFilterControl/StockFilterStore';
import { StockTable } from './StockTable/StockTable';
import { StockTableStore } from './StockTable/StockTableStore';
import { StockDetailTableStore } from './StockDetailTable/StockDetailTableStore';

interface Props extends RouteComponentProps {
  stockFilterStore?: StockFilterStore;
  stockTableStore?: StockTableStore;
  stockDetailTableStore?: StockDetailTableStore;
}

@inject('stockFilterStore', 'stockTableStore', 'stockDetailTableStore')
@observer
class StockLayout extends Component<Props> {
  public render(): ReactNode {
    const { stockTableStore, stockDetailTableStore } = this.props;

    return (
      <div className="container">
        {/* Filter */}
        <StockFilter
          stockFilterStore={this.props.stockFilterStore}
          onFilterApplied={async (
            branchCodes: string[] | undefined,
            _warehouseCodes: string[] | undefined,
            productCategoryIds: number[] | undefined,
            productGroupIds: number[] | undefined,
            stockClassificationIds: number[] | undefined
          ): Promise<void> => {
            await stockTableStore!.populateTableControl(
              branchCodes ? branchCodes : [],
              productGroupIds ? productGroupIds : [],
              productCategoryIds ? productCategoryIds : [],
              stockClassificationIds ? stockClassificationIds : []
            );
          }}
        />

        {/* Table */}
        <Card className="card">
          <CardHeader color="primary" icon className="card-header">
            <CardIcon className="card-icon" color="warning">
              <Icon icon={IconResources.REORDER} />
            </CardIcon>
            <div className="card-header-section">
              <h4 className="card-title">Stock Request</h4>
            </div>
          </CardHeader>
          <CardBody>
            <StockTable />
          </CardBody>
        </Card>

        <Snackbar
          place="br"
          color={
            this.props.stockFilterStore!.alertType ||
            stockTableStore!.alertType ||
            stockDetailTableStore!.alertType
          }
          icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
          message={
            this.props.stockFilterStore!.alertMessage ||
            stockTableStore!.alertMessage ||
            stockDetailTableStore!.alertMessage
          }
          open={
            this.props.stockFilterStore!.alert ||
            stockTableStore!.alert ||
            stockDetailTableStore!.alert
          }
          closeNotification={this.hideAlert}
          close={true}
        />
      </div>
    );
  }

  private hideAlert = (): void => {
    if (
      this.props.stockFilterStore &&
      this.props.stockTableStore &&
      this.props.stockDetailTableStore
    ) {
      this.props.stockFilterStore!.hideAlert();
      this.props.stockTableStore!.hideAlert();
      this.props.stockDetailTableStore!.hideAlert();
    }
  };
}

export default StockLayout;
