import { InputLabel, Typography } from '@material-ui/core';
import { GridContainer, GridItem } from '@vulcan/vulcan-materialui-theme';
import React from 'react';
import { FacetItem } from './FacetItem';
import { FacetDateItem } from './FacetDateItem';

export interface FacetsProps {
  filter: string[];
  facets: {};
  onFilterChange: Function;
  clearAll: () => void;
}

export const Facets = (props: FacetsProps): JSX.Element => {
  const { filter, facets, onFilterChange, clearAll } = props;

  return Object.keys(facets).length > 0 ? (
    <GridContainer>
      <div className="facets-panel">
        {filter.length > 0 && (
          <GridItem lg={12} xs={2}>
            <div className="label-panel">
              <InputLabel className="label">All Facets</InputLabel>
            </div>
            <Typography className="item" variant="caption" gutterBottom={true} onClick={clearAll}>
              Clear All
            </Typography>
          </GridItem>
        )}
        <FacetItem
          facetItems={facets['line/lineState']}
          title="Line State"
          feild="line/lineState"
          onFilterChange={onFilterChange}
        />
        <FacetItem
          facetItems={facets['headerInfo/warehouse/code']}
          title="Warehouses"
          feild="headerInfo/warehouse/code"
          onFilterChange={onFilterChange}
        />
        <FacetItem
          facetItems={facets['headerInfo/vendor/code']}
          title="Vendors"
          feild="headerInfo/vendor/code"
          onFilterChange={onFilterChange}
        />
        <FacetDateItem
          useUTC={true}
          facetItems={facets['line/createdDateTimeUtc']}
          title="Creation Date"
          field="line/createdDateTimeUtc"
          onFilterChange={onFilterChange}
        />
        <FacetDateItem
          useUTC={false}
          facetItems={facets['line/etaDate']}
          title="ETA Date"
          field="line/etaDate"
          onFilterChange={onFilterChange}
        />
        <FacetDateItem
          useUTC={false}
          facetItems={facets['line/etdDate']}
          title="ETD Date"
          field="line/etdDate"
          onFilterChange={onFilterChange}
        />
      </div>
    </GridContainer>
  ) : (
    <></>
  );
};
