import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteModel } from '.';
import { Location } from 'history';
import { NotFound } from '@vulcan/vulcan-materialui-theme';

export const RenderRoute = (r: RouteModel): any => {
  if (r.redirect) {
    return <Redirect key={r.name} from={r.path} to={r.pathTo || '/'} exact={true} />;
  }

  if (r.views && r.views.length > 0) {
    return r.views.map(RenderRoute);
  }

  return <Route key={r.name} path={r.path} component={r.component} exact={true} />;
};

interface Props {
  routes: RouteModel[];
  location: any;
}

export const SwitchRoutes = ({ routes, location }: Props): JSX.Element => {
  return (
    <Switch location={location}>
      {routes.map(RenderRoute)}
      <Route component={NotFound} exact={true} />
    </Switch>
  );
};

export default SwitchRoutes;
