import * as React from 'react';
import { SupplierInvoiceLineItemResponse } from '@vulcan/purchasing-api-client/esm/models';
import { List } from '@material-ui/core';
import InvoiceLineItem from './InvoiceLineItem';

const InvoiceLinesList = ({ lineItems }): JSX.Element => {
  const content = lineItems.map((item: SupplierInvoiceLineItemResponse, index: number) => (
    <InvoiceLineItem lineItem={item} key={index} />
  ));

  return <List className="invoice-lineitems-container">{content}</List>;
};

export default InvoiceLinesList;
