import { useEffect, useState } from 'react';
import { getTenantUrl, getTokenAsync } from 'src/App/Infrastructure/API';

export const usePurchasingApiTooltipData = (
    warehouseCode: string,
    productCode: string,
    open: boolean
) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [tooltipData, setTooltipData] = useState<TooltipData[]>([]);

    useEffect(() => {
        const fetchTooltipData = async () => {
            setLoading(true);
            setError(undefined);
            setTooltipData([]);

            const purchasingUrl = getTenantUrl(window.appSettings.purchasingApiUrl);
            const token = await getTokenAsync();

            const filterText =
                `(headerInfo/warehouse/code eq '${warehouseCode}' and line/product/code eq '${productCode}' ` +
                `and line/remainingStockQuantity gt 0 and line/lineState ne \'Cancelled\')`;
            const searchText = '';
            const orderBy = 'line/etaDate asc';
            const skip = 0;
            const top = 100;

            const querySettings = { filterText, searchText, orderBy };

            const pageSettings = { skip, top };
            const facetsToRetrieve = [
                'headerInfo/warehouse/code',
                'headerInfo/vendor/code',
                'line/lineState',
                'line/createdDateTimeUtc,interval:month',
                'line/etdDate,interval:month',
                'line/etaDate,interval:month',
            ];

            const request = { querySettings, pageSettings, facetsToRetrieve };
            const url = `${purchasingUrl}/api/v12/procurementLines/search`;

            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `bearer ${token}`,
                },
                body: JSON.stringify(request),
            };

            try {
                const response = await fetch(url, options);
                const data = await response.json();
                const parsedData = data.results.map((result) => {
                    const orderNumber = result.procurementOrderDocumentNumber;
                    const vendorCode = data.relatedData.procurementOrders[orderNumber].vendorCode;
                    const lineId = result.line.lineId;
                    const onOrder = result.line.remainingStockQuantity;
                    const etaDate = result.line.etaDate;
                    return { lineId, vendorCode, onOrder, etaDate };
                });
                setTooltipData(parsedData);
            } catch (e) {
                setError(e as Error);
            } finally {
                setLoading(false);
            }
        };
        if (open) {
            fetchTooltipData();
        }
    }, [open]);

    return {
        tooltipData,
        isLoadingTooltipData: loading,
        errorLoadingTooltipData: error,
    };
};

export interface TooltipData {
    lineId: string;
    vendorCode: string;
    onOrder: number;
    etaDate: string;
}
