import * as React from 'react';
import { observer, inject } from 'mobx-react';
import {
  GridContainer,
  GridItem,
  Icon,
  Card,
  CardHeader,
  CardBody,
  CardIcon,
  Snackbar,
} from '@vulcan/vulcan-materialui-theme';
import { Switch, withStyles } from '@material-ui/core';
import { IconResources } from 'src/Shared/Constants';
import { StockFilter } from 'src/Shared/StockFilterControl/StockFilter';
import ReorderTable from './ReorderTable';
import ReorderStore from './ReorderStore';
import { RouteComponentProps } from 'react-router-dom';
import ReorderExport from './ReorderExport';
import { StockFilterStore } from 'src/Shared/StockFilterControl/StockFilterStore';

const styles = {
  colorSwitchBase: {
    color: '#fafafa',
    '&$colorChecked': {
      color: '#479fd1',
      '& + $colorBar': {
        backgroundColor: '#479fd1',
      },
    },
    height: 'auto',
  },
  colorBar: {},
  colorChecked: {},
};

interface Props extends RouteComponentProps {
  classes?: any;
  reorderStore?: ReorderStore;
  reorderFilterStore?: StockFilterStore;
}

@inject('reorderStore', 'reorderFilterStore')
@observer
class ReorderLayout extends React.Component<Props> {
  public render(): React.ReactNode {
    const { classes, reorderStore, reorderFilterStore } = this.props;

    return (
      <div className="container">
        {/* Filter */}
        <StockFilter
          stockFilterStore={reorderFilterStore}
          showStockWarehouse={true}
          onFilterApplied={async (
            branchCodes: string[],
            warehouseCodes: string[],
            productCategoryIds: number[],
            productGroupIds: number[],
            stockClassificationIds: number[]
          ): Promise<void> => {
            await reorderStore!.updateTableControl(
              branchCodes,
              warehouseCodes,
              productCategoryIds,
              productGroupIds,
              stockClassificationIds
            );
          }}
        />

        {/* Table */}
        <GridContainer>
          <GridItem xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="card">
              <CardHeader color="primary" icon={true} className="card-header">
                <CardIcon className="card-icon" color="warning">
                  <Icon icon={IconResources.REORDER} />
                </CardIcon>
                <div className="card-header-section">
                  <h4 className="card-title">View Stock</h4>
                  <fieldset className="card-header-setting-fieldset">
                    <label>Group Sales Branch</label>
                    <Switch
                      classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                      }}
                      checked={reorderStore!.groupByWarehouses}
                      onChange={this.handleGroupByWarehousesClick}
                    />
                  </fieldset>
                  <fieldset className="card-header-setting-fieldset">
                    <label>Group Stock Warehouse</label>
                    <Switch
                      classes={{
                        switchBase: classes.colorSwitchBase,
                        checked: classes.colorChecked,
                      }}
                      checked={reorderStore!.groupByBranches}
                      onChange={this.handleGroupByBranchesClick}
                    />
                  </fieldset>
                  <ReorderExport />
                </div>
              </CardHeader>
              <CardBody>
                <ReorderTable />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <Snackbar
          place="br"
          color={reorderFilterStore!.alertType || reorderStore!.alertType}
          icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
          message={reorderFilterStore!.alertMessage || reorderStore!.alertMessage}
          open={reorderFilterStore!.alert || reorderStore!.alert}
          closeNotification={this.hideAlert}
          close={true}
        />
      </div>
    );
  }

  private handleGroupByWarehousesClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    _checked: boolean
  ): void => {
    if (this.props.reorderStore) {
      this.props.reorderStore!.setGroupByWarehouses(event.target.checked);
    }
  };

  private handleGroupByBranchesClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    _checked: boolean
  ): void => {
    if (this.props.reorderStore) {
      this.props.reorderStore!.setGroupByBranches(event.target.checked);
    }
  };

  private hideAlert = (): void => {
    if (this.props.reorderStore && this.props.reorderFilterStore) {
      this.props.reorderFilterStore!.hideAlert();
      this.props.reorderStore!.hideAlert();
    }
  };
}
export default withStyles(styles)(ReorderLayout);
