import { AppSettings } from '../Infrastructure';
import { HttpService } from './HttpService';
// tslint:disable-next-line:no-var-requires
const rg4js = require('raygun4js');

export class SettingsService {
  public static tenantsUrls: Map<string, string> = new Map();

  public static async loadSettings(): Promise<AppSettings> {
    const response = await fetch(
      `${window.location.origin}/${
        process.env.REACT_APP_STAGING === 'true' ? 'stagingAppSettings' : 'appsettings'
      }.json`,
      {
        method: 'get',
      }
    );
    const validatedResponse = await HttpService.checkStatus(response);
    const data = (await validatedResponse.json()) as AppSettings;
    // This value is assigned manually here and not read from appSettings as this value cannot
    // be a static string.
    data.postLogoutRedirectUri = window.location.origin;
    window.appSettings = data as AppSettings;

    return data;
  }

  public static configureCrashReporting(): void {
    const apiKey = window.appSettings.raygunApiKey;
    rg4js('apiKey', apiKey);
    // Attaches Raygun to the `window.onerror` handler. Enables unhandled errors to be automatically tracked.
    rg4js('enableCrashReporting', true);
  }
}

export default SettingsService;
