import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { roundForDisplay, toFixedNumber, toLocalTime } from 'src/Shared/Utils';
import { sumBy } from 'lodash';
import { usePurchasingApiTooltipData } from 'src/ProcurementLine/hooks/usePurchasingApiTooltipData';
import { StringResources } from 'src/Shared/Constants';
import AlertType from 'src/Shared/Alert/AlertType';
import { Skeleton } from '@material-ui/lab';
import { Tooltip } from 'src/Shared/Tooltip/Tooltip';
import { TableCellHeading } from 'src/Shared/Tooltip/TableCellHeading';
import { TableContainer } from 'src/Shared/Tooltip/TableContainer';

export interface StockDetailOnOrderTooltipProps {
  warehouseCode: string;
  productCode: string;
  onOrder: number;
  loading?: boolean;
  showAlert: (type: AlertType, message: string) => void;
  value: string;
}

export const StockDetailOnOrderTooltip = ({
  warehouseCode,
  productCode,
  onOrder,
  showAlert,
  value,
}: StockDetailOnOrderTooltipProps) => {
  const [open, setOpen] = useState(false);
  const { tooltipData, isLoadingTooltipData, errorLoadingTooltipData } =
    usePurchasingApiTooltipData(warehouseCode, productCode, open);

  const other = useMemo(() => {
    const tooltipOnOrderSum = sumBy(tooltipData, (line) => line.onOrder);
    return Math.trunc(onOrder - toFixedNumber(tooltipOnOrderSum, 0));
  }, [tooltipData]);
  useEffect(() => {
    if (errorLoadingTooltipData) {
      setOpen(false);
      showAlert(AlertType.danger, StringResources.ErrorPopulatingTooltip);
    }
  }, [errorLoadingTooltipData]);

  const onOrderToolTipTitle = (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellHeading>Vendor</TableCellHeading>
            <TableCellHeading>On Order</TableCellHeading>
            <TableCellHeading>ETA Date</TableCellHeading>
          </TableRow>
        </TableHead>
        {isLoadingTooltipData ? (
          <LoadingSkeleton />
        ) : (
          <TableBody>
            {tooltipData.map((procurementLine) => (
              <TableRow key={procurementLine.lineId}>
                <TableCell>{procurementLine.vendorCode}</TableCell>
                <TableCell align="right">{toFixedNumber(procurementLine.onOrder, 0)}</TableCell>
                <TableCell>{toLocalTime(procurementLine.etaDate).format('DD/MM/YY')}</TableCell>
              </TableRow>
            ))}
            {other !== 0 && (
              <TableRow>
                <TableCell>Other</TableCell>
                <TableCell align="right">{other}</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );

  return (
    <Tooltip
      title={onOrderToolTipTitle}
      open={open}
      onOpen={() => setOpen(!errorLoadingTooltipData)}
      onClose={() => setOpen(false)}
      value={value}
    />
  );
};

const LoadingSkeleton = () => (
  <TableBody>
    <TableRow>
      <TableCell colSpan={3}>
        <Skeleton variant="rect" height={10} />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={3}>
        <Skeleton variant="rect" height={10} />
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell colSpan={3}>
        <Skeleton variant="rect" height={10} />
      </TableCell>
    </TableRow>
  </TableBody>
);
