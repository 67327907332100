export class AppSettings {
  public cacheLocation: string;
  public dateFormat: string;
  public excelDateFormat: string;
  public displayDateFormat: string;
  public graphApiUrl: string;
  public msLoginUrl: string;
  public postLogoutRedirectUri: string;
  public raygunApiKey: string;
  public applicationInsightsKey: string;
  public azureAppId: string;
  public azureTenantId: string;
  public securityServiceUrl: string;
  public websitepathprefix: string;
  public securityApiUrl: string;
  public purchasingApiUrl: string;
  public docsApiUrl: string;
  public salesApiUrl: string;
  public inventoryApiUrl: string;

  constructor() {
    this.cacheLocation = '';
    this.dateFormat = '';
    this.excelDateFormat = '';
    this.displayDateFormat = '';
    this.graphApiUrl = '';
    this.msLoginUrl = '';
    this.postLogoutRedirectUri = '';
    this.raygunApiKey = '';
    this.applicationInsightsKey = '';
    this.azureAppId = '';
    this.azureTenantId = '';
    this.securityServiceUrl = '';
    this.websitepathprefix = '';
    this.securityApiUrl = '';
    this.purchasingApiUrl = '';
    this.docsApiUrl = '';
    this.salesApiUrl = '';
    this.inventoryApiUrl = '';
  }
}

export default AppSettings;

declare global {
  interface Window {
    appSettings: AppSettings;
    isAuthenticated: boolean;
    getToken: Function | null;
    adalInstance: any;
    Raygun: any;
    authenticate: any;
  }
}

window.appSettings = window.appSettings || {};
window.adalInstance = null;
window.getToken = null;
window.Raygun = null;
