import * as React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Card,
  CardHeader,
  CardBody,
  CardIcon,
  Icon,
  Button,
  modalStyle,
  Snackbar,
} from '@vulcan/vulcan-materialui-theme';
import { RouteComponentProps } from 'react-router-dom';
import RequestStore from './RequestStore';
import RequestTable from './RequestTable';
import { IconResources } from 'src/Shared/Constants';
import { RequestExportButton } from './RequestExportButton';
import { WithStyles, withStyles } from '@material-ui/core';
import RequestConfirmationDialog from 'src/Reorder/Request/RequestConfirmationDialog';
import RequestFilter from 'src/Shared/RequestFilterControl/RequestFilter';
import { UserProfileStore } from 'src/App';
import { StockTableStore } from '../Stock/StockTable/StockTableStore';

interface Props extends RouteComponentProps, WithStyles<typeof modalStyle> {
  requestStore?: RequestStore;
  userProfileStore: UserProfileStore;
  stockTableStore?: StockTableStore;
}

@inject('requestStore', 'stockTableStore')
@observer
class RequestLayout extends React.Component<Props> {
  state = {
    originalUniqueBranches: [],
    originalProductGroups: [],
    originalRequestedBy: [],
  };

  public async componentDidMount(): Promise<void> {
    const { requestStore } = this.props;
    requestStore!.applyFilter({
      branchCodes: [],
      requestedBy: [],
      productGroups: []
    });
    await requestStore!.init();

    const uniqueBranches = requestStore!.uniqueBranches;
    const uniqueRequestedBy = requestStore!.uniqueRequestBy;
    const uniqueProductGroup = requestStore!.uniqueProductGroup;

    this.setState({
      originalUniqueBranches: uniqueBranches,
      originalProductGroups: uniqueProductGroup,
      originalRequestedBy: uniqueRequestedBy,
    });
  }

  public render(): React.ReactNode {
    const { requestStore, stockTableStore } = this.props;

    const saveAmendedQuantity = async (): Promise<void> => {
      await requestStore!.saveAmendedQuantity(true);
    };

    return (
      <div className="container">

        {/* Filter */}
        <RequestFilter
          branches={this.state.originalUniqueBranches}
          productGroups={this.state.originalProductGroups}
          requestedBy={this.state.originalRequestedBy}
          applyFilter={requestStore!.applyFilter}
        />

        {/* Table */}
        <Card className="card">
          <CardHeader color="primary" icon={true} className="card-header">
            <CardIcon className="card-icon" color="warning">
              <Icon icon={IconResources.REORDER} />
            </CardIcon>
            <div className="card-header-section">
              <h4 className="card-title">Reorder Request</h4>
              <RequestExportButton
                data={requestStore!.tableData.filter(d => d.requestedQuantity && d.requestedQuantity > 0)}
              />
              <Button
                type="button"
                color="success"
                disabled={!requestStore!.hasAmendedQuantities}
                onClick={saveAmendedQuantity}
              >
                Save amended qty
              </Button>
              <Button
                type="button"
                color="success"
                disabled={!requestStore!.hasItemsToConfirm}
                onClick={(): void => requestStore!.openConfirmDialog()}
              >
                Confirm request
              </Button>
              {requestStore!.hasItemsToRest && (<Button
                type="button"
                color="primary"
                onClick={async () => {
                  await requestStore!.resetRequest();
                  await stockTableStore!.reloadTableControl();
                }}
              >
                Reset
              </Button>)}
            </div>
          </CardHeader>
          <CardBody>
            <RequestTable />
          </CardBody>
        </Card>
        <RequestConfirmationDialog />
        <Snackbar
          place="br"
          color={requestStore!.alertType}
          icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
          message={requestStore!.alertMessage}
          open={requestStore!.alert}
          closeNotification={(): void => requestStore!.hideAlert()}
          close={true}
        />
      </div>
    );
  }
}
export default withStyles(modalStyle)(RequestLayout);
