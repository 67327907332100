import * as React from 'react';
import { SupplierInvoiceModel } from 'src/Invoice/SupplierInvoiceModel';
import { observable, computed, action } from 'mobx';
import { SupplierInvoiceLineItemResponse } from '@vulcan/purchasing-api-client/esm/models';
import { FormInput } from '@vulcan/vulcan-materialui-theme';
import { observer } from 'mobx-react';
import DetailsViewHeader from './DetailsViewHeader';
import EmptySearchResults from '../../Shared/EmptySearchResults';
import InvoiceLinesList from './InvoiceLines/InvoiceLinesList';

interface DetailsViewLayoutProps {
  selectedInvoice: SupplierInvoiceModel;
}

@observer
class DetailsViewLayout extends React.Component<DetailsViewLayoutProps, {}> {
  @observable private searchValue: string;

  constructor(props: DetailsViewLayoutProps) {
    super(props);
    this.searchValue = '';
  }

  @action public UNSAFE_componentWillUpdate = (newProps: DetailsViewLayoutProps): void => {
    if (
      !newProps.selectedInvoice.invoiceNumber ||
      !newProps.selectedInvoice.invoiceNumber ||
      this.props.selectedInvoice.invoiceNumber !== newProps.selectedInvoice.invoiceNumber
    ) {
      this.searchValue = '';
    }
  };

  public render(): JSX.Element | null {
    const showNoLineItems =
      this.props.selectedInvoice && this.props.selectedInvoice.lineItems.length === 0;
    const showNoSearchResults = this.filteredLineItems.length === 0 && !showNoLineItems;
    return (
      <div className="details-view-container">
        <DetailsViewHeader selectedInvoice={this.props.selectedInvoice} />
        {!showNoLineItems && (
          <FormInput
            className="search-supplier-invoices"
            placeholder="Search Invoice Lines"
            value={this.searchValue}
            handleInputChange={this.handleSearchInputChange}
            showClearIcon={this.searchValue.length > 0}
            handleClearIconClick={this.handleSearchInputClear}
            showAdornment={true}
            showLabel={false}
            validated={false}
          />
        )}
        <InvoiceLinesList lineItems={this.filteredLineItems} />
        <EmptySearchResults show={showNoSearchResults} />
        {showNoLineItems && (
          <div className="no-invoice-line-items">This invoice has no line items.</div>
        )}
      </div>
    );
  }

  @computed public get filteredLineItems(): SupplierInvoiceLineItemResponse[] {
    const self = this;
    if (self.props.selectedInvoice) {
      return self.searchValue
        ? self.props.selectedInvoice.lineItems.filter(
            (l: SupplierInvoiceLineItemResponse) =>
              l.supplierItemCode.toLowerCase().indexOf(self.searchValue.trim().toLowerCase()) >
                -1 ||
              (l.description || '').toLowerCase().indexOf(self.searchValue.trim().toLowerCase()) >
                -1
          )
        : self.props.selectedInvoice.lineItems;
    }
    return [];
  }

  @action private handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.searchValue = e.target.value;
  };

  @action private handleSearchInputClear = (): void => {
    this.searchValue = '';
  };
}

export default DetailsViewLayout;
