import React from 'react';
import ReactTable, { CellInfo, Column, RowInfo } from 'react-table';
import { inject, observer } from 'mobx-react';
import { StringResources } from 'src/Shared/Constants';
import { StockDetailTableStore } from './StockDetailTableStore';
import { Button } from '@vulcan/vulcan-materialui-theme';
import { StockDetailTableModel } from './StockDetailTableModel';
import { dashIfZero, toFixedNumber, toFormatDate, toNumberFormat } from 'src/Shared/Utils';
import { NumberInput } from 'src/Shared/NumberInput/NumberInput';
import moment from 'moment';
import { StockDetailOnOrderTooltip } from './StockDetailOnOrderTooltip';
import { StockDetailEtaDateTooltip } from './StockDetailEtaDateTooltip';
import { TextInput } from 'src/Shared/NumberInput/TextInput';

interface Props {
  stockDetailTableStore?: StockDetailTableStore;
  productCode: string;
}

@inject('stockDetailTableStore')
@observer
export class StockDetailTable extends React.Component<Props, { expanded: {} }> {
  constructor(props: Props) {
    super(props);
    this.state = { expanded: {} };
  }

  public render(): JSX.Element {
    const { stockDetailTableStore: store } = this.props;
    const expanded = store!.expanded;

    const grandchildComponent = (rowInfo: RowInfo): React.ReactNode => {
      const data = rowInfo.original?.children?.slice();
      return data && data.length > 0 ? (
        <ReactTable
          data={data}
          columns={this.childTableColumns}
          className="react-table table-font"
          minRows={0}
          filterable={false}
          sortable={false}
          defaultSorted={[{ id: 'warehouse', desc: false }]}
          showPageJump={false}
          showPageSizeOptions={false}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPagination={false}
          collapseOnDataChange={false}
          collapseOnSortingChange={false}
          collapseOnPageChange={false}
          resizable={false}
        />
      ) : (
        <></>
      );
    };

    const childComponent = (rowInfo: RowInfo): React.ReactNode => {
      const data = rowInfo.original?.children?.slice();
      const rowIndex = rowInfo.index;
      return data && data.length > 0 ? (
        <ReactTable
          data={data}
          columns={this.childTableColumns}
          className="react-table table-font"
          minRows={0}
          onExpandedChange={(newChildExpanded): void =>
            this.onChildExpandedChange(newChildExpanded, rowIndex)
          }
          expanded={expanded[rowIndex]}
          filterable={false}
          sortable={false}
          defaultSorted={[{ id: 'sales-branch', desc: false }]}
          showPageJump={false}
          showPageSizeOptions={false}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPagination={false}
          collapseOnDataChange={false}
          collapseOnSortingChange={false}
          collapseOnPageChange={false}
          resizable={false}
          SubComponent={grandchildComponent}
        />
      ) : (
        <></>
      );
    };
    return (
      <>
        <div className="expend-row">
          {Object.values(expanded).every((v) => !v) && (
            <Button
              className="expend-row-button"
              type="button"
              color="success"
              onClick={this.expandAll}
            >
              Expand All
            </Button>
          )}
          {Object.values(expanded).some((v) => v) && (
            <Button
              className="expend-row-button"
              type="button"
              color="success"
              onClick={(): void => {
                this.onExpandedChange({});
              }}
            >
              Collapse All
            </Button>
          )}
        </div>
        <ReactTable
          data={store!.tableData.slice()}
          columns={this.tableColumns}
          loading={store!.tableDataLoading}
          noDataText={store!.tableDataLoading ? '' : StringResources.TableNoRowsFound}
          minRows={store!.tableData.length > 0 ? 0 : 5}
          onExpandedChange={this.onExpandedChange}
          expanded={expanded}
          className="react-table react-table-divider react-table-sticky table-font"
          filterable={false}
          sortable={false}
          SubComponent={childComponent}
          showPageJump={false}
          showPageSizeOptions={false}
          showPaginationTop={false}
          showPaginationBottom={false}
          showPagination={false}
          collapseOnDataChange={false}
          collapseOnSortingChange={false}
          collapseOnPageChange={false}
          resizable={false}
        />
      </>
    );
  }
  public get tableColumns(): Column<StockDetailTableModel>[] {
    const renderRequestedQuantity = (cell: CellInfo): JSX.Element => {
      const setRequestedQuantity = (value: string): void =>
        this.props.stockDetailTableStore!.setRequestedQuantity(
          cell.original.salesProfitCentreCode,
          cell.index,
          value
        );

      if (cell.original.showRequestedQtyInput) {
        return <NumberInput value={cell.value ?? ''} onChange={setRequestedQuantity} />;
      }

      if (cell.original.showBold) {
        return <strong>{toNumberFormat(cell.value)}</strong>;
      }

      return <></>;
    };

    const renderComment = (cell: CellInfo): JSX.Element => {
      const setComment = (value: string): void =>
        this.props.stockDetailTableStore!.setComment(
          cell.original.salesProfitCentreCode,
          cell.index,
          value
        );

      if (cell.original.showRequestedQtyInput) {
        return <TextInput value={cell.value ?? ''} onChange={setComment} maxLength={20} />;
      }

      return <></>;
    };

    const stockLevelInfoAggregatedCell = (cell: CellInfo): JSX.Element => {
      return cell.original.showBold === true ? (
        <strong>{toNumberFormat(cell.value)}</strong>
      ) : (
        <>{toNumberFormat(cell.value)}</>
      );
    };

    const EmptyIfNull = (value: number | null): string => {
      return value === null || value === undefined ? '' : dashIfZero(value);
    };

    const stockSalesInfoAggregatedCell = (cell: CellInfo): JSX.Element =>
      cell.original.showBold === true ? (
        <strong>{EmptyIfNull(cell.value)}</strong>
      ) : (
        <>{EmptyIfNull(cell.value)}</>
      );

    const sortMethodWithTotal = (a: string, b: string): number => {
      if (a === 'Total' || b === 'Total') {
        return 1;
      }
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    };

    const startHeaderStyle = { display: 'flex', justifyContent: 'flex-start', marginLeft: 0 };
    const startStyle = { whiteSpace: 'unset', justifyContent: 'flex-start', marginLeft: 0 };
    const endHeaderStyle = { display: 'flex', justifyContent: 'flex-end', marginLeft: 0 };
    const endStyle = { whiteSpace: 'unset', justifyContent: 'flex-end', marginLeft: 0 };

    const verticalBorder = '3px solid rgba(224, 224, 224, 1)';
    const verticalInvisibleBorder = '3px solid white';

    const headerBorder = {
      margin: 0,
      height: 'auto',
      borderLeft: verticalBorder,
      borderRight: verticalBorder,
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: 'white',
    };

    const colouredColumnHeader = { ...endHeaderStyle, paddingRight: 5 };

    const colouredColumn = {
      ...endStyle,
      marginBottom: 0,
      marginTop: 0,
      height: 'auto',
      display: 'flex',
      backgroundColor: '#e6e6e6',
      paddingRight: 5,
    };

    const gapCellStyle = {
      ...colouredColumn,
      borderLeft: verticalInvisibleBorder,
      borderRight: verticalInvisibleBorder,
      backgroundColor: 'white',
      justifyContent: 'center',
      margin: 0,
      paddingRight: 10,
    };

    const columnGap = {
      maxWidth: 10,
      headerStyle: headerBorder,
      style: gapCellStyle,
    };

    return [
      {
        headerStyle: { display: 'flex' },
        style: { padding: '0px' },
        expander: true,
        width: 25,
        Expander: ({ isExpanded, ...rest }): JSX.Element => {
          if (rest.original?.children?.length > 0) {
            return (
              <>
                {[...Array(rest.original?.levelNumber)].map((_v, i) => (
                  <span key={i} />
                ))}
                <div className={`rt-expander ${isExpanded ? '-open' : ''}`}>•</div>
              </>
            );
          }
          return <span />;
        },
      },
      {
        id: 'sales-branch',
        Header: 'Sales Branch',
        accessor: 'salesBranchCode',
        width: 70,
        headerClassName: 'wordwrap-alignLeft',
        headerStyle: startHeaderStyle,
        style: startStyle,
        sortMethod: sortMethodWithTotal,
        Cell: (cellInfo: CellInfo): JSX.Element =>
          cellInfo.original.showBold === true ? <strong>{cellInfo.value}</strong> : cellInfo.value,
      },
      {
        id: 'warehouse',
        Header: 'Whse',
        accessor: 'warehouseCode',
        width: 60,
        headerClassName: 'wordwrap-alignLeft',
        headerStyle: startHeaderStyle,
        style: startStyle,
      },
      {
        Header: 'Class',
        accessor: 'stockClass',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: {
          marginTop: '0px',
          marginBottom: '0px',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 0,
          marginRight: 0,
        },
        style: {
          marginTop: '-1px',
          marginBottom: '-1px',
          height: 'auto',
          whiteSpace: 'unset',
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 0,
          marginRight: 0,
        },
        Cell: (cell: CellInfo): JSX.Element => (
          <div className="product-class-cell" style={{ backgroundColor: cell.value?.color }}>
            {cell.value?.code}
          </div>
        ),
      },
      columnGap,
      {
        Header: 'Avail Stock',
        accessor: 'availableNonSerialStock',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: colouredColumnHeader,
        style: { ...colouredColumn, paddingRight: 5 },
        Cell: stockLevelInfoAggregatedCell,
      },
      {
        Header: 'Avail Ser Stock',
        accessor: 'availableSerialStock',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockLevelInfoAggregatedCell,
      },
      {
        Header: 'On Order',
        accessor: 'onOrder',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: (cell: CellInfo) => {
          if (cell.value && cell.original.warehouseCode) {
            return (
              <StockDetailOnOrderTooltip
                warehouseCode={cell.original.warehouseCode}
                productCode={this.props.productCode}
                onOrder={cell.original.onOrder}
                showAlert={this.props.stockDetailTableStore!.showAlert}
                value={toFixedNumber(cell.value, 1).toString()}
              />
            );
          } else if (cell.value) {
            return <span>{toFixedNumber(cell.value, 1).toString()}</span>;
          } else if (cell.original.showBold === true) {
            return <strong>-</strong>;
          } else if (EmptyIfNull(cell.original.warehouseCode)) {
            return '-';
          } else {
            return <></>;
          }
        },
      },
      {
        Header: 'Total',
        accessor: 'total',
        width: 80,
        headerClassName: 'wordwrap',
        headerStyle: colouredColumnHeader,
        style: colouredColumn,
        Cell: stockLevelInfoAggregatedCell,
      },
      {
        Header: 'Comm Stock',
        accessor: 'committed',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockLevelInfoAggregatedCell,
      },
      {
        Header: 'ETA',
        accessor: 'eta',
        width: 70,
        headerClassName: 'wordwrap',
        headerStyle: { display: 'flex', justifyContent: 'center' },
        style: { whiteSpace: 'unset', justifyContent: 'center' },
        Cell: (cell: CellInfo): JSX.Element =>
          cell.row.salesBranchCode === 'Total' ||
            (cell.original?.children?.length > 0 && !cell.value) ||
            !cell.original.showRequestedQtyInput ? (
            <></>
          ) : (
            <>
              {cell.value && (
                <StockDetailEtaDateTooltip
                  etaStatus={cell.original.etaStatus}
                  remainingQty={cell.original.remainingQty}
                  value={toFormatDate('DD/MM/YY', cell.value)}
                />
              )}
            </>
          ),
      },
      {
        id: 'requested-qty',
        Header: 'Req Qty',
        accessor: 'requestedQty',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: { display: 'flex', justifyContent: 'center' },
        style: { whiteSpace: 'unset', justifyContent: 'center' },
        Cell: renderRequestedQuantity,
      },
      {
        id: 'comment',
        Header: 'Comment',
        accessor: 'comment',
        width: 100,
        headerClassName: 'wordwrap',
        headerStyle: { display: 'flex', justifyContent: 'flex-end' },
        style: { whiteSpace: 'unset', paddingLeft: '10px', justifyContent: 'flex-end' },
        Cell: renderComment,
      },
      columnGap,
      {
        Header: 'Avg Sales',
        accessor: 'averageMonthSales',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: colouredColumnHeader,
        style: colouredColumn,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: 'Current',
        accessor: 'thisMonth',
        width: 60,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-1, 'month').format('MMM'),
        accessor: 'oneMonthAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-2, 'month').format('MMM'),
        accessor: 'twoMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-3, 'month').format('MMM'),
        accessor: 'threeMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-4, 'month').format('MMM'),
        accessor: 'fourMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-5, 'month').format('MMM'),
        accessor: 'fiveMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-6, 'month').format('MMM'),
        accessor: 'sixMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-7, 'month').format('MMM'),
        accessor: 'sevenMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-8, 'month').format('MMM'),
        accessor: 'eightMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-9, 'month').format('MMM'),
        accessor: 'nineMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-10, 'month').format('MMM'),
        accessor: 'tenMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
      {
        Header: moment().add(-11, 'month').format('MMM'),
        accessor: 'elevenMonthsAgo',
        width: 50,
        headerClassName: 'wordwrap',
        headerStyle: endHeaderStyle,
        style: endStyle,
        Cell: stockSalesInfoAggregatedCell,
      },
    ];
  }

  public get childTableColumns(): Column<StockDetailTableModel>[] {
    const columnsHideHeader = [...this.tableColumns];
    columnsHideHeader.forEach((column) => {
      if (column.headerStyle) {
        const propertyName = 'display';
        column.headerStyle[propertyName] = 'none';
      } else {
        column.headerStyle = { display: 'none' };
      }
    });
    return columnsHideHeader;
  }

  private expandAll = (): void => {
    const expandArray = {};
    let ind = 0;
    this.props.stockDetailTableStore!.tableData.forEach((element) => {
      const children = element.children;
      children.forEach((child) => {
        expandArray[ind] = child;
        ind++;
      });
    });
    this.onExpandedChange(expandArray);
  };

  private onExpandedChange = (newExpanded): void => {
    this.props.stockDetailTableStore!.updateExpanded(newExpanded);
  };

  private onChildExpandedChange = (newChildExpanded, index): void => {
    this.props.stockDetailTableStore!.updateChildExpanded(newChildExpanded, index);
  };
}
