import { BaseStore } from '../Infrastructure';
import { observable, action, computed, runInAction } from 'mobx';
import * as Cookies from 'js-cookie';
import BranchModel from 'src/Shared/StockFilterControl/Models/BranchModel';
import AlertType from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';
import { OptionModel, GroupOptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import { groupBy, forEach } from 'lodash';
import { localeCompare } from 'src/Shared/Utils';

export class SettingStore extends BaseStore {
  @observable private allBranches: BranchModel[] = [];
  @observable public loading = false;
  @observable public selectedSaleBranchCodes: string[] = [];

  // Be carful!, Javascript will do Boolean("false");  // == true
  @observable private _miniActive: boolean = Cookies.get('miniActive')
    ? Cookies.get('miniActive').toLowerCase() === 'true'
    : false;

  @computed get miniActive(): boolean {
    return this._miniActive;
  }

  @action public handelMiniModeToggle = (): void => {
    this._miniActive = !this._miniActive;
    Cookies.set('miniActive', this._miniActive, { expires: 7 /* days*/ });
  };

  @observable private detailsPageFilterBySalesBranch: boolean = Cookies.get('detailsPageFilterBySalesBranch')
  ? Cookies.get('detailsPageFilterBySalesBranch').toLowerCase() === 'true'
  : false;

  @computed get isDetailsPageFilterBySalesBranch(): boolean {
    return this.detailsPageFilterBySalesBranch;
  }

  @action public handelDetailsPageFilterBySalesBranchToggle = (): void => {
    this.detailsPageFilterBySalesBranch = !this.detailsPageFilterBySalesBranch;
    Cookies.set('detailsPageFilterBySalesBranch', this.detailsPageFilterBySalesBranch, { expires: 365 /* days*/ });
  };

  @observable private detailsPageCollapseAllByDefault: boolean = Cookies.get('detailsPageCollapseAllByDefault')
  ? Cookies.get('detailsPageCollapseAllByDefault').toLowerCase() === 'true'
  : false;

  @computed get isDetailsPageCollapseAllByDefault(): boolean {
    return this.detailsPageCollapseAllByDefault;
  }

  @action public handelDetailsPageCollapseAllByDefault = (): void => {
    this.detailsPageCollapseAllByDefault = !this.detailsPageCollapseAllByDefault;
    Cookies.set('detailsPageCollapseAllByDefault', this.detailsPageCollapseAllByDefault, { expires: 365 /* days*/ });
  };

  public get CurrentYear(): number {
    return this.BrowserTodayDate.year();
  }

  @action public async init(): Promise<void> {
    this.loading = true;

    const allBranches = await this.getAllBranches();
    const userBranches = this.GetUserBranches;

    runInAction(() => {
      this.allBranches = allBranches;
      this.loading = false;
      this.selectedSaleBranchCodes = userBranches;
    });
  }

  private async getAllBranches(): Promise<BranchModel[]> {
    let allBranches: BranchModel[] = [];
    try {
      const salesClient = await this.getSalesClient();
      const response = await salesClient.branches.getBranches();
      const result = response._response.parsedBody;
      allBranches = result
        .map((r) => new BranchModel(r.branchId, r.code, r.name ? r.name : '', r.profitCentreCode))
        .sort(BranchModel.compareFn);
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingBranchException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingBranchException);
    } finally {
      return allBranches;
    }
  }

  @computed public get allBranchDataSource(): GroupOptionModel[] {
    const allOptions: GroupOptionModel[] = [];
    if (this.allBranches) {
      const branchesByProfitCentreCode = groupBy<BranchModel>(
        this.allBranches,
        (item) => item.profitCentreCode
      );
      forEach(branchesByProfitCentreCode, (group: BranchModel[], key: string) => {
        allOptions.push(
          new GroupOptionModel({
            label: key,
            options: group
              .sort((x, y) => localeCompare(x.code, y.code))
              .map((b) => {
                return new OptionModel({
                  value: b.branchId.toString(),
                  label: b.code,
                });
              }),
          })
        );
      });
    }
    return allOptions;
  }

  @action public selectedSaleBranchChanged(selectedBranches: OptionModel[]): void {
    this.selectedSaleBranchCodes = selectedBranches.map((b) => b.label);
    Cookies.set('userBranch', this.selectedSaleBranchCodes, { expires: 365 /* days*/ });
  }
}

export default SettingStore;
