import * as React from 'react';
import { Button, Icon } from '@vulcan/vulcan-materialui-theme';
import DetailsViewType from './ViewType';
import { IconResources } from '../../Shared/Constants';

interface Props {
  selectedView: DetailsViewType;
  handleChangeView: Function;
  noAttachmentAvailable: boolean;
}

function DetailsSectionNav({
  selectedView,
  handleChangeView,
  noAttachmentAvailable,
}: Props): JSX.Element {
  return (
    <div className="supplier-invoice-details-navigation">
      <Button
        className={selectedView === DetailsViewType.Details ? 'selected' : ''}
        justIcon={true}
        round={false}
        simple={false}
        onClick={(): void => handleChangeView(DetailsViewType.Details)}
      >
        <Icon icon={IconResources.DETAILS} color={'black'} />
      </Button>
      <Button
        className={selectedView === DetailsViewType.PDF ? 'selected' : ''}
        justIcon={true}
        round={false}
        simple={false}
        disabled={noAttachmentAvailable}
        onClick={(): void => handleChangeView(DetailsViewType.PDF)}
      >
        <Icon icon={IconResources.ATTACHMENT} color={'black'} />
      </Button>
    </div>
  );
}

export default DetailsSectionNav;
