import { BaseStore } from 'src/App/Infrastructure';
import { observable, action, runInAction, computed } from 'mobx';
import { RequestTableDataRowModel } from './Models';
import { StringResources } from 'src/Shared/Constants';
import AlertType from 'src/Shared/Alert/AlertType';
import { divide } from 'lodash';
import {
  formatEmailAsName,
  localeCompare,
  toFixedNumber,
} from 'src/Shared/Utils';
import {
  AmendReorderQuantitiesListRequest,
  AmendReorderQuantityRequest,
  DeleteReorderQuantityRequest,
} from '@vulcan/purchasing-api-client/esm/models';

export class RequestStore extends BaseStore {
  @observable public loading = false;
  @observable public isConfirmDialogOpen = false;
  @observable public tableData: RequestTableDataRowModel[] = [];

  private allTableData: RequestTableDataRowModel[] = [];
  private originalTableData: RequestTableDataRowModel[] = [];
  private currentBranchFilter: string[] = [];
  private currentRequestedByFilter: string[] = [];
  private currentProductGroupFilter: string[] = [];

  @computed public get uniqueBranches(): string[] {
    const branchSet = new Set(this.allTableData.filter(d => d.requestedQuantity && d.requestedQuantity > 0).map(row => row.orderingBranchCode));
    return Array.from(branchSet);
  }

  @computed public get uniqueRequestBy(): string[] {
    const requestBySet = new Set(this.allTableData.map(row => row.requestedBy));
    return Array.from(requestBySet);
  }

  @computed public get uniqueProductGroup(): string[] {
    const productGroupSet = new Set(this.allTableData.filter(d => d.requestedQuantity && d.requestedQuantity > 0).map(row => row.productGroup));
    return Array.from(productGroupSet);
  }

  @computed public get hasAmendedQuantities(): boolean {
    return this.tableData.filter((x) => x.isEdited).length > 0;
  }

  @computed public get hasItemsToConfirm(): boolean {
    return this.tableData.filter(x => x.requestedQuantity && x.requestedQuantity > 0).length > 0;
  }

  @computed public get hasItemsToRest(): boolean {
    return this.tableData.filter(x => x.requestedQuantity && x.requestedQuantity > 0).length === 0 &&
      this.tableData.filter(x => !x.requestedQuantity || x.requestedQuantity === 0).length > 0;
  }

  @action public hideConfirmDialog(): void {
    this.isConfirmDialogOpen = false;
  }

  @action public openConfirmDialog(): void {
    this.isConfirmDialogOpen = true;
  }

  @action public rollbackAmendedQuantity(
    productCode: string,
    orderingBranchCode: string,
  ): void {
    const originalData = this.originalTableData.find(d => d.productCode === productCode && d.orderingBranchCode === orderingBranchCode);

    const indexInTable = this.tableData.findIndex(d => d.productCode === productCode && d.orderingBranchCode === orderingBranchCode);
    this.tableData[indexInTable] = {
      ...this.tableData[indexInTable],
      amendedQuantity: originalData?.amendedQuantity ?? null,
      amendedBy: originalData?.amendedBy ?? null,
    };

    const indexInAllTable = this.allTableData.findIndex(d => d.productCode === productCode && d.orderingBranchCode === orderingBranchCode);
    this.allTableData[indexInAllTable] = {
      ...this.allTableData[indexInAllTable],
      amendedQuantity: originalData?.amendedQuantity ?? null,
      amendedBy: originalData?.amendedBy ?? null,
    };
  }

  @action public setAmendedQuantity(
    productCode: string,
    orderingBranchCode: string,
    amendedQuantity: string,
    amendedBy: string
  ): void {
    let updatedAmendedQuantity: number | null = null;
    let updatedAmendedBy: string | null = null;
    let updatedIsEdited = false;
    if (amendedQuantity !== '' && amendedQuantity !== '0') {
      updatedAmendedQuantity = Number(amendedQuantity);
      updatedAmendedBy = amendedBy;
      updatedIsEdited = true;
    }

    const indexInTable = this.tableData.findIndex(d => d.productCode === productCode && d.orderingBranchCode === orderingBranchCode);
    this.tableData[indexInTable] = {
      ...this.tableData[indexInTable],
      amendedQuantity: updatedAmendedQuantity,
      amendedBy: updatedAmendedBy,
      isEdited: updatedIsEdited,
    };

    const indexInAllTable = this.allTableData.findIndex(d => d.productCode === productCode && d.orderingBranchCode === orderingBranchCode);
    this.allTableData[indexInAllTable] = {
      ...this.allTableData[indexInAllTable],
      amendedQuantity: updatedAmendedQuantity,
      amendedBy: updatedAmendedBy,
      isEdited: updatedIsEdited,
    };
  }

  @action public async saveAmendedQuantity(refreshAfter: boolean): Promise<void> {
    const amendedItems = this.tableData.filter((x) => x.isEdited);
    if (amendedItems) {
      try {
        const purchasingClient = await this.getPurchasingClient();
        const entries: AmendReorderQuantityRequest[] = amendedItems.map(
          (item) =>
          ({
            productId: item.productId,
            branchCode: item.orderingBranchCode,
            amendedQuantity: item.amendedQuantity
          } as AmendReorderQuantityRequest)
        );
        const request: AmendReorderQuantitiesListRequest = { entries: entries };
        await purchasingClient.reorder.amendReorderQuantity(request);
        runInAction(() => {
          amendedItems.map((item) => (item.isEdited = false));
          this.showAlert(AlertType.success, StringResources.SavedAmendedQuantity);
        });
      } catch (e) {
        runInAction(() => {
          this.log(StringResources.ErrorSavingAmendedQuantityException, e as Error);
          this.showAlert(AlertType.danger, StringResources.ErrorSavingAmendedQuantityException);
        });
      } finally {
        if (refreshAfter === true) {
          await this.init();
        }
      }
    }
  }

  @action public async confirmRequest(): Promise<void> {
    try {
      const entries = this.tableData.map((data) => {
        return { branchCode: data.orderingBranchCode, productId: data.productId };
      });
      const purchasingClient = await this.getPurchasingClient();
      await purchasingClient.reorder.confirmReorderEntries({ entries: entries });
      this.showAlert(AlertType.success, StringResources.ConfirmRequest);
    } catch (e) {
      this.log(StringResources.ErrorConfirmingRequestException, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorConfirmingRequestException);
    } finally {
      await this.init();
    }
  };

  @action public async resetRequest(): Promise<void> {
    try {
      const entries = this.tableData
        .filter(x => !x.requestedQuantity || x.requestedQuantity === 0)
        .map((data) => ({
          productId: data.productId,
          branchCode: data.orderingBranchCode,
        } as DeleteReorderQuantityRequest));
      const purchasingClient = await this.getPurchasingClient();
      await purchasingClient.reorder.deleteReorderQuantity({ entries: entries });
      this.showAlert(AlertType.success, StringResources.ResetRequest);
    } catch (e) {
      this.log(StringResources.ErrorResetingRequestException, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorResetingRequestException);
    } finally {
      await this.init();
    }
  };

  @action public async init(): Promise<void> {
    this.loading = true;

    const allTableData = await this.getAllRequestData();
    this.allTableData = allTableData;
    this.originalTableData = [...allTableData];

    this.filterTable();
    runInAction(() => {
      this.loading = false;
    });
  }

  @action applyFilter = (filterOptions: { branchCodes: string[]; requestedBy: string[]; productGroups: string[] }) => {
    const { branchCodes, requestedBy, productGroups } = filterOptions;

    this.currentBranchFilter = branchCodes;
    this.currentRequestedByFilter = requestedBy;
    this.currentProductGroupFilter = productGroups;
    this.filterTable();
  };

  private filterTable = () => {
    runInAction(() => {
      if (this.currentBranchFilter.length === 0 && this.currentRequestedByFilter.length === 0 && this.currentProductGroupFilter.length === 0) {
        this.tableData = [];
      } else {
        this.tableData = this.allTableData.filter(data => {
          const isBranchIncluded = this.currentBranchFilter.length === 0 || this.currentBranchFilter.includes(data.orderingBranchCode);
          const isRequestedByIncluded = this.currentRequestedByFilter.length === 0 || this.currentRequestedByFilter.includes(data.requestedBy);
          const isProductGroupIncluded = this.currentProductGroupFilter.length === 0 || this.currentProductGroupFilter.includes(data.productGroup);
          return isBranchIncluded && isRequestedByIncluded && isProductGroupIncluded;
        });
      }
    });
  };

  private async getAllRequestData(): Promise<RequestTableDataRowModel[]> {
    let allRequests: RequestTableDataRowModel[] = [];
    try {
      const purchasingClient = await this.getPurchasingClient();
      const response = await purchasingClient.reorder.getRequestedReorderEntries();
      allRequests = response
        .map(
          (r) =>
            new RequestTableDataRowModel({
              ...r,
              productWeight: r.conversionFactor ? `${toFixedNumber(divide(1000, r.conversionFactor), 2)} KG` : '-',
              productGroup: r.productGroupName,
              productDescription: r.productDescription,
              dieCode: r.dieCode,
              orderingBranchCode: r.branchCode,
              stockClass: { code: r.stockClassificationCode, color: r.stockClassificationColour },
              averageMonthSales: toFixedNumber(r.averageMonthSales, 2),
              availableStock: toFixedNumber(r.inStock, 2),
              committed: toFixedNumber(r.committed, 2),
              pending: toFixedNumber(r.pending, 2),
              onOrder: toFixedNumber(r.onOrder, 2),
              requestedBy: formatEmailAsName(r.requestedBy),
              amendedQuantity: r.amendedQuantity,
              amendedBy: formatEmailAsName(r.amendedBy),
              comment: r.comment,
            })
        )
        .sort(
          (a: RequestTableDataRowModel, b: RequestTableDataRowModel) =>
            (!a.productCode || !b.productCode) ? 0 : localeCompare(a.productCode, b.productCode)
        );
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingReorderTableException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingReorderTableException);
    } finally {
      return allRequests;
    }
  }
}

export default RequestStore;
