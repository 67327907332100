import {
  createStyles,
  makeStyles,
  Paper,
  TableContainer as MuiTableContainer,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    tableContainer: {
      boxShadow: 'none',
      padding: 0,
      maxHeight: theme.spacing(110),
      width: '105%',
      border: `1px solid ${theme.palette.common.black}`,
    },
  })
);

export interface TableContainerProps {
  children: ReactNode;
}

export const TableContainer = ({ children }: TableContainerProps) => {
  const classes = useStyles();
  return (
    <MuiTableContainer className={classes.tableContainer} component={Paper}>
      {children}
    </MuiTableContainer>
  );
};
