import {
  SuppliersSearchSupplierInvoicesByStateOptionalParams,
  SupplierInvoiceSearchResponse,
} from '@vulcan/purchasing-api-client/esm/models';
import { BaseStore } from 'src/App/Infrastructure';
import { observable, action, computed, runInAction } from 'mobx';
import { StringResources } from '../Shared/Constants/StringResources';
import SupplierInvoiceModel from './SupplierInvoiceModel';
import InvoiceType from './InvoiceType';
import moment from 'moment';

type SearchProgress = 'active' | 'completed';
type AttachmentMediaType = 'image/jpeg' | 'image/png' | 'application/pdf';

export type SortInvoicesBy =
  | 'dueDate'
  | 'supplierCode'
  | 'purchaseOrderNumber'
  | 'ascending'
  | 'descending';

interface SearchParams {
  state: InvoiceType;
  query?: string;
  skip?: number;
  top?: number;
  count?: boolean;
}

export class SupplierInvoiceStore extends BaseStore {
  @observable public draftSupplierInvoices!: SupplierInvoiceModel[];
  @observable public matchedSupplierInvoices!: SupplierInvoiceModel[];
  @observable public supplierInvoicesSearchResults!: SupplierInvoiceModel[];
  @observable public searchInputValue!: string;
  @observable private _attachmentUri: string | null | undefined;
  @observable public attachmentMediaType!: AttachmentMediaType | undefined;
  @observable public selectedInvoice!: SupplierInvoiceModel | null;
  @observable public _activeSupplierInvoicesList: InvoiceType;
  public scrollToElement!: string | null;
  public totalResults!: number;
  public pages!: number;
  public currentPage!: number;
  @observable public orderByDescending!: boolean;
  @observable public sortBy!: SortInvoicesBy;
  @observable public loadingInvoiceAttachment!: boolean;
  @observable private searchProgress!: SearchProgress;
  private pageSize!: number;
  private skip!: number;
  private top!: number;

  constructor() {
    super();
    this.init();
    this._activeSupplierInvoicesList = InvoiceType.Draft;
  }

  @action public init(): void {
    this.draftSupplierInvoices = [];
    this.matchedSupplierInvoices = [];
    this.supplierInvoicesSearchResults = [];
    this.selectedInvoice = null;
    this.skip = 0;
    this.top = 10;
    this.totalResults = 10;
    this.pages = 0;
    this.pageSize = 10;
    this.currentPage = 1;
    this.searchInputValue = '';
    this._attachmentUri = null;
    this.attachmentMediaType = undefined;
    this.loadingInvoiceAttachment = false;
    this._activeSupplierInvoicesList = InvoiceType.Draft;
    this.scrollToElement = null;
    this.searchProgress = 'completed';
  }

  public get activeListType(): InvoiceType {
    return this._activeSupplierInvoicesList;
  }

  @action public setActiveListType(type: InvoiceType): void {
    this._activeSupplierInvoicesList = type;
  }

  @action public setSearchingProgress(status: SearchProgress): void {
    this.searchProgress = status;
  }

  @computed public get showEmptyListMessage(): boolean {
    if (this.isLoading) {
      return false;
    }

    if (
      this.searching &&
      this.searchProgress === 'completed' &&
      this.supplierInvoicesSearchResults.length === 0
    ) {
      return true;
    }

    if (
      !this.searching &&
      this.activeListType === InvoiceType.Draft &&
      this.draftSupplierInvoices.length === 0
    ) {
      return true;
    }

    if (
      !this.searching &&
      this.activeListType === InvoiceType.Complete &&
      this.matchedSupplierInvoices.length === 0
    ) {
      return true;
    }

    return false;
  }

  @computed public get searching(): boolean {
    return this.searchInputValue.length > 0;
  }

  public applyFilters = (first: SupplierInvoiceModel, second: SupplierInvoiceModel): number => {
    const compareItems = (
      a: SupplierInvoiceModel,
      b: SupplierInvoiceModel,
      filter: string
    ): number => {
      let firstItem = '';
      let secondItem = '';
      if (this.sortBy === 'dueDate') {
        return (
          moment(b.dueDate, window.appSettings.dateFormat).valueOf() -
          moment(a.dueDate, window.appSettings.dateFormat).valueOf()
        );
      } else if (this.sortBy === 'purchaseOrderNumber') {
        firstItem = a.lineItems.length > 0 ? a.lineItems[0].purchaseOrderNumber : '';
        secondItem = b.lineItems.length > 0 ? b.lineItems[0].purchaseOrderNumber : '';
      } else {
        firstItem = a[filter];
        secondItem = b[filter];
      }

      if (firstItem < secondItem) {
        return -1;
      } else if (firstItem > secondItem) {
        return 1;
      }
      return 0;
    };

    if (this.orderByDescending) {
      return compareItems(second, first, this.sortBy);
    } else {
      return compareItems(first, second, this.sortBy);
    }
  };

  @action public loadData = async (type: InvoiceType, applyingFilters = false): Promise<void> => {
    const self = this;
    const data = await self.searchSupplierInvoicesByState({
      state: type,
      skip: self.skip,
      top: self.top,
      count: true,
    });
    const supplierInvoices = data.supplierInvoices.map((i) => new SupplierInvoiceModel({ ...i }));

    if (data.totalResults) {
      self.totalResults = data.totalResults;
      self.pages = Math.ceil(self.totalResults / self.pageSize);
    }

    runInAction(() => {
      if (applyingFilters) {
        if (type === InvoiceType.Draft) {
          self.updateDraftSupplierInvoicesWithFilters(supplierInvoices);
        } else {
          self.updateMatchedSupplierInvoicesWithFilters(supplierInvoices);
        }
      } else {
        if (type === InvoiceType.Draft) {
          self.updateDraftSupplierInvoices(supplierInvoices);
        } else {
          self.updateMatchedSupplierInvoices(supplierInvoices);
        }
      }
    });
  };

  @action public loadMoreData = async (type: InvoiceType): Promise<void> => {
    const self = this;
    if (self.currentPage === self.pages) {
      return;
    }
    self.skip += 10;
    self.currentPage += 1;
    this.loadData(type, false);
  };

  @action public loadSearchResults = async (
    searchTerm: string,
    type: InvoiceType
  ): Promise<void> => {
    const self = this;
    const results = await self.searchSupplierInvoicesByState({
      state: type,
      query: searchTerm,
      skip: 0,
      top: 100,
      count: true,
    });

    runInAction(() => {
      const data = results.supplierInvoices.map((r) => new SupplierInvoiceModel({ ...r }));
      self.supplierInvoicesSearchResults.length = 0;
      self.supplierInvoicesSearchResults.push(...data);
      this.setSearchingProgress('completed');
    });
  };
  @action public clearSearchResults(): void {
    this.searchInputValue = '';
    this.supplierInvoicesSearchResults.length = 0;
  }

  @action public setSelectedInvoice(selected?: SupplierInvoiceModel | null): void {
    if (selected) {
      this.selectedInvoice = selected;
    } else {
      this.selectedInvoice = null;
    }
  }

  @action public async getSupplierInvoiceAttachment(documentId: string): Promise<string> {
    const self = this;
    let result = '';
    self.setLoadingInvoiceAttachment(true);
    try {
      const url = `${window.appSettings.docsApiUrl}/api/v10/documents/${documentId}/attachments?disposition=inline`;
      const token = window.getToken ? await window.getToken() : '';
      if (!token) {
        throw new Error(StringResources.UnexpectedError);
      }
      const response = await fetch(url, {
        method: 'get',
        headers: {
          Authorization: `bearer ${token}`,
          Accept: 'application/pdf, image/jpeg, image/png',
        },
      });
      if (response.status !== 200) {
        throw new Error(StringResources.UnexpectedError);
      }

      const attachmentType =
        (response.headers.get('Content-Type') as AttachmentMediaType) || undefined;
      if (!attachmentType) {
        throw new Error(StringResources.UndefinedAttachmentMediaType);
      }
      const base64Flag = `data:${attachmentType};base64,`;
      const buffer = await response.arrayBuffer();
      const fileString = self.arrayBufferToBase64(buffer);
      const uri = base64Flag + fileString;

      self.setAttachmentUri(uri, attachmentType);

      result = base64Flag + fileString;
      self.setLoadingInvoiceAttachment(false);
    } catch (e) {
      result = '';
      self.setAttachmentUri(undefined, undefined);
      self.setLoadingInvoiceAttachment(false);
      self.log(StringResources.ErrorRetrievingData, e as Error);
    }
    return result;
  }

  @action public downloadPDFAttachment = async (): Promise<void> => {
    const self = this;
    if (!self.selectedInvoice || !self.selectedInvoice.documentId) {
      return;
    }
    const documentMetadataId = self.selectedInvoice!.documentId;
    const url = `${window.appSettings.docsApiUrl}/api/v10/documents/${documentMetadataId}/attachments?disposition=attachment`;
    const token = window.getToken ? await window.getToken() : '';
    if (!token) {
      return;
    }
    try {
      const response = await fetch(url, {
        method: 'get',
        headers: {
          Authorization: `bearer ${token}`,
          Accept: 'application/pdf, image/jpeg, image/png',
        },
      });
      if (response.status !== 200) {
        throw new Error();
      }

      const attachmentType =
        (response.headers.get('Content-Type') as AttachmentMediaType) || undefined;
      const documentExtension =
        attachmentType && attachmentType.substring(attachmentType.indexOf('/') + 1);
      const responseBlob = await response.blob();
      const newBlob = new Blob([responseBlob], { type: attachmentType });
      const newBlobUrl = URL.createObjectURL(newBlob);

      self.setAttachmentUri(newBlobUrl, attachmentType);
    } catch (e) {
      self.setAttachmentUri(undefined, undefined);
      self.log(StringResources.ErrorDownloadingPDFDocument, e as Error);
    }
  };

  public get attachmentUri(): string | undefined | null {
    return this._attachmentUri;
  }

  @action public setAttachmentUri(
    attachmentUri: string | undefined,
    attachmentType: AttachmentMediaType | undefined
  ): void {
    this.attachmentMediaType = attachmentType;
    this._attachmentUri = attachmentUri;
    if (!attachmentUri) {
      this.attachmentMediaType = undefined;
    }
  }

  public isSupportedAttachmentType(type: AttachmentMediaType): boolean {
    return type === 'application/pdf' || type === 'image/jpeg' || type === 'image/png';
  }

  @action private setLoadingInvoiceAttachment(status: boolean): void {
    this.loadingInvoiceAttachment = status;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  private async searchSupplierInvoicesByState({
    state,
    query,
    skip,
    top,
    count,
  }: SearchParams): Promise<SupplierInvoiceSearchResponse> {
    this.setIsLoading(true);
    let result: SupplierInvoiceSearchResponse;

    try {
      const options: SuppliersSearchSupplierInvoicesByStateOptionalParams = {
        query: query || undefined,
        skip: skip || 0,
        top: top || 10,
        count: count || undefined,
      };
      const client = await this.getPurchasingClient();
      const response = await client.suppliers.searchSupplierInvoicesByState(state, options);
      result = JSON.parse(response._response.bodyAsText) as SupplierInvoiceSearchResponse;
    } catch (e) {
      result = {
        skip: 0,
        top: 0,
        supplierInvoices: [],
        totalResults: 0,
      } as SupplierInvoiceSearchResponse;
      this.log(`${StringResources.ErrorRetrievingData}`, e as Error);
    }

    this.setIsLoading(false);
    return result;
  }

  @action private updateDraftSupplierInvoices(invoices: SupplierInvoiceModel[]): void {
    this.draftSupplierInvoices.push(...invoices);
    this.scrollToElement = invoices[0] && invoices[0].supplierCode;
  }

  @action private updateMatchedSupplierInvoices(invoices: SupplierInvoiceModel[]): void {
    this.matchedSupplierInvoices.push(...invoices);
    this.scrollToElement = invoices[0] && invoices[0].supplierCode;
  }

  @action private updateDraftSupplierInvoicesWithFilters(invoices: SupplierInvoiceModel[]): void {
    this.draftSupplierInvoices.length = 0;
    this.draftSupplierInvoices.push(...invoices);
  }

  @action private updateMatchedSupplierInvoicesWithFilters(invoices: SupplierInvoiceModel[]): void {
    this.matchedSupplierInvoices.length = 0;
    this.matchedSupplierInvoices.push(...invoices);
  }
}

export default SupplierInvoiceStore;
