import * as React from 'react';
import { CellInfo } from 'react-table';
import ProductCodeMapModel from './ProductCodeMapModel';
import classNames from 'classnames';

interface EditableCellProps {
  cellInfo: CellInfo;
  editable: boolean;
  handleChange: Function;
  model: ProductCodeMapModel;
  validationError: boolean;
}

export class EditableCell extends React.Component<EditableCellProps, {}> {
  public shouldComponentUpdate(nextProps: EditableCellProps): boolean {
    return (
      this.props.editable !== nextProps.editable ||
      this.props.validationError !== nextProps.validationError ||
      this.props.model[this.props.cellInfo.column.id!] !==
        nextProps.model[nextProps.cellInfo.column.id!]
    );
  }

  public render(): JSX.Element {
    const { editable } = { ...this.props };
    const validationErrorLocal = editable && this.props.validationError;

    const editableCellClass = classNames({
      'product-maps-table-cell': true,
      'editable-cell': editable,
      'view-cell': !editable,
      'invalid-cell': validationErrorLocal,
    });

    const newValue = {
      __html: this.props.model[this.props.cellInfo.column.id!],
    };

    return (
      <div
        className={editableCellClass}
        contentEditable={editable}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={newValue}
        onBlur={(e): void => this.props.handleChange(e, this.props.cellInfo)}
      />
    );
  }
}

export default EditableCell;
