import { RequestTableDataRowModel } from './Models';
import React from 'react';
import { ExcelGenerator } from '@vulcan/component-lib/dist/utils';
import { Button } from '@vulcan/vulcan-materialui-theme';
import RequestTable from 'src/Reorder/Request/RequestTable';

interface RequestExportButtonProps {
  data: RequestTableDataRowModel[];
}

export class RequestExportButton extends React.Component<RequestExportButtonProps> {
  public render(): JSX.Element {
    return (
      <Button
        disabled={!this.props.data.length}
        className="action-button export-excel-button"
        type="button"
        color="warning"
        onClick={async (): Promise<void> => await this.handleClick()}
      >
        Export
      </Button>
    );
  }

  private async handleClick(): Promise<void> {
    const generator = new ExcelGenerator<RequestTableDataRowModel>(
      'ReorderRequest',
      RequestTable.exportableTableColumns,
      this.props.data
    );
    generator.generate();
  }
}
