import { action, observable, runInAction } from 'mobx';
import { AuthStore, SecurityStore, UserProfileStore } from '.';
import { SettingsService } from './Middleware/SettingsService';
import { ProductMapsStore } from 'src/ProductMaps/ProductMapsStore';
import SupplierInvoiceStore from '../Invoice/SupplierInvoiceStore';
import BaseStore from './Infrastructure/BaseStore';
import SettingStore from './Layout/SettingStore';
import ReorderStore from 'src/Reorder/ClassicReport/ReorderStore';
import { StockFilterStore } from 'src/Shared/StockFilterControl/StockFilterStore';
import RequestHistoryStore from 'src/Reorder/RequestHistory/RequestHistoryStore';
import RequestStore from 'src/Reorder/Request/RequestStore';
import { StockDetailTableStore } from 'src/Reorder/Stock/StockDetailTable/StockDetailTableStore';
import { StockTableStore } from 'src/Reorder/Stock/StockTable/StockTableStore';
import { configureApplicationInsights } from '../Shared/AppInsights';

export class AppStore extends BaseStore {
  // All global stores will be added here, they must be private as they should only be accessible
  // by injecting them into the components where they are required.
  @observable public isSettingLoaded = false;
  public authStore: AuthStore;
  public securityStore: SecurityStore;
  private userProfileStore: UserProfileStore;
  private settingStore: SettingStore;
  private productMapsStore: ProductMapsStore;
  private supplierInvoiceStore: SupplierInvoiceStore;
  private reorderStore: ReorderStore;
  private reorderFilterStore: StockFilterStore;
  private stockFilterStore: StockFilterStore;
  private stockTableStore: StockTableStore;
  private stockDetailTableStore: StockDetailTableStore;
  private requestStore: RequestStore;
  private requestHistoryStore: RequestHistoryStore;

  constructor() {
    super();
    this.authStore = new AuthStore();
    this.securityStore = new SecurityStore();
    this.userProfileStore = new UserProfileStore(this.authStore);
    this.settingStore = new SettingStore();
    this.productMapsStore = new ProductMapsStore();
    this.supplierInvoiceStore = new SupplierInvoiceStore();
    this.reorderStore = new ReorderStore();
    this.stockTableStore = new StockTableStore();
    this.stockDetailTableStore = new StockDetailTableStore();
    this.reorderFilterStore = new StockFilterStore();
    this.stockFilterStore = new StockFilterStore();
    this.requestStore = new RequestStore();
    this.requestHistoryStore = new RequestHistoryStore();
  }

  @action
  public async loadAppData(): Promise<void> {
    await SettingsService.loadSettings();

    runInAction(async () => {
      this.isSettingLoaded = true;

      SettingsService.configureCrashReporting();

      this.authStore.init();

      window.authenticate = await this.authStore.login();
      configureApplicationInsights(this.authStore.getUser);

      if (this.authStore.isAuthenticated) {
        this.authStore.setLoggedInStatus(true);
        await this.securityStore.init(this.authStore);
      }
    });
  }

  public getStores(): Record<string, any> {
    return {
      authStore: this.authStore,
      securityStore: this.securityStore,
      userProfileStore: this.userProfileStore,
      settingStore: this.settingStore,
      productMapsStore: this.productMapsStore,
      supplierInvoiceStore: this.supplierInvoiceStore,
      reorderStore: this.reorderStore,
      stockTableStore: this.stockTableStore,
      stockDetailTableStore: this.stockDetailTableStore,
      reorderFilterStore: this.reorderFilterStore,
      stockFilterStore: this.stockFilterStore,
      requestStore: this.requestStore,
      requestHistoryStore: this.requestHistoryStore,
    };
  }
}

export default AppStore;
