import * as React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Sidebar, Header, appStyle, Icon } from '@vulcan/vulcan-materialui-theme';
import { SecurityStore, AuthStore } from '..';
import { withStyles, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import { UserProfileStore } from './../UserProfileStore';
import { MainRoutes, RouteModel } from '../Infrastructure/Routes';
import SwitchRoutes from '../Infrastructure/Routes/RouteRenderer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TenantsSelect from '../MultiTenant/TenantsSelect';
import SettingFooter from './SettingFooter';
import SettingStore from './SettingStore';
import { Permissions } from '../Security/Permissions';
import { IconResources } from 'src/Shared/Constants';
import image from 'src/Shared/Assets/sidebar-background.jpg';

interface MatchParams {
  prefix: string;
}

interface Props extends WithStyles<appStyle>, RouteComponentProps<MatchParams> {
  authStore: AuthStore;
  securityStore?: SecurityStore;
  userProfileStore: UserProfileStore;
  settingStore: SettingStore;
}

@inject('authStore')
@inject('securityStore')
@inject('userProfileStore')
@inject('settingStore')
@observer
export class Layout extends React.Component<Props, {}> {
  @observable private isMobileOpen = false;

  @action private handleDrawerToggle = (): void => {
    this.isMobileOpen = !this.isMobileOpen;
  };

  @action private resizeFunction = (): void => {
    if (window.innerWidth >= 960) {
      this.handleCloseMobile();
    }
  };

  @action private handleCloseMobile = (): void => {
    this.isMobileOpen = false;
  };

  public async componentDidMount(): Promise<void> {
    await this.props.securityStore!.loadUserPermissions();
    window.addEventListener('resize', this.resizeFunction);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.resizeFunction);
  }

  public componentDidUpdate(e: any): void {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.isMobileOpen) {
        this.handleCloseMobile();
      }
    }
  }

  public render(): JSX.Element | null {
    if (!this.props.securityStore!.permissionsLoaded) {
      return null;
    }

    const { classes } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      classNames({
        [classes.mainPanelSidebarMini]: this.props.settingStore.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });

    const siteWrapper = classNames(`${classes.wrapper} site-container`);

    return (
      <div className={siteWrapper}>
        <Sidebar
          routes={this.getPermissionRoutesWithTenantPrefix().filter((x) => x.renderInSidebar)}
          logoText="Purchasing"
          logo={<Icon size={50} icon={IconResources.WebsiteLogo} />}
          image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.isMobileOpen}
          color="orange"
          bgColor="black"
          miniActive={this.props.settingStore.miniActive}
          location={this.props.location}
          username={this.props.userProfileStore!.userName}
          settingsRoutes={this.getPermissionRoutesWithTenantPrefix().filter((x) => x.settingsRoute)}
          showSettings={true}
          settingFooter={<SettingFooter key="setting-footer" />}
        />
        <div id="site-container-id" className={mainPanel}>
          <Header
            handleDrawerToggle={this.handleDrawerToggle}
            authStore={this.props.authStore}
            headerMainText={this.props.userProfileStore!.userName}
            headerSubText={this.props.securityStore!.selectedTenant!.friendlyName}
          >
            <TenantsSelect {...this.props} className="tenants-select-menu-item" />
          </Header>

          <div className="site-content-and-footer-container">
            <div className={classes.content}>
              <div className={classes.container}>
                <SwitchRoutes routes={MainRoutes} location={this.props.location || undefined} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getPermissionRoutesWithTenantPrefix(): RouteModel[] {
    const tenantPrefix = this.props.securityStore!.getTenantPrefixFromCookieTenantName!;
    const result: RouteModel[] = this.getRoutesByPermissions();

    result.forEach((route) => {
      this.replaceTenantPrefix(route, tenantPrefix);
      if (route.views && route.views.length > 0) {
        route.views!.forEach((r) => this.replaceTenantPrefix(r, tenantPrefix));
      }
    });
    return result;
  }

  private replaceTenantPrefix(route: RouteModel, tenantPrefix: string): void {
    route.path = route.path.replace(':prefix', tenantPrefix);
    if (route.pathTo) {
      route.pathTo = route.pathTo.replace(':prefix', tenantPrefix);
    }
  }

  private getRoutesByPermissions(): RouteModel[] {
    const result: RouteModel[] = [];
    MainRoutes.forEach((mainRoute: RouteModel) => {
      const clonedMainRoute = Object.assign({}, mainRoute); // Deep copy is necessary here.

      if (this.hasPermission(clonedMainRoute)) {
        if (clonedMainRoute.views && clonedMainRoute.views.length > 0) {
          clonedMainRoute.views = clonedMainRoute.views!.filter((r: RouteModel) =>
            this.hasPermission(r)
          );
          if (clonedMainRoute.views.length > 0) {
            result.push(clonedMainRoute);
          }
        } else {
          result.push(clonedMainRoute);
        }
      }
    });
    return result;
  }

  private hasPermission(route: RouteModel): boolean {
    return (
      this.props.securityStore!.hasPermission(route.permission) ||
      route.permission === Permissions.None
    );
  }
}

const StyledLayout = withStyles(appStyle)(Layout);
const WrappedLayout = withRouter<any, any>(StyledLayout);
export default WrappedLayout;
