import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import {
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  FormLabel,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { StockDetailTable } from './StockDetailTable';
import { IconResources } from 'src/Shared/Constants';
import {
  Button as VulcanButton,
  Icon,
  GridContainer,
  GridItem,
} from '@vulcan/vulcan-materialui-theme';
import { StockTableModel } from '../StockTable/StockTableModel';
import { tokilograms, toMeters } from 'src/Shared/Utils';
import { appInsights } from 'src/Shared/AppInsights';

interface Props {
  selectedRow: StockTableModel;
  isOpen: boolean;
  closeDialog: Function;
  saveAndNext: Function;
  toPreviousRow: Function;
  toNextRow: Function;
  isFirstRow: boolean;
}

const transition = React.forwardRef(
  (props: TransitionProps, _ref: React.Ref<HTMLDivElement>): JSX.Element => (
    <Slide direction="down" {...props} />
  )
);

const makeClasses = makeStyles(
  createStyles({
    productLabel: {
      color: 'black',
      fontWeight: 'bolder',
      fontSize: '1.8vh',
      name: 'blank',
    },
    gridItem: {
      marginBottom: '10px',
    },
    gridWrapper: {
      width: '85%',
    },
    dialogueContent: {
      paddingTop: 0,
    },
  })
);

export default function StockDetailDialog({
  selectedRow,
  isOpen,
  closeDialog,
  saveAndNext,
  toPreviousRow,
  toNextRow,
  isFirstRow,
}: Props): JSX.Element {
  const [row, setRow] = useState<StockTableModel>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // This component is open, start tracking the page duration.
    if (isOpen) {
      appInsights().startTrackPage('Stock Detail Table');
    }
  }, [isOpen]);

  useEffect(() => {
    setRow(selectedRow);
    setOpen(isOpen);
  }, [selectedRow, isOpen, closeDialog, saveAndNext]);

  const handleClose = (): void => {
    appInsights().stopTrackPage('Stock Detail Table');
    setOpen(false);
    closeDialog();
  };

  const handleSave = (): void => {
    // The next table is drawn, stop the previous track and start a new one.
    appInsights().stopTrackPage('Stock Detail Table');
    appInsights().startTrackPage('Stock Detail Table');
    saveAndNext();
  };

  const classes = makeClasses();

  return (
    <Dialog
      scroll="paper"
      fullScreen={true}
      maxWidth={false}
      open={open}
      TransitionComponent={transition}
      keepMounted={true}
      onClose={handleClose}
      className="detailsModal"
      PaperProps={{ style: { borderRadius: 6 } }}
    >
      <div className={'product-dialog-wrapper'}>
        <MuiDialogTitle className={classes.gridWrapper}>
          <GridContainer>
            <GridItem className={classes.gridItem} lg={4} md={6} xs={12}>
              <span className={'product-form-label-title'}>Product Code: </span>
              <FormLabel className={classes.productLabel}>{row?.productCode}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={6} md={6} xs={12}>
              <span className={'product-form-label-title'}>Description: </span>
              <FormLabel className={classes.productLabel}>{row?.productDescription}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={2} md={6} xs={12}>
              <span className={'product-form-label-title'}>Die Code: </span>
              <FormLabel className={classes.productLabel}>{row?.dieCode ? row?.dieCode : '-'}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={4} md={6} xs={12}>
              <span className={'product-form-label-title'}>Group: </span>
              <FormLabel className={classes.productLabel}>{row?.productGroup}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={6} md={6} xs={12}>
              <span className={'product-form-label-title'}>Category: </span>
              <FormLabel className={classes.productLabel}>{row?.productCategory}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={2} md={6} xs={12}>
              <span className={'product-form-label-title'}>Length: </span>
              <FormLabel className={classes.productLabel}>
                {toMeters(row?.productLength || 0)}
              </FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={4} md={6} xs={12}>
              <span className={'product-form-label-title'}>Size: </span>
              <FormLabel className={classes.productLabel}>{row?.productSize}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={6} md={6} xs={12}>
              <span className={'product-form-label-title'}>Units Per Tonne: </span>
              <FormLabel className={classes.productLabel}>{row?.unitsPerTonne}</FormLabel>
            </GridItem>
            <GridItem className={classes.gridItem} lg={2} md={6} xs={12}>
              <span className={'product-form-label-title'}>Each Weight: </span>
              <FormLabel className={classes.productLabel}>
                {tokilograms(row?.eachWeight || 0)}
              </FormLabel>
            </GridItem>
          </GridContainer>
        </MuiDialogTitle>
        <div>
          <span className={'reviewed-label-title'}>
            Reviewed:{' '}
            {row?.reviewedProduct === false ? (
              <></>
            ) : (
              <Icon className={'reviewed-icon'} icon={IconResources.GREENTICK} />
            )}
          </span>
        </div>
      </div>
      <DialogContent className={classes.dialogueContent}>
        <fieldset className="fieldset">
          <legend className="label">Stock Details</legend>
          <StockDetailTable productCode={row?.productCode ?? ''} />
        </fieldset>
      </DialogContent>
      <DialogActions className="footer-button-row">
        <VulcanButton
          className="expend-row-button"
          type="button"
          color="warning"
          onClick={toPreviousRow}
          disabled={isFirstRow}
        >
          Back
        </VulcanButton>
        <VulcanButton
          className="expend-row-button"
          type="button"
          color="warning"
          onClick={toNextRow}
        >
          Next
        </VulcanButton>
        <VulcanButton className="expend-row-green-button" type="button" onClick={handleSave}>
          Save & Next
        </VulcanButton>
        <VulcanButton className="expend-row-button" type="button" color="" onClick={handleClose}>
          Close
        </VulcanButton>
      </DialogActions>
    </Dialog>
  );
}
