// Polyfills
import 'whatwg-fetch';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';

import '@vulcan/vulcan-materialui-theme/build/material-dashboard-pro-react.css?v=1.3.0';
import './index.scss';

// Activate mobx strict mode
// This will ensure that changes to the state must be done within an action method
configure({ enforceActions: 'observed' });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
