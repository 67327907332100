import * as React from 'react';
import SupplierInvoiceModel from 'src/Invoice/SupplierInvoiceModel';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import PDFView from './AttachmentView/PDFView';
import DetailsViewType from './ViewType';
import DetailsSectionNav from './DetailsSectionNav';
import EmptyInvoiceLinesList from './DetailsView/InvoiceLines/EmptyInvoiceLinesList';
import DetailsViewLayout from './DetailsView/DetailsViewLayout';

interface Props {
  selectedInvoice: SupplierInvoiceModel | null;
}

@observer
class DetailsSectionLayout extends React.Component<Props, {}> {
  @observable view: DetailsViewType;

  constructor(props: Props) {
    super(props);
    this.view = DetailsViewType.Details;
  }

  public getSnapshotBeforeUpdate(prevProps: Props, prevState: {}): boolean | null {
    if (
      !prevProps.selectedInvoice ||
      !this.props.selectedInvoice ||
      prevProps.selectedInvoice.invoiceNumber !== this.props.selectedInvoice.invoiceNumber
    ) {
      return true;
    }
    return null;
  }

  @action public componentDidUpdate(
    prevProps: Props,
    prevState: {},
    snapshot: boolean | null
  ): void {
    if (snapshot !== null) {
      this.view = DetailsViewType.Details;
    }
  }

  public render(): JSX.Element {
    return (
      <div className="supplier-invoice-details-container">
        {this.props.selectedInvoice && this.renderDetails()}
        <EmptyInvoiceLinesList show={!this.props.selectedInvoice} />
      </div>
    );
  }

  private renderDetails(): JSX.Element {
    const hasAttachment =
      this.props.selectedInvoice && this.props.selectedInvoice.documentId ? true : false;
    return (
      <>
        {this.props.selectedInvoice && (
          <>
            {this.view === DetailsViewType.Details && (
              <DetailsViewLayout selectedInvoice={this.props.selectedInvoice} />
            )}
            {this.view === DetailsViewType.PDF && hasAttachment && (
              <PDFView selectedInvoice={this.props.selectedInvoice} />
            )}
          </>
        )}
        <DetailsSectionNav
          selectedView={this.view}
          handleChangeView={this.handleChangeView}
          noAttachmentAvailable={!hasAttachment}
        />
      </>
    );
  }

  @action private handleChangeView = (view: DetailsViewType): void => {
    this.view = view;
  };
}

export default DetailsSectionLayout;
