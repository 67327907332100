import * as React from 'react';
import { CustomDropdownMultiHeaders, Icon } from '@vulcan/vulcan-materialui-theme';
import { inject } from 'mobx-react';
import SupplierInvoiceStore from '../SupplierInvoiceStore';
import { action } from 'mobx';
import InvoiceType from '../InvoiceType';
import { IconResources } from 'src/Shared/Constants/IconResources';

type FilterItemType = 'sortby' | 'orderby' | 'header' | 'divider';

class FilterItem {
  public type: FilterItemType;
  public id: string;
  public text?: string;
  public selected?: boolean;

  constructor(fields: Partial<FilterItem>) {
    this.type = 'header';
    this.id = '';
    this.text = '';
    this.selected = false;
    Object.assign(this, fields);
  }
}

interface FilterProps {
  supplierInvoiceStore?: SupplierInvoiceStore;
  type: InvoiceType;
}

@inject('supplierInvoiceStore')
class Filter extends React.Component<FilterProps, {}> {
  private dropdownItems: FilterItem[] = [];

  constructor(props: FilterProps) {
    super(props);
    this.dropdownItems = [
      new FilterItem({ type: 'header', id: 'header', text: 'SORT BY' }),
      new FilterItem({
        type: 'sortby',
        id: 'sortby-dueDate',
        text: 'Due Date',
        selected: this.props.supplierInvoiceStore!.sortBy === 'dueDate',
      }),
      new FilterItem({
        type: 'sortby',
        id: 'sortby-supplierCode',
        text: 'Supplier',
        selected: this.props.supplierInvoiceStore!.sortBy === 'supplierCode',
      }),
      new FilterItem({
        type: 'sortby',
        id: 'sortby-purchaseOrderNumber',
        text: 'PO Number',
        selected: this.props.supplierInvoiceStore!.sortBy === 'purchaseOrderNumber',
      }),
      new FilterItem({ type: 'divider', id: 'divider' }),
      new FilterItem({ type: 'header', id: 'header', text: 'ORDER' }),
      new FilterItem({
        type: 'orderby',
        id: 'orderby-ascending',
        text: 'Ascending',
        selected: !this.props.supplierInvoiceStore!.orderByDescending,
      }),
      new FilterItem({
        type: 'orderby',
        id: 'orderby-descending',
        text: 'Descending',
        selected: this.props.supplierInvoiceStore!.orderByDescending,
      }),
    ];
  }

  public render(): JSX.Element {
    const buttonProps = {
      simple: true,
      round: true,
      justIcon: true,
      fullWidth: false,
      style: { marginBottom: '0' },
    };
    return (
      <CustomDropdownMultiHeaders
        buttonIcon={(): JSX.Element => <Icon icon={IconResources.FILTER} color="black" />}
        hoverColor="primary"
        buttonProps={buttonProps}
        dropdownList={this.dropdownItems}
        caret={false}
        handleItemClick={this.handleMenuItemClick}
        menuListClass="filter-list"
        listItemClass="filter-list-item"
      />
    );
  }

  @action private handleMenuItemClick = (e: React.MouseEvent<HTMLElement>): void => {
    const self = this;
    const selectedFilterItemId = e.currentTarget.dataset.id!.toLowerCase();
    const selectedFilterItemType =
      selectedFilterItemId.indexOf('orderby') > -1 ? 'orderby' : 'sortby';

    self.dropdownItems.forEach((i: FilterItem, index: number, arr: FilterItem[]) => {
      if (i.type === selectedFilterItemType) {
        if (i.id.toLowerCase() === selectedFilterItemId.toLowerCase()) {
          arr[index].selected = !arr[index].selected;

          if (selectedFilterItemType === 'orderby') {
            self.props.supplierInvoiceStore!.orderByDescending = i.id === 'orderby-descending';
          } else if (selectedFilterItemType === 'sortby') {
            if (i.id.indexOf('dueDate') > -1) {
              self.props.supplierInvoiceStore!.sortBy = 'dueDate';
            } else if (i.id.indexOf('supplierCode') > -1) {
              self.props.supplierInvoiceStore!.sortBy = 'supplierCode';
            } else if (i.id.indexOf('purchaseOrderNumber') > -1) {
              self.props.supplierInvoiceStore!.sortBy = 'purchaseOrderNumber';
            }
          }
        } else {
          arr[index].selected = false;
        }
      }
    });
  };
}

export default Filter;
