import React from 'react';
import { Button } from '@vulcan/vulcan-materialui-theme';
import { inject, observer } from 'mobx-react';
import ReorderStore from './ReorderStore';
import ReorderTable from './ReorderTable';
import { ExcelGenerator } from '@vulcan/component-lib/dist/utils';
import { ReorderTableDataRowGroupModel } from 'src/Reorder/ClassicReport/Models';

interface ReorderTableProps {
  reorderStore?: ReorderStore;
}

@inject('reorderStore')
@observer
export class ReorderExport extends React.Component<ReorderTableProps> {
  private handleExport(): void {
    const tableData = this.props.reorderStore!.sortedTableData.slice();
    const generator = new ExcelGenerator<ReorderTableDataRowGroupModel>(
      'Reorder',
      ReorderTable.exportableTableColumns,
      tableData
    );
    generator.generate();
  }

  public render(): JSX.Element {
    return (
      <Button
        disabled={!this.props.reorderStore!.tableData.length}
        className="action-button export-excel-button"
        type="button"
        color="warning"
        onClick={(): void => this.handleExport()}
      >
        Export
      </Button>
    );
  }
}

export default ReorderExport;
