import * as React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Snackbar,
  Icon,
  Card,
  CardHeader,
  CardBody,
  CardIcon,
} from '@vulcan/vulcan-materialui-theme';
import { RouteComponentProps } from 'react-router-dom';
import { IconResources } from 'src/Shared/Constants';
import { RequestHistoryTable } from './RequestHistoryTable';
import { RequestHistoryStore } from './RequestHistoryStore';

interface Props extends RouteComponentProps {
  requestHistoryStore?: RequestHistoryStore;
}

@inject('requestHistoryStore')
@observer
class RequestHistoryLayout extends React.Component<Props> {
  public async componentDidMount(): Promise<void> {
    await this.props.requestHistoryStore!.init();
  }

  public render(): React.ReactNode {
    return (
      <div className="container">
        <Card className="card">
          <CardHeader color="primary" icon={true} className="card-header">
            <CardIcon className="card-icon" color="warning">
              <Icon icon={IconResources.REORDER} />
            </CardIcon>
            <div className="card-header-section">
              <h4 className="card-title">Request History</h4>
            </div>
          </CardHeader>
          <CardBody>
            <RequestHistoryTable />
          </CardBody>
        </Card>
        <Snackbar
          place="br"
          color={this.props.requestHistoryStore!.alertType}
          icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
          message={this.props.requestHistoryStore!.alertMessage}
          open={this.props.requestHistoryStore!.alert}
          closeNotification={(): void => this.props.requestHistoryStore!.hideAlert()}
          close={true}
        />
      </div>
    );
  }
}
export default RequestHistoryLayout;
