import { observable, action, runInAction } from 'mobx';
import {
  PermissionResponse,
  UserSettingsResponse,
  NameValueResponse,
} from '@vulcan/security-api-client/lib/models';
import { Permissions } from './Permissions';
import { BaseStore } from '../Infrastructure';
import * as Cookies from 'js-cookie';
import { AuthStore } from '..';
import SettingsService from '../Middleware/SettingsService';
import { StringResources } from 'src/Shared/Constants';
import UserSettingModel from './UserSettingModel';

export class SecurityStore extends BaseStore {
  @observable public permissionsLoaded = false;
  @observable public permissions: PermissionResponse[] = [];

  @observable public loadingTenants = true;
  @observable public userTenants: UserSettingModel[] = [];
  @observable public selectUserTenantName = Cookies.get('tenant') || '';

  private authStore!: AuthStore;

  @action public init = async (authStore: AuthStore): Promise<void> => {
    this.authStore = authStore;
    this.loadingTenants = true;

    this.setIsLoading(true);
    const allTenants = await this.getUserTenants();
    this.setIsLoading(false);

    const filteredTenants = this.filterTenants(allTenants);
    runInAction(() => {
      this.userTenants.length = 0;
      this.userTenants.push(...filteredTenants);
      this.loadingTenants = false;
      if (this.userTenants.length === 1) {
        // When there is only one tenant - select it automatically
        // - no need to ask the user to select a tenant.
        this.setSelectedTenant(this.userTenants[0].tenantName);
      } else if (this.userTenants.length > 1 && this.isTenantSelected) {
        // If there is more than 1 tenant available, only handle the case when the tenant is selected.
        // if the tenant is not selected, TenantsList modal will be shown to ask
        // the user to select a tenant.
        this.populateTenantsUrls(this.userTenants);
      }
    });
  };

  public get isTenantSelected(): boolean {
    return this.selectUserTenantName !== undefined;
  }

  private getUserTenants = async (): Promise<UserSettingModel[]> => {
    let result: UserSettingModel[] = [];
    try {
      const security = await this.getSecurityClient();
      const user = this.authStore.getUser;
      if (!user) {
        throw new Error(StringResources.NoAuthToken);
      }
      const userObjectId = user.profile.oid;
      const response = await security.users.getUserSettings(userObjectId);
      result = JSON.parse(response._response.bodyAsText) as UserSettingModel[];
    } catch (e) {
      result = [];
      this.log(StringResources.ErrorAcquiringUserTenants, e as Error);
    }
    return result;
  };

  private filterTenants(allTenants: UserSettingModel[]): UserSettingModel[] {
    const filteredTenants: UserSettingModel[] = [];
    const necessaryUrls: string[] = [
      window.appSettings.websitepathprefix,
      window.appSettings.securityApiUrl,
      window.appSettings.purchasingApiUrl,
      window.appSettings.docsApiUrl,
      window.appSettings.salesApiUrl,
      window.appSettings.inventoryApiUrl,
    ];

    allTenants.forEach((t: UserSettingModel) => {
      const settings = new Map(t.settings.map((s) => [s.name.toLowerCase(), s.value]));
      // Detected that if this tenant including all url setting for this website.
      const hasSettings = necessaryUrls.every((url) => settings.has(url.toLowerCase()));
      if (hasSettings) {
        t.pathPrefix = settings.get(window.appSettings.websitepathprefix) || '';
        filteredTenants.push(t);
      }
    });

    return filteredTenants;
  }

  public get selectedTenant(): UserSettingModel | undefined {
    return this.userTenants.find((x) => x.tenantName === this.selectUserTenantName);
  }

  public get getTenantPrefixFromCookieTenantName(): string | undefined {
    return this.selectedTenant?.pathPrefix;
  }

  public getTenantNameByTenantPrefix(paramTenantPrefix: string): string | undefined {
    const matchedTenant = this.userTenants.find((t) => t.pathPrefix === paramTenantPrefix);
    return matchedTenant?.tenantName;
  }

  @action public setSelectedTenant = (newTenant: string): void => {
    Cookies.set('tenant', newTenant);
    this.selectUserTenantName = newTenant;
    this.populateTenantsUrls(this.userTenants);
  };

  public populateTenantsUrls(userTenants: UserSettingsResponse[]): void {
    const tenant = userTenants.find((x) => x.tenantName === this.selectUserTenantName);
    if (tenant) {
      SettingsService.tenantsUrls.clear();
      tenant.settings.forEach((s: NameValueResponse) => {
        SettingsService.tenantsUrls.set(s.name, s.value!);
      });
    }
  }

  @action public async loadUserPermissions(): Promise<void> {
    this.permissionsLoaded = false;
    const userPermissions = await this.getUserPermissions();

    runInAction(() => {
      this.permissionsLoaded = true;
      this.permissions = userPermissions;
    });
  }

  private getUserPermissions = async (): Promise<PermissionResponse[]> => {
    try {
      const security = await this.getSecurityClient();
      const response = await security.authenticatedUser.getPermissions();
      return JSON.parse(response._response.bodyAsText) as PermissionResponse[];
    } catch (e) {
      this.log(StringResources.ErrorAcquiringUserTenants, e as Error);
      return [];
    }
  };

  public hasPermission(permission: Permissions): boolean {
    return this.permissions.some((p) => p.name.toLowerCase() === permission.toLowerCase());
  }
}

export default SecurityStore;
