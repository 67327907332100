export enum StringResources {
  ErrorAcquiringToken = 'Error Acquiring Token',
  ErrorGettingProfilePhoto = 'Error getting profile photo ',
  NoGetTokenMethod = 'getToken method is not attached to the window object',
  UnexpectedError = 'An unexpected error occurred',
  ErrorRetrievingData = 'Error retrieving data',
  NoAuthToken = 'No Auth token was found',
  ErrorAddingProductMap = 'Error adding product code map',
  ErrorDeletingProductCodeMap = 'Error deleting product code map',
  ErrorEditingProductMap = 'Error editing product code map',
  TenantUrlNotFound = 'Cannot find tenant url',
  ErrorAcquiringUserTenants = 'Error acquiring user tenants',
  ErrorDownloadingPDFDocument = 'Unable to download PDF document',
  AddedProductCodeMap = 'Successfully added a new product code map',
  EditedProductCodeMap = 'Successfully edited product code map',
  RemovedProductCodeMap = 'Successfully removed product code map',
  InvalidProductMapModel = 'Invalid product map model',
  UndefinedAttachmentMediaType = 'Undefined attachment media type',
  ErrorRetrievingInvoiceAttachment = 'Error retrieving invoice attachment',
  TableNoRowsFound = 'No items found',
  ErrorLoadingReorderTableException = 'Failed to load reorder data. Please contact support.',
  ErrorLoadingBranchException = 'Failed to load branches. Please contact support.',
  ErrorLoadingStockClassificationException = 'Failed to load stock classification. Please contact support.',
  ErrorLoadingStockFiltersException = 'Failed to load stock filters. Please contact support.',
  ErrorPopulateStockSalesTable = 'Failed to populate stock sales table. Please retry.',
  ErrorPopulateStockSalesChart = 'Failed to populate stock sales chart. Please retry.',
  ErrorPopulateStockLevelTable = 'Failed to populate stock level table. Please retry.',
  ErrorSavingAmendedQuantityException = 'Failed to save amended quantity. Please retry.',
  ErrorSavingReorderQuantityException = 'Failed to save reorder quantity. Please retry.',
  ErrorPopulatingTooltip = 'Failed to populate tooltip. Please retry.',
  SavedAmendedQuantity = 'Successfully saved amended quantity',
  SavedReorderQuantity = 'Successfully saved reorder quantity',
  ConfirmRequest = 'Successfully confirmed reorder request',
  ErrorConfirmingRequestException = 'Failed to confirm reorder request. Please contact support.',
  ResetRequest = 'Successfully reset reorder request',
  ErrorResetingRequestException = 'Failed to reset reorder request. Please contact support.',
}
