import React, { useCallback } from 'react';
import ReactTable, { ReactTableDefaults, SortingRule } from 'react-table';
import { ProcurementLineRow } from './Model/ProcurementLineRow';
import { TableColumns } from './TableColumns';

export interface TableProps {
  loading: boolean;
  data: ProcurementLineRow[];
  pageSize: number;
  currentPage: number;
  totalCount: number;
  onPageChange: Function;
  onSortedChange: Function;
}

export const Table = (props: TableProps): JSX.Element => {
  const { loading, data, pageSize, currentPage, totalCount, onPageChange, onSortedChange } = props;

  const totalPage = Math.ceil(totalCount / pageSize);

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      onPageChange(pageNumber);
    },
    [onPageChange]
  );

  const handleSortedChange = useCallback(
    (sortingRules: SortingRule[]) => {
      if (sortingRules.length > 0) {
        const sortingRule = sortingRules[0];
        const orderBy = `${sortingRule.id} ${sortingRule.desc ? 'desc' : 'asc'}`;
        onSortedChange(orderBy);
      }
    },
    [onSortedChange]
  );

  return (
    <ReactTable<ProcurementLineRow>
      manual={true}
      loading={loading}
      data={data}
      columns={TableColumns}
      column={{ ...ReactTableDefaults.column, style: { whiteSpace: 'unset' } }}
      minRows={pageSize}
      pageSize={pageSize}
      page={currentPage}
      pages={totalPage}
      onPageChange={handlePageChange}
      className="react-table -striped -highlight"
      filterable={false}
      sortable={false}
      onSortedChange={handleSortedChange}
      defaultSorted={[{ id: 'line/createdDateTimeUtc', desc: false }]}
      showPageJump={false}
      showPageSizeOptions={false}
      showPaginationTop={false}
      showPaginationBottom={true}
      showPagination={true}
      collapseOnDataChange={false}
      collapseOnSortingChange={false}
      collapseOnPageChange={false}
      resizable={false}
    />
  );
};
