import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { SupplierInvoiceStore } from './SupplierInvoiceStore';
import { RouteComponentProps } from 'react-router-dom';
import SecurityStore from '../App/Security/SecurityStore';
import Permissions from '../App/Security/Permissions';
import { NotAuthorised } from '@vulcan/vulcan-materialui-theme';
import InvoiceType from './InvoiceType';
import ListSectionLayout from './ListSection/ListSectionLayout';
import DetailsSectionLayout from './DetailsSection/DetailsSectionLayout';

interface Props extends RouteComponentProps {
  supplierInvoiceStore: SupplierInvoiceStore;
  securityStore: SecurityStore;
  type: InvoiceType;
}

@inject('supplierInvoiceStore')
@inject('securityStore')
@observer
export class SupplierInvoiceLayout extends React.Component<Props, {}> {
  private securityPolicy: Record<string, boolean>;

  public async componentDidMount(): Promise<void> {
    this.props.supplierInvoiceStore.init();
  }

  constructor(props: Props) {
    super(props);
    this.securityPolicy = {
      canViewSupplierInvoice: this.props.securityStore!.hasPermission(
        Permissions.SupplierInvoiceView
      ),
      canManageSupplierInvoice: this.props.securityStore!.hasPermission(
        Permissions.SupplierInvoiceManage
      ),
    };
  }

  public render(): React.ReactNode {
    if (!this.securityPolicy.canViewSupplierInvoice) {
      return <NotAuthorised />;
    }

    return (
      <div className="supplier-invoices-layout">
        <ListSectionLayout type={this.props.type} />
        <DetailsSectionLayout selectedInvoice={this.props.supplierInvoiceStore.selectedInvoice} />
      </div>
    );
  }
}
export default SupplierInvoiceLayout;
