import { useCallback, useState } from 'react';
import { toFixedNumber } from 'src/Shared/Utils';
import { ProcurementLineRow } from '../Model/ProcurementLineRow';
import { useInventoryApi } from './useInventoryApi';
import {
  ProcurementOrderResponse,
  ProcurementOrdersResponse,
  SearchRequest,
  usePurchaseingApi,
} from './usePurchasingApi';

export const useProcurementLine = () => {
  const [tableData, setTableData] = useState<ProcurementLineRow[]>([]);
  const [facets, setFacets] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { procurementViewSearchAsync } = usePurchaseingApi();
  const { getStockClassificationsAsync } = useInventoryApi();

  const searchAsync = useCallback(
    async (searchRequest: Partial<SearchRequest>): Promise<ProcurementLineRow[]> => {
      setLoading(true);
      let lines: ProcurementLineRow[] = [];
      try {
        const statusColorsMap = new Map([
          ['unconfirmed', '#FCCA46'],
          ['confirmed', '#A1C181'],
          ['completed', '#619B8A'],
          ['receipted', '#5DAEFD'],
          ['partiallyconfirmed', '#3954A1'],
          ['partiallyreceipted', '#30D210'],
        ]);
        const stockClassList = await getStockClassificationsAsync();
        const data = await procurementViewSearchAsync(searchRequest);

        if (data) {
          const procurementOrdersMap = new Map(Object.entries(data.relatedData.procurementOrders));
          const warehousesMap = new Map(Object.entries(data.relatedData.warehouses));
          const stockClassColorMap = new Map(
            stockClassList.map((stockClass) => [stockClass.code, stockClass])
          );

          if (data.results) {
            const tableLines = data.results.reduce(
              (acc: ProcurementLineRow[], r: ProcurementOrderResponse) => {
                const order = procurementOrdersMap.get(
                  r.procurementOrderDocumentNumber
                ) as ProcurementOrdersResponse;
                const warehouseCode = (warehousesMap.get(order?.warehouseId)?.code as string) ?? '';
                const vendorCode = order?.vendorCode ?? '';
                const stockClassColor = stockClassColorMap.get(r.line.product.class);

                acc.push({
                  Status: r.line.lineState,
                  StatusColor: statusColorsMap.get(r.line.lineState.toLowerCase()),
                  PurchaseOrderNumber: r.procurementOrderDocumentNumber,
                  SupplierCode: vendorCode,
                  User: r.line.createdBy,
                  Warehouse: warehouseCode,
                  StockClassColor: stockClassColor?.colour ?? '#343434',
                  Class: r.line.product.class,
                  ProductCode: r.line.product.code,
                  Description: r.line.product.description,
                  Quantity: toFixedNumber(r.line.purchasedStockQuantity, 2),
                  Ton: toFixedNumber(r.line.purchasedWeightTotalKG / 1000, 3),
                  CreationDate: r.line.createdDateTimeUtc,
                  ETDDate: r.line.etdDate,
                  ETADate: r.line.etaDate,
                } as ProcurementLineRow);
                return acc;
              },
              []
            );

            setTableData(tableLines);
            lines = tableLines;
          }

          setFacets(data.facets);
          if (searchRequest.skip === 0) {
            setTotalCount(data.totalCount);
          }
        }
      } finally {
        setLoading(false);
        return lines;
      }
    },
    [procurementViewSearchAsync, getStockClassificationsAsync]
  );

  return { searchAsync, tableData, facets, totalCount, loading };
};
