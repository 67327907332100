export class ReorderTableDataRowModel {
  public branchId = 0;
  public branchCode = '';
  public warehouseId = 0;
  public warehouseCode = '';
  public productId = 0;
  public productCode = '';
  public productCategoryId = 0;
  public productCategory = '';
  public productGroupId = 0;
  public productGroup = '';
  public stockClass: object = { code: '', color: '' };

  public availableStock = 0;
  public committed = 0;
  public pending = 0;
  public onOrder = 0;

  public salesBranchId = 0;
  public salesBranchCode = '';
  public averageMonthSales = 0;
  public thisMonth = 0;
  public oneMonthAgo = 0;
  public twoMonthsAgo = 0;
  public threeMonthsAgo = 0;
  public fourMonthsAgo = 0;
  public fiveMonthsAgo = 0;
  public leadDays = 0;

  public eta: string | undefined;
  public etaStatus = '';
  public eachWeight = 0;
  public eachActualCost = 0;
  public actualCost = 0;
  public lastPurchaseOrderDate: string | undefined;
  public lastPurchaseOrderFirstReceiptDate: string | undefined;

  constructor(fields?: Partial<ReorderTableDataRowModel>) {
    Object.assign(this, fields);
  }
}

export default ReorderTableDataRowModel;
