import React, { useState } from 'react';
import Select from 'react-select';
import { Button } from '@vulcan/vulcan-materialui-theme';
import { FormLabel, useMediaQuery } from '@material-ui/core';

interface RequestFiltersProps {
    branches: string[];
    requestedBy: string[];
    productGroups: string[];
    applyFilter: (filterOptions: { branchCodes: string[]; requestedBy: string[]; productGroups: string[] }) => void;
}

const customStyles = {
    control: (base) => ({
        ...base,
        width: '100%',
        minWidth: 0,
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999
    })
};

const RequestFilter: React.FC<RequestFiltersProps> = ({ branches, requestedBy, productGroups, applyFilter }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');

    const [selectedBranch, setSelectedBranch] = useState<string | null>(null);
    const [selectedRequestedBy, setSelectedRequestedBy] = useState<string | null>(null);
    const [selectedProductGroup, setSelectedProductGroup] = useState<string | null>(null);

    const handleChangeBranch = (selectedOption: any) => {
        setSelectedBranch(selectedOption ? selectedOption.value : null);

        applyFilter({
            branchCodes: selectedOption ? [selectedOption.value] : [],
            requestedBy: selectedRequestedBy ? [selectedRequestedBy] : [],
            productGroups: selectedProductGroup ? [selectedProductGroup] : [],
        });
    };

    const handleChangeRequestedBy = (selectedOption: any) => {
        setSelectedRequestedBy(selectedOption ? selectedOption.value : null);

        applyFilter({
            branchCodes: selectedBranch ? [selectedBranch] : [],
            requestedBy: selectedOption ? [selectedOption.value] : [],
            productGroups: selectedProductGroup ? [selectedProductGroup] : [],
        });
    };

    const handleChangeProductGroup = (selectedOption: any) => {
        setSelectedProductGroup(selectedOption ? selectedOption.value : null);

        applyFilter({
            branchCodes: selectedBranch ? [selectedBranch] : [],
            requestedBy: selectedRequestedBy ? [selectedRequestedBy] : [],
            productGroups: selectedOption ? [selectedOption.value] : [],
        });
    };

    const handleResetFilter = () => {
        setSelectedBranch(null);
        setSelectedRequestedBy(null);
        setSelectedProductGroup(null);
        applyFilter({
            branchCodes: [],
            requestedBy: [],
            productGroups: []
        });
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: isSmallScreen || isMediumScreen ? 'column' : 'row',
            justifyContent: 'space-between',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: isSmallScreen || isMediumScreen ? '20px' : '0px'
            }}>
                <div style={{
                    width: isSmallScreen ? '100%' : '33%',
                    paddingRight: isSmallScreen ? '0px' : '10px',
                    maxWidth: '250px'
                }}>
                    <FormLabel>Sales Branch</FormLabel>
                    <Select
                        styles={customStyles}
                        placeholder="Select Branch"
                        options={branches.map(branch => ({ label: branch, value: branch }))}
                        onChange={handleChangeBranch}
                        value={selectedBranch ? { label: selectedBranch, value: selectedBranch } : ''}
                    />
                </div>
                <div style={{
                    width: isSmallScreen ? '100%' : '33%',
                    paddingRight: isSmallScreen ? '0px' : '10px',
                    maxWidth: '250px'
                }}>
                    <FormLabel>Requested By</FormLabel>
                    <Select
                        styles={customStyles}
                        placeholder="Select Requested By"
                        options={requestedBy.map(reqBy => ({ label: reqBy, value: reqBy }))}
                        onChange={handleChangeRequestedBy}
                        value={selectedRequestedBy ? { label: selectedRequestedBy, value: selectedRequestedBy } : ''}
                    />
                </div>
                <div style={{
                    width: isSmallScreen ? '100%' : '33%',
                    paddingRight: isSmallScreen ? '0px' : '10px',
                    maxWidth: '250px'
                }}>
                    <FormLabel>Product Group</FormLabel>
                    <Select
                        styles={customStyles}
                        placeholder="Select Product Group"
                        options={productGroups.map(prodGroup => ({ label: prodGroup, value: prodGroup }))}
                        onChange={handleChangeProductGroup}
                        value={selectedProductGroup ? { label: selectedProductGroup, value: selectedProductGroup } : ''}
                    />
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%'
            }}>
                <Button
                    style={{
                        margin: '10px',
                        padding: '10px',
                        border: '1px solid grey',
                        color: 'white',
                    }}
                    onClick={handleResetFilter}
                    disabled={!selectedBranch && !selectedRequestedBy && !selectedProductGroup}
                >
                    Reset Filter
                </Button>
            </div>
        </div>
    );
};

export default RequestFilter;