import { AppSettings, BaseStore } from './Infrastructure';
import { action, observable } from 'mobx';
import { StringResources } from '../Shared/Constants/StringResources';

declare let AuthenticationContext: new (arg0: {
  cacheLocation: string;
  clientId: string;
  redirectUri: string;
  instance: string;
  postLogoutRedirectUri: string;
  tenant: string;
}) => void;

export class AuthStore extends BaseStore {
  @observable public isLoggedIn = false;
  private appSettings: AppSettings;

  constructor() {
    super();
    this.appSettings = new AppSettings();
  }

  @action public setLoggedInStatus(status: boolean): void {
    this.isLoggedIn = status;
  }

  @action public init(): void {
    if (this.getAuthContext()) {
      return;
    }

    this.appSettings = window.appSettings;

    window.adalInstance = new AuthenticationContext({
      cacheLocation: this.appSettings.cacheLocation,
      clientId: this.appSettings.azureAppId,
      instance: this.appSettings.msLoginUrl,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: this.appSettings.postLogoutRedirectUri,
      tenant: this.appSettings.azureTenantId,
    });

    window.adalInstance.isCallback(window.location.hash);
    window.adalInstance.handleWindowCallback();
    window.getToken = this.getToken;
    this.isLoggedIn = false;
  }

  public getAuthContext(): any {
    return window.adalInstance;
  }

  public getToken = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getAuthContext().acquireToken(
        this.getAuthContext().config.clientId,
        (error: Error, token: string) => {
          if (error) {
            this.log(StringResources.ErrorAcquiringToken, error);
            this.logout();
            reject(error);
            return;
          }
          resolve(token);
        }
      );
    });
  };

  public async acquireGraphApiToken(callback: Function): Promise<string> {
    if (!this.getUser) {
      return callback('There has been an error aqcuiring Graph API token', null);
    }
    return await this.getAuthContext().acquireToken(`${this.appSettings.graphApiUrl}`, callback);
  }

  public login(): void {
    if (this.isAuthenticated) {
      return;
    }

    this.getAuthContext().login();
  }

  public logout = (): void => {
    if (!this.isAuthenticated) {
      return;
    }
    this.getAuthContext().logOut();
    this.setAuthContext(undefined);
    this.isLoggedIn = false;
  };

  public get isAuthenticated(): boolean {
    const cachedToken =
      this.getAuthContext()?.getCachedToken(this.getAuthContext().config.clientId) || null;
    return cachedToken !== null;
  }

  public get getUser(): { profile: any; username: string } | null {
    return this.getAuthContext()?.getCachedUser() || null;
  }

  private setAuthContext(adalInstance: any): void {
    window.adalInstance = adalInstance;
  }
}

export default AuthStore;
