import { BaseStore } from 'src/App/Infrastructure';
import { observable, action, runInAction } from 'mobx';
import { groupBy, multiply, divide, sumBy, forEach, meanBy, uniq } from 'lodash';
import moment from 'moment';
import {
  StockLevelForReorderReportResponse,
  StockLastPurchaseForReorderReportResponse,
} from '@vulcan/inventory-api-client/lib/models';
import {
  ProductSalesHistoryResponse,
  ProductSalesHistoryRequest,
} from '@vulcan/sales-api-client/lib/models';
import { toFixedNumber, workdayCount } from 'src/Shared/Utils';
import { ReorderTableDataRowGroupModel, ReorderTableDataRowModel } from './Models';
import { AlertType } from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';

export class ReorderStore extends BaseStore {
  @observable public loading = false;
  @observable public tableDataLoading = false;
  @observable public tableData: ReorderTableDataRowGroupModel[] = [];
  public sortedTableData: ReorderTableDataRowGroupModel[] = [];
  private cachedTableData: ReorderTableDataRowGroupModel[] = [];
  @observable public groupByWarehouses = false;
  @observable public groupByBranches = false;

  // Table
  private async getReorderTableRowData(
    branchCodes: string[],
    warehouseCodes: string[],
    productCategoryIds: number[],
    productGroupIds: number[],
    stockClassificationIds: number[]
  ): Promise<ReorderTableDataRowGroupModel[]> {
    const branchCodesParam = branchCodes.length > 0 ? branchCodes : undefined;
    const warehouseCodesParam = warehouseCodes.length > 0 ? warehouseCodes : undefined;
    const productGroupIdsParam = productGroupIds.length > 0 ? productGroupIds : undefined;
    const productCategoryIdsParam = productCategoryIds.length > 0 ? productCategoryIds : undefined;
    const stockClassificationIdsParam =
      stockClassificationIds.length > 0 ? stockClassificationIds : undefined;

    if (
      !branchCodesParam &&
      !warehouseCodesParam &&
      !productGroupIdsParam &&
      !productCategoryIdsParam &&
      !stockClassificationIdsParam
    ) {
      return [];
    }

    const salesOptions: ProductSalesHistoryRequest = {
      salesBranchCodes: branchCodesParam,
      warehouseCodes: warehouseCodesParam,
      productGroupIds: productGroupIdsParam,
      productCategoryIds: productCategoryIdsParam,
      stockClassificationIds: stockClassificationIdsParam,
    };
    const salesClient = await this.getSalesClient();
    const salesHistoryResponse = await salesClient.dailySales.getProductSalesHistory(salesOptions);
    const salesHistoryResult = JSON.parse(
      salesHistoryResponse._response.bodyAsText
    ) as ProductSalesHistoryResponse[];

    const options = {
      branchCodes: undefined,
      warehouseCodes: warehouseCodesParam,
      productGroupIds: productGroupIdsParam,
      productCategoryIds: productCategoryIdsParam,
      stockClassificationIds: stockClassificationIdsParam,
      productIds: undefined,
    };
    const inventoryClient = await this.getInventoryClient();
    const stockResponse = await inventoryClient.availableStock.getStockForReorderReport(options);
    const stockResult = JSON.parse(
      stockResponse._response.bodyAsText
    ) as StockLevelForReorderReportResponse[];

    const stockLastPurchaseResponse =
      await inventoryClient.availableStock.getStockLastPurchaseForReorderReport(options);
    const stockLastPurchaseResult = JSON.parse(
      stockLastPurchaseResponse._response.bodyAsText
    ) as StockLastPurchaseForReorderReportResponse[];

    const result: ReorderTableDataRowModel[] = this.combineResults(
      stockResult,
      salesHistoryResult,
      stockLastPurchaseResult
    );
    return this.mergeReorderTableData(result);
  }

  private combineResults(
    stockResult: StockLevelForReorderReportResponse[],
    salesHistoryResult: ProductSalesHistoryResponse[],
    stockLastPurchaseResult: StockLastPurchaseForReorderReportResponse[]
  ): ReorderTableDataRowModel[] {
    return salesHistoryResult.reduce(
      (previousValue: ReorderTableDataRowModel[], stockSales: ProductSalesHistoryResponse) => {
        const rowModel = new ReorderTableDataRowModel();

        rowModel.warehouseId = stockSales.warehouseId;
        rowModel.warehouseCode = stockSales.warehouseCode;
        rowModel.productId = stockSales.productId;
        rowModel.productCode = stockSales.productCode;
        rowModel.productCategoryId = stockSales.productCategoryId;
        rowModel.productCategory = stockSales.productCategoryName;
        rowModel.productGroupId = stockSales.productGroupId;
        rowModel.productGroup = stockSales.productGroupName;
        rowModel.stockClass = {
          code: stockSales.stockClassificationCode,
          color: stockSales.stockClassificationColour,
        };

        rowModel.salesBranchId = stockSales.salesBranchId;
        rowModel.salesBranchCode = stockSales.salesBranchCode;
        rowModel.averageMonthSales = toFixedNumber(stockSales.averageMonthSales, 2);
        rowModel.thisMonth = toFixedNumber(stockSales.thisMonth, 2);
        rowModel.oneMonthAgo = toFixedNumber(stockSales.oneMonthAgo, 2);
        rowModel.twoMonthsAgo = toFixedNumber(stockSales.twoMonthsAgo, 2);
        rowModel.threeMonthsAgo = toFixedNumber(stockSales.threeMonthsAgo, 2);
        rowModel.fourMonthsAgo = toFixedNumber(stockSales.fourMonthsAgo, 2);
        rowModel.fiveMonthsAgo = toFixedNumber(stockSales.fiveMonthsAgo, 2);

        const matchedStock = stockResult.find(
          (s) =>
            s.productCode === stockSales.productCode && s.warehouseCode === stockSales.warehouseCode
        );
        if (matchedStock) {
          rowModel.branchId = matchedStock.branchId;
          rowModel.branchCode = matchedStock.branchCode;

          rowModel.availableStock = matchedStock.inStock - matchedStock.committed;
          rowModel.committed = matchedStock.committed;
          rowModel.pending = matchedStock.pending;
          rowModel.onOrder = toFixedNumber(matchedStock.onOrder, 2);

          rowModel.eta = matchedStock.eta;
          rowModel.etaStatus = matchedStock.etaStatus;
          rowModel.eachWeight = toFixedNumber(divide(1000, matchedStock.conversionFactor), 2);
          rowModel.eachActualCost = toFixedNumber(matchedStock.eachActualCost, 2);
          rowModel.actualCost = toFixedNumber(
            multiply(matchedStock.inStock - matchedStock.committed, matchedStock.eachActualCost),
            2
          );
        }

        const matchedLastPurchase = stockLastPurchaseResult.find(
          (s) =>
            s.productCode === stockSales.productCode && s.warehouseCode === stockSales.warehouseCode
        );
        if (matchedLastPurchase) {
          rowModel.lastPurchaseOrderDate = matchedLastPurchase.lastPurchaseOrderDate;
          rowModel.lastPurchaseOrderFirstReceiptDate =
            matchedLastPurchase.lastPurchaseOrderFirstReceiptDate;
        }

        if (rowModel.eta) {
          const days = workdayCount(moment(rowModel.eta), moment());
          rowModel.leadDays = toFixedNumber(multiply(days, 1.2), 0);
        } else if (rowModel.lastPurchaseOrderDate && rowModel.lastPurchaseOrderFirstReceiptDate) {
          const days = workdayCount(
            moment(rowModel.lastPurchaseOrderDate),
            moment(rowModel.lastPurchaseOrderFirstReceiptDate)
          );
          rowModel.leadDays = toFixedNumber(multiply(days, 1.2), 0);
        } else {
          rowModel.leadDays = 96; // 80*1.20 END AS DaysTillReplenishment
        }

        previousValue.push(rowModel);
        return previousValue;
      },
      []
    );
  }

  private mergeReorderTableData(
    reorderTableModel: ReorderTableDataRowModel[]
  ): ReorderTableDataRowGroupModel[] {
    const result: ReorderTableDataRowGroupModel[] = [];

    const groupTableData = groupBy(
      reorderTableModel,
      (item) =>
        `${item.productCode}-` +
        `${item.salesBranchCode}-` +
        `${item.warehouseCode}-` +
        `${item.stockClass}-` +
        `${item.productCategory}-` +
        `${item.productGroup}-` +
        `${item.eachWeight}-` +
        `${item.eachActualCost}`
    );
    forEach(groupTableData, (group: ReorderTableDataRowModel[], key: string) => {
      const keyItem = group[0];

      const groupOnOrder = toFixedNumber(
        sumBy(group, (g) => g.onOrder),
        2
      );
      const groupAvailableStock = toFixedNumber(
        sumBy(group, (g) => g.availableStock),
        2
      );
      const groupCommitted = toFixedNumber(
        sumBy(group, (g) => g.committed),
        2
      );
      const groupPending = toFixedNumber(
        sumBy(group, (g) => g.pending),
        2
      );
      const groupActualCost = toFixedNumber(
        sumBy(group, (g) => g.actualCost),
        2
      );

      const groupAverageMonthSales = toFixedNumber(
        sumBy(group, (g) => g.averageMonthSales),
        2
      );
      const groupThisMonth = toFixedNumber(
        sumBy(group, (g) => g.thisMonth),
        2
      );
      const groupOneMonthAgo = toFixedNumber(
        sumBy(group, (g) => g.oneMonthAgo),
        2
      );
      const groupTwoMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.twoMonthsAgo),
        2
      );
      const groupThreeMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.threeMonthsAgo),
        2
      );
      const groupFourMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fourMonthsAgo),
        2
      );
      const groupFiveMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fiveMonthsAgo),
        2
      );

      // Calculate monthsCover and stockDays
      const groupMonthsCover = this.calculateMonthsCover(
        groupAverageMonthSales,
        groupAvailableStock
      );
      const groupStockDays = this.calculateStockDays(groupAverageMonthSales, groupAvailableStock);

      // Calculate averaged DaysTillReplenishment/leadDays
      // logic comes from procs [Reporting].[StockReOrder_Report]
      const groupLeadDaysValue = meanBy(group, (g) => g.leadDays);
      const groupLeadDays = groupLeadDaysValue < 10 ? 10 : toFixedNumber(groupLeadDaysValue, 0);

      let groupEta: string | undefined = undefined;
      let groupEtaStatus = '';
      forEach(group, (item) => {
        // take latest ETA
        if (item.eta && (!groupEta || moment(item.eta).isAfter(moment(groupEta)))) {
          groupEta = item.eta;
          groupEtaStatus = item.etaStatus;
        }
      });

      const rowGroupModel = new ReorderTableDataRowGroupModel({
        branchId: keyItem.branchId,
        branchCode: keyItem.branchCode,
        warehouseId: keyItem.warehouseId,
        warehouseCode: keyItem.warehouseCode,
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        productCategoryId: keyItem.productCategoryId,
        productCategory: keyItem.productCategory,
        productGroupId: keyItem.productGroupId,
        productGroup: keyItem.productGroup,
        stockClass: keyItem.stockClass,

        eachWeight: keyItem.eachWeight,
        actualCost: groupActualCost,

        onOrder: groupOnOrder,
        availableStock: groupAvailableStock,
        committed: groupCommitted,
        pending: groupPending,

        salesBranchId: keyItem.salesBranchId,
        salesBranchCode: keyItem.salesBranchCode,
        averageMonthSales: groupAverageMonthSales,
        thisMonth: groupThisMonth,
        oneMonthAgo: groupOneMonthAgo,
        twoMonthsAgo: groupTwoMonthsAgo,
        threeMonthsAgo: groupThreeMonthsAgo,
        fourMonthsAgo: groupFourMonthsAgo,
        fiveMonthsAgo: groupFiveMonthsAgo,

        monthsCover: groupMonthsCover,
        stockDays: groupStockDays,
        leadDays: groupLeadDays,
        eta: groupEta,
        etaStatus: groupEtaStatus,
      });

      result.push(rowGroupModel);
    });
    return result;
  }

  private calculateMonthsCover(
    groupAverageMonthSales: number,
    groupAvailableStock: number
  ): number {
    let groupMonthsCover = -1; // initial value which would display as '-' and 0 has it's meaning
    if (groupAverageMonthSales > 0) {
      const monthsCoverValue = divide(groupAvailableStock, groupAverageMonthSales);
      groupMonthsCover = toFixedNumber(monthsCoverValue, 1);
    }
    return groupMonthsCover;
  }

  private calculateStockDays(groupAverageMonthSales: number, groupAvailableStock: number): number {
    let groupStockDays = -1; // initial value which would display as '-' and 0 has it's meaning
    if (groupAverageMonthSales > 0) {
      const averageDailySales = divide(groupAverageMonthSales, 22);
      const stockDaysValue = divide(groupAvailableStock, averageDailySales);
      groupStockDays = toFixedNumber(stockDaysValue, 0);
    }
    return groupStockDays;
  }

  @action public async updateTableControl(
    branchCodes: string[],
    warehouseCodes: string[],
    productCategoryIds: number[],
    productGroupIds: number[],
    stockClassificationIds: number[]
  ): Promise<void> {
    try {
      this.tableDataLoading = true;
      this.cachedTableData = await this.getReorderTableRowData(
        branchCodes,
        warehouseCodes,
        productCategoryIds,
        productGroupIds,
        stockClassificationIds
      );
      this.updateTableData();
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingReorderTableException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingReorderTableException);
    } finally {
      runInAction(() => {
        this.tableDataLoading = false;
      });
    }
  }

  public updateSortedTableData(sortedTableData: ReorderTableDataRowGroupModel[]): void {
    this.sortedTableData = sortedTableData;
  }

  @action private updateTableData(): void {
    let tableData = this.cachedTableData;
    if (this.groupByWarehouses) {
      tableData = this.GroupByWarehouseTableData();
    }
    if (this.groupByBranches) {
      tableData = this.GroupByBranchTableData();
    }
    this.tableData = tableData;
  }

  private GroupByWarehouseTableData(): ReorderTableDataRowGroupModel[] {
    const warehouseGroupedTableData = groupBy(
      this.cachedTableData,
      (item) => `${item.productCode}-${item.warehouseCode}`
    );
    const result: ReorderTableDataRowGroupModel[] = [];
    forEach(warehouseGroupedTableData, (group: ReorderTableDataRowGroupModel[], _key: string) => {
      const keyItem = group[0];

      const salesBranchId =
        uniq(group.map((g) => g.salesBranchId)).length > 1 ? 0 : keyItem.salesBranchId;
      const salesBranchCode =
        uniq(group.map((g) => g.salesBranchCode)).length > 1 ? 'All' : keyItem.salesBranchCode;
      const groupAverageMonthSales = toFixedNumber(
        sumBy(group, (g) => g.averageMonthSales),
        2
      );
      const groupThisMonth = toFixedNumber(
        sumBy(group, (g) => g.thisMonth),
        2
      );
      const groupOneMonthAgo = toFixedNumber(
        sumBy(group, (g) => g.oneMonthAgo),
        2
      );
      const groupTwoMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.twoMonthsAgo),
        2
      );
      const groupThreeMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.threeMonthsAgo),
        2
      );
      const groupFourMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fourMonthsAgo),
        2
      );
      const groupFiveMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fiveMonthsAgo),
        2
      );

      const groupAvailableStock = keyItem.availableStock;
      // Calculate monthsCover and stockDays
      const groupMonthsCover = this.calculateMonthsCover(
        groupAverageMonthSales,
        groupAvailableStock
      );
      const groupStockDays = this.calculateStockDays(groupAverageMonthSales, groupAvailableStock);

      // Calculate averaged DaysTillReplenishment/leadDays
      // logic comes from procs [Reporting].[StockReOrder_Report]
      const groupLeadDaysValue = meanBy(group, (g) => g.leadDays);
      const groupLeadDays = groupLeadDaysValue < 10 ? 10 : toFixedNumber(groupLeadDaysValue, 0);

      const rowGroupModel = new ReorderTableDataRowGroupModel({
        branchId: keyItem.branchId,
        branchCode: keyItem.branchCode,
        warehouseId: keyItem.warehouseId,
        warehouseCode: keyItem.warehouseCode,
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        productCategoryId: keyItem.productCategoryId,
        productCategory: keyItem.productCategory,
        productGroupId: keyItem.productGroupId,
        productGroup: keyItem.productGroup,
        stockClass: keyItem.stockClass,

        eachWeight: keyItem.eachWeight,
        actualCost: keyItem.actualCost,

        onOrder: keyItem.onOrder,
        availableStock: groupAvailableStock,
        committed: keyItem.committed,
        pending: keyItem.pending,

        salesBranchId: salesBranchId,
        salesBranchCode: salesBranchCode,
        averageMonthSales: groupAverageMonthSales,
        thisMonth: groupThisMonth,
        oneMonthAgo: groupOneMonthAgo,
        twoMonthsAgo: groupTwoMonthsAgo,
        threeMonthsAgo: groupThreeMonthsAgo,
        fourMonthsAgo: groupFourMonthsAgo,
        fiveMonthsAgo: groupFiveMonthsAgo,

        monthsCover: groupMonthsCover,
        stockDays: groupStockDays,
        leadDays: groupLeadDays,
        eta: keyItem.eta,
        etaStatus: keyItem.etaStatus,
      });

      result.push(rowGroupModel);
    });
    return result;
  }

  private GroupByBranchTableData(): ReorderTableDataRowGroupModel[] {
    const branchGroupedTableData = groupBy(
      this.cachedTableData,
      (item) => `${item.productCode}-${item.salesBranchCode}`
    );
    const tableData: ReorderTableDataRowGroupModel[] = [];
    forEach(branchGroupedTableData, (group: ReorderTableDataRowGroupModel[], key: string) => {
      const keyItem = group[0];

      const warehouseId =
        uniq(group.map((g) => g.warehouseId)).length > 1 ? 0 : keyItem.warehouseId;
      const warehouseCode =
        uniq(group.map((g) => g.warehouseCode)).length > 1 ? 'All' : keyItem.warehouseCode;

      const emptyStockClass = { code: '', color: '' };
      const matchedStockClass = group.find((g) => g.warehouseCode === g.salesBranchCode);
      const stockClass =
        uniq(group.map((g) => g.warehouseCode)).length > 1
          ? matchedStockClass
            ? matchedStockClass.stockClass
            : emptyStockClass
          : keyItem.stockClass;

      // Business logic: filter by sales branch when aggreate stock warehouse.
      const filteredGroup = group.filter((g) => keyItem.salesBranchCode === g.branchCode);

      const groupOnOrder = toFixedNumber(
        sumBy(filteredGroup, (g) => g.onOrder),
        2
      );
      const groupAvailableStock = toFixedNumber(
        sumBy(filteredGroup, (g) => g.availableStock),
        2
      );
      const groupCommitted = toFixedNumber(
        sumBy(filteredGroup, (g) => g.committed),
        2
      );
      const groupPending = toFixedNumber(
        sumBy(filteredGroup, (g) => g.pending),
        2
      );
      const groupActualCost = toFixedNumber(
        sumBy(filteredGroup, (g) => g.actualCost),
        2
      );

      const groupAverageMonthSales = toFixedNumber(
        sumBy(group, (g) => g.averageMonthSales),
        2
      );
      const groupThisMonth = toFixedNumber(
        sumBy(group, (g) => g.thisMonth),
        2
      );
      const groupOneMonthAgo = toFixedNumber(
        sumBy(group, (g) => g.oneMonthAgo),
        2
      );
      const groupTwoMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.twoMonthsAgo),
        2
      );
      const groupThreeMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.threeMonthsAgo),
        2
      );
      const groupFourMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fourMonthsAgo),
        2
      );
      const groupFiveMonthsAgo = toFixedNumber(
        sumBy(group, (g) => g.fiveMonthsAgo),
        2
      );

      // Calculate monthsCover and stockDays
      const groupMonthsCover = this.calculateMonthsCover(
        groupAverageMonthSales,
        groupAvailableStock
      );
      const groupStockDays = this.calculateStockDays(groupAverageMonthSales, groupAvailableStock);

      let groupEta: string | undefined = undefined;
      let groupEtaStatus = '';
      forEach(filteredGroup, (item) => {
        // take latest ETA
        if (item.eta && (!groupEta || moment(item.eta).isAfter(moment(groupEta)))) {
          groupEta = item.eta;
          groupEtaStatus = item.etaStatus;
        }
      });

      // Calculate averaged DaysTillReplenishment/leadDays
      // logic comes from procs [Reporting].[StockReOrder_Report]
      const groupLeadDaysValue = meanBy(filteredGroup, (g) => g.leadDays);
      const groupLeadDays = groupLeadDaysValue < 10 ? 10 : toFixedNumber(groupLeadDaysValue, 0);

      const rowGroupModel = new ReorderTableDataRowGroupModel({
        branchId: keyItem.branchId,
        branchCode: keyItem.branchCode,
        warehouseId: warehouseId,
        warehouseCode: warehouseCode,
        productId: keyItem.productId,
        productCode: keyItem.productCode,
        productCategoryId: keyItem.productCategoryId,
        productCategory: keyItem.productCategory,
        productGroupId: keyItem.productGroupId,
        productGroup: keyItem.productGroup,
        stockClass: stockClass,

        eachWeight: keyItem.eachWeight,
        actualCost: groupActualCost,

        onOrder: groupOnOrder,
        availableStock: groupAvailableStock,
        committed: groupCommitted,
        pending: groupPending,

        salesBranchId: keyItem.salesBranchId,
        salesBranchCode: keyItem.salesBranchCode,
        averageMonthSales: groupAverageMonthSales,
        thisMonth: groupThisMonth,
        oneMonthAgo: groupOneMonthAgo,
        twoMonthsAgo: groupTwoMonthsAgo,
        threeMonthsAgo: groupThreeMonthsAgo,
        fourMonthsAgo: groupFourMonthsAgo,
        fiveMonthsAgo: groupFiveMonthsAgo,

        monthsCover: groupMonthsCover,
        stockDays: groupStockDays,
        leadDays: groupLeadDays,
        eta: groupEta,
        etaStatus: groupEtaStatus,
      });

      tableData.push(rowGroupModel);
    });
    return tableData;
  }

  @action public setGroupByWarehouses(groupByWarehouses: boolean): void {
    this.groupByWarehouses = groupByWarehouses;
    if (this.groupByBranches) {
      this.groupByBranches = false;
    }
    this.updateTableData();
  }

  @action public setGroupByBranches(groupByBranches: boolean): void {
    this.groupByBranches = groupByBranches;
    if (this.groupByWarehouses) {
      this.groupByWarehouses = false;
    }
    this.updateTableData();
  }
}
export default ReorderStore;
