import React, { FunctionComponent, useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
  value: string;
  onChange: Function;
  maxLength: number;
}

export const TextInput: FunctionComponent<Props> = (prop: Props): JSX.Element => {
  const { value, onChange, maxLength } = prop;
  const [textValue, setTextValue] = useState(value);

  return (
    <TextField
      value={textValue}
      inputProps={{ maxLength: maxLength }}
      multiline
      onChange={(e): void => {
        const newValue = e.target.value;
        setTextValue(newValue);
        onChange(newValue);
      }}
    />
  );
};
