export class HttpService {
  // Check the status of the response if valid, else return error with message from the server
  public static async checkStatus(response: Response): Promise<Response> {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const errorMessage = await response.json();
      const errorObject = {
        errorMessage: {},
        status: 500,
      };
      errorObject.errorMessage = errorMessage;
      errorObject.status = response.status;
      throw errorObject;
    }
  }
}

export default HttpService;
