import { Permissions } from './../../Security/Permissions';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { RouteComponentProps } from 'react-router';

export class RouteModel {
  permission = Permissions.None;
  path = '';
  private?: boolean;
  name?: string;
  icon?: React.ComponentType<SvgIconProps>;
  // tslint:disable-next-line:no-any
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  redirect?: boolean;
  pathTo?: string;
  collapse?: boolean;
  state?: string;
  mini?: string;
  renderInSidebar?: boolean;
  settingsRoute?: boolean;
  views?: RouteModel[];

  constructor(fields: Partial<RouteModel>) {
    Object.assign(this, fields);
  }
}

export default RouteModel;
