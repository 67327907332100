import { forEach, groupBy, uniqBy } from 'lodash';
import { BaseStore } from 'src/App/Infrastructure';
import { action, computed, observable, runInAction, toJS } from 'mobx';
import {
  StockFilterResponse,
  StockGetStockFiltersResponse,
  StockWarehousesFilterResponse,
  StockClassificationFilterResponse,
} from '@vulcan/inventory-api-client/lib/models';
import { OptionModel, GroupOptionModel } from '@vulcan/vulcan-materialui-theme/build/components';
import BranchModel from './Models/BranchModel';
import AlertType from 'src/Shared/Alert/AlertType';
import { StringResources } from 'src/Shared/Constants';
import { localeCompare } from 'src/Shared/Utils';

export class StockFilterStore extends BaseStore {
  @observable public loading = false;
  @observable private allBranches: BranchModel[] = [];
  @observable private allStockFilters: StockFilterResponse | undefined;

  @observable public selectedSaleBranchCodes: string[] = [];
  @observable public selectedStockWarehouseCodes: string[] = [];
  @observable public selectedProductCategoryIds: number[] = [];
  @observable public selectedProductGroupIds: number[] = [];
  @observable public selectedStockClassificationIds: number[] = [];

  @action public async init(): Promise<void> {
    this.loading = true;
    const allStockFilters = await this.getAllStockFilters();
    const allBranches = await this.getAllBranches();
    const userBranches = this.GetUserBranches;

    runInAction(() => {
      this.allStockFilters = allStockFilters;
      this.allBranches = allBranches;
      this.selectedSaleBranchCodes  = userBranches;
      this.loading = false;
    });
  }

  // Filters
  private async getAllBranches(): Promise<BranchModel[]> {
    let allBranches: BranchModel[] = [];
    try {
      const salesClient = await this.getSalesClient();
      const response = await salesClient.branches.getBranches();
      const result = response._response.parsedBody;
      allBranches = result
        .map((r) => new BranchModel(r.branchId, r.code, r.name ? r.name : '', r.profitCentreCode))
        .sort(BranchModel.compareFn);
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingBranchException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingBranchException);
    } finally {
      return allBranches;
    }
  }

  private async getAllStockFilters(): Promise<StockFilterResponse | undefined> {
    try {
      const inventoryClient = await this.getInventoryClient();
      const response = await inventoryClient.stock.getStockFilters();
      return JSON.parse(response._response.bodyAsText) as StockGetStockFiltersResponse;
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingStockFiltersException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingStockFiltersException);
      return undefined;
    }
  }

  @computed public get allBranchDataSource(): GroupOptionModel[] {
    const allOptions: GroupOptionModel[] = [];
    if (this.allBranches) {
      const branchesByProfitCentreCode = groupBy<BranchModel>(
        this.allBranches,
        (item) => item.profitCentreCode
      );
      forEach(branchesByProfitCentreCode, (group: BranchModel[], key: string) => {
        allOptions.push(
          new GroupOptionModel({
            label: key,
            options: group
              .sort((x, y) => localeCompare(x.code, y.code))
              .map((b) => {
                return new OptionModel({
                  value: b.branchId.toString(),
                  label: b.code,
                });
              }),
          })
        );
      });
    }
    return allOptions;
  }

  @computed public get allGroupedWarehouseDataSource(): GroupOptionModel[] {
    const allOptions: GroupOptionModel[] = [];
    if (this.allStockFilters) {
      const warehousesGroupedByBranchCode = groupBy<StockWarehousesFilterResponse>(
        this.allStockFilters.allWarehouses,
        (item) => item.branchCode
      );
      forEach(
        warehousesGroupedByBranchCode,
        (group: StockWarehousesFilterResponse[], key: string) => {
          allOptions.push(
            new GroupOptionModel({
              label: key,
              options: group
                .sort((x, y) => localeCompare(x.warehouseCode, y.warehouseCode))
                .map((r) => {
                  return new OptionModel({
                    value: `${r.warehouseId}`,
                    label: `${r.warehouseCode}`,
                  });
                }),
            })
          );
        }
      );
    }
    return allOptions;
  }

  @computed public get allProductCategoryDataSource(): OptionModel[] {
    if (this.allStockFilters) {
      const productCategories =
        this.selectedStockWarehouseCodes.length > 0
          ? this.allStockFilters.allProductCategories.filter(
              (pc) =>
                this.selectedStockWarehouseCodes.filter((wc) => pc.warehouseCodes.includes(wc))
                  .length > 0
            )
          : this.allStockFilters.allProductCategories;
      const allOptions = productCategories
        .sort((a, b) => localeCompare(a.name!, b.name!))
        .map(
          (pc) =>
            new OptionModel({
              value: `${pc.productCategoryId}`,
              label: `${pc.name}`,
            })
        );

      return allOptions;
    }
    return [];
  }

  @computed public get allProductGroupDataSource(): OptionModel[] {
    if (this.allStockFilters) {
      const productGroups = this.allStockFilters.allProductGroups
        .filter(
          (pc) =>
            this.selectedStockWarehouseCodes.length === 0 ||
            this.selectedStockWarehouseCodes.filter((wc) => pc.warehouseCodes.includes(wc)).length >
              0
        )
        .filter(
          (pc) =>
            this.selectedProductCategoryIds.length === 0 ||
            this.selectedProductCategoryIds.includes(pc.productCategoryId)
        );

      const allOptions = productGroups
        .sort((a, b) => localeCompare(a.name!, b.name!))
        .map(
          (pg) =>
            new OptionModel({
              value: `${pg.productGroupId}`,
              label: `${pg.name}`,
            })
        );

      return allOptions;
    }
    return [];
  }

  @computed public get allStockClassificationDataSource(): OptionModel[] {
    if (this.allStockFilters) {
      const stockClassifications = this.allStockFilters.allStockClassifications
        // Filter by stock warehouses
        .filter(
          (sc) =>
            this.selectedStockWarehouseCodes.length === 0 ||
            this.selectedStockWarehouseCodes.filter((wc) => sc.warehouseCodes.includes(wc)).length >
              0
        )
        // Filter by product categories
        .filter(
          (sc) =>
            this.selectedProductCategoryIds.length === 0 ||
            this.selectedProductCategoryIds.filter((pc) => sc.productCategories.includes(pc))
              .length > 0
        )
        // Filter by product groups
        .filter(
          (sc) =>
            this.selectedProductGroupIds.length === 0 ||
            this.selectedProductGroupIds.filter((pg) => sc.productGroups.includes(pg)).length > 0
        )
        // Filter by product category/group and warehouse
        .filter((sc) => {
          const hasSelectedWarehouseCodes = this.selectedStockWarehouseCodes.length > 0;
          const hasSelectedProductCategories = this.selectedProductCategoryIds.length > 0;
          const hasSelectedProductGroupIds = this.selectedProductGroupIds.length > 0;

          // Only filter when user has selected a warehouse AND a product category/group
          if (
            hasSelectedWarehouseCodes &&
            (hasSelectedProductCategories || hasSelectedProductGroupIds)
          ) {
            let productGroupIds = this.selectedProductGroupIds;

            // Calculate all product groups for the selected product categories.
            // This handles when user has no filter for product groups. I.e. "All Product Groups"
            if (!hasSelectedProductGroupIds && hasSelectedProductCategories) {
              productGroupIds = this.allStockFilters!.allProductGroups.filter((productGroup) =>
                this.selectedProductCategoryIds.includes(productGroup.productCategoryId)
              ).map((productGroup) => productGroup.productGroupId);
            }

            // Decide if this stock classification should be shown based on warehouse and product group.
            for (const warehouseCode of toJS(this.selectedStockWarehouseCodes)) {
              const warehouseCodeParameter = this.getParameterNameCaseInsensitive(
                sc.productGroupByWarehouse,
                warehouseCode
              );
              if (warehouseCodeParameter && sc.productGroupByWarehouse[warehouseCodeParameter]) {
                for (const productGroupId of sc.productGroupByWarehouse[warehouseCodeParameter]) {
                  if (productGroupIds!.includes(productGroupId)) {
                    return true; // Stock classification should be shown.
                  }
                }
              }
            }
            return false; // Stock classification should not be shown.
          }
          return true; // Filter nothing as user has not selected a warehouse AND a product category/group.
        });

      const allOptions = uniqBy(
        stockClassifications,
        (stockClassification) => stockClassification.name
      )
        .sort(
          (a: StockClassificationFilterResponse, b: StockClassificationFilterResponse): number =>
            localeCompare(a.name!, b.name!)
        )
        .map(
          (sc) =>
            new OptionModel({
              value: `${sc.stockClassificationId}`,
              label: `${sc.name}`,
            })
        );

      return allOptions;
    }
    return [];
  }

  private getParameterNameCaseInsensitive(object: {}, key: string): string | undefined {
    return Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
  }

  @action public selectedSaleBranchChanged(selectedBranches: OptionModel[]): void {
    this.selectedSaleBranchCodes = selectedBranches.map((b) => b.label);
  }

  @action public selectedStockWarehouseChanged(selectedWarehouses: OptionModel[]): void {
    this.selectedStockWarehouseCodes = selectedWarehouses.map((w) => w.label);
    this.clearSelectedProductCategoryIds();
    this.clearSelectedProductGroupIds();
    this.clearSelectedStockClassificationIds();
  }

  @action public selectedProductCategoryChanged(selectedCategories: OptionModel[]): void {
    this.selectedProductCategoryIds = selectedCategories.map((c) => Number(c.value));
    this.clearSelectedProductGroupIds();
    this.clearSelectedStockClassificationIds();
  }

  @action public selectedProductGroupChanged(selectedGroupes: OptionModel[]): void {
    this.selectedProductGroupIds = selectedGroupes.map((g) => Number(g.value));
    this.clearSelectedStockClassificationIds();
  }

  @action public selectedStockClassificationChanged(selectedClassifications: OptionModel[]): void {
    this.selectedStockClassificationIds = selectedClassifications.map((c) => Number(c.value));
  }

  private clearSelectedProductCategoryIds(): void {
    if (this.selectedProductCategoryLabels.length === 0) {
      this.selectedProductCategoryIds = [];
    }
  }

  private clearSelectedProductGroupIds(): void {
    if (this.selectedProductGroupLabels.length === 0) {
      this.selectedProductGroupIds = [];
    }
  }
  private clearSelectedStockClassificationIds(): void {
    if (this.selectedStockClassificationLabels.length === 0) {
      this.selectedStockClassificationIds = [];
    }
  }

  public get selectedProductCategoryLabels(): string[] {
    return this.allProductCategoryDataSource
      .filter((item) => this.selectedProductCategoryIds.includes(Number(item.value)))
      .map((item) => item.label);
  }

  public get selectedProductGroupLabels(): string[] {
    return this.allProductGroupDataSource
      .filter((item) => this.selectedProductGroupIds.includes(Number(item.value)))
      .map((item) => item.label);
  }

  public get selectedStockClassificationLabels(): string[] {
    return this.allStockClassificationDataSource
      .filter((item) => this.selectedStockClassificationIds.includes(Number(item.value)))
      .map((item) => item.label);
  }
}
