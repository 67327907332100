import * as React from 'react';
import AppStore from './AppStore';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SiteWrapper from './SiteWrapper';
import { ErrorBoundary } from '@vulcan/vulcan-materialui-theme';
import withAppInsights from '../Shared/AppInsights';
import { StylesProvider } from '@material-ui/core';
import { Rule } from 'jss';

const classnameGenerator = (rule: Rule) => {
  // The index starts at -1000000000, so we can make the numbers look a bit nicer.
  // MUI seems like it's got multiple generators again, which is confusing as we only have one lib import.
  // This should fix the issue for now, but can be removed if the generators are functional now.
  return `${rule.key}-${1000000000 + rule.options.parent!.options.index!}`;
};

@observer
export class App extends React.Component<{}, {}> {
  private appStore: AppStore;

  constructor(props: {}) {
    super(props);
    this.appStore = new AppStore();
  }

  public async componentDidMount(): Promise<void> {
    await this.appStore.loadAppData();
  }

  public render(): React.ReactNode {
    if (!this.appStore.isSettingLoaded || !this.appStore.authStore.isLoggedIn) {
      return null;
    }

    return (
      <StylesProvider generateClassName={classnameGenerator}>
        <ErrorBoundary logError={this.appStore.log}>
          <Provider {...this.appStore.getStores()}>
            <Router>
              <Switch>
                <Redirect from="/" to={'/index'} exact={true} />
                <Route
                  path="/:prefix"
                  render={(): JSX.Element => <SiteWrapper appStore={this.appStore} />}
                />
              </Switch>
            </Router>
          </Provider>
        </ErrorBoundary>
      </StylesProvider>
    );
  }
}

export default withAppInsights(App);
