import { localeCompare } from 'src/Shared/Utils';

export class BranchModel {
  branchId: number;
  code: string;
  name: string;
  profitCentreCode: string | undefined;

  constructor(branchId: number, code: string, name: string, profitCentreCode: string | undefined) {
    this.branchId = branchId;
    this.code = code;
    this.name = name;
    this.profitCentreCode = profitCentreCode;
  }

  public static compareFn = (a: BranchModel, b: BranchModel): number =>
    localeCompare(a.code, b.code);
}

export default BranchModel;
