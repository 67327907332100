import React, { ReactNode } from 'react';
import { inject, observer } from 'mobx-react';
import { IconResources, StringResources } from 'src/Shared/Constants';
import ReactTable, { CellInfo, Column, FinalState, ReactTableDefaults } from 'react-table';
import { tokilograms, toMeters } from 'src/Shared/Utils';
import { Icon } from '@vulcan/vulcan-materialui-theme';
import { reaction } from 'mobx';
import { StockTableStore } from './StockTableStore';
import { StockTableModel } from './StockTableModel';
import StockDetailDialog from '../StockDetailTable/StockDetailDialog';
import { StockDetailTableStore } from '../StockDetailTable/StockDetailTableStore';

interface Props {
  stockTableStore?: StockTableStore;
  stockDetailTableStore?: StockDetailTableStore;
}

@inject('stockTableStore', 'stockDetailTableStore')
@observer
export class StockTable extends React.Component<Props> {
  private table: ReactTable<StockTableModel> | undefined;

  public componentDidMount = (): void => {
    // page default to 0
    reaction(() => this.props.stockTableStore!.tableData, this.showFirstPageOfTable);
  };

  public showFirstPageOfTable = (): void => {
    if (this.table && this.props.stockTableStore!.onlyRefreshTableData === false) {
      this.table.setState({ ...this.table.state, page: 0 });
    }
    this.props.stockTableStore!.setOnlyRefreshTableData(false);
  };

  public render(): JSX.Element {
    const { stockDetailTableStore: detailStore, stockTableStore: stockStore } = this.props;

    const saveAndNext = async (): Promise<void> => {
      await detailStore!.saveChangesAndMarkReviewed();
      stockStore!.openNextProductWithoutReviewed();
      await detailStore!.populateTableControl(stockStore!.selectedRow);
    };

    const toPreviousRow = async (): Promise<void> => {
      stockStore!.openPreviousProduct();
      await detailStore!.populateTableControl(stockStore!.selectedRow);
    };

    const toNextRow = async (): Promise<void> => {
      stockStore!.openNextProductWithoutReviewed();
      await detailStore!.populateTableControl(stockStore!.selectedRow);
    };

    return (
      <>
        <ReactTable
          ref={(table: ReactTable<StockTableModel>): void => {
            this.table = table;
          }}
          data={stockStore!.tableData.slice()}
          columns={this.tableColumns}
          column={{ ...ReactTableDefaults.column, style: { whiteSpace: 'unset' } }}
          loading={stockStore!.tableDataLoading}
          noDataText={stockStore!.tableDataLoading ? '' : StringResources.TableNoRowsFound}
          minRows={10}
          pageSize={10}
          className="react-table -striped -highlight"
          filterable={false}
          sortable={true}
          defaultSorted={[{ id: 'product-detail', desc: false }]}
          showPageJump={false}
          showPageSizeOptions={false}
          showPaginationTop={false}
          showPaginationBottom={true}
          showPagination={true}
          collapseOnDataChange={false}
          collapseOnSortingChange={false}
          collapseOnPageChange={false}
          resizable={false}
        >
          {(state: FinalState, makeTable: () => React.ReactElement): ReactNode => {
            stockStore!.updatePageRows(state.sortedData, this.table);
            return makeTable();
          }}
        </ReactTable>

        <StockDetailDialog
          selectedRow={this.props.stockTableStore!.selectedRow}
          isOpen={this.props.stockTableStore!.isDialogOpen}
          closeDialog={this.props.stockTableStore!.closeDialog}
          saveAndNext={saveAndNext}
          toPreviousRow={toPreviousRow}
          toNextRow={toNextRow}
          isFirstRow={stockStore!.isFirstRow}
        />
      </>
    );
  }

  public get tableColumns(): Column<StockTableModel>[] {
    const { stockTableStore, stockDetailTableStore } = this.props;

    const openDialog = async (cell: CellInfo): Promise<void> => {
      const row = cell.original as StockTableModel;
      stockTableStore!.openDialog(row, cell.index);
      await stockDetailTableStore!.populateTableControl(row);
    };

    const stringContainsFilterMethod = (filter, row): boolean =>
      row[filter.id].toLowerCase().includes(filter.value.trim().toLowerCase());
    return [
      {
        expander: true,
        show: false,
      },
      {
        id: 'product-detail',
        Header: 'Product Detail',
        accessor: 'productCode',
        width: 200,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
        Cell: (cell: CellInfo): JSX.Element => (
          <div
            className="click-product"
            onClick={async (): Promise<void> => await openDialog(cell)}
          >
            {cell.value}
          </div>
        ),
      },
      {
        id: 'product-description',
        Header: 'Description',
        accessor: 'productDescription',
        width: 160,
        sortable: false,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
        Cell: (cell: CellInfo): JSX.Element => cell.value ? cell.value : '-',
      },
      {
        id: 'product-die-code',
        Header: 'Die Code',
        accessor: 'dieCode',
        width: 120,
        sortable: false,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
        Cell: (cell: CellInfo): JSX.Element => cell.value ? cell.value : '-',
      },
      {
        id: 'product-category',
        Header: 'Product Category',
        accessor: 'productCategory',
        width: 160,
        sortable: false,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
      },
      {
        id: 'product-group',
        Header: 'Product Group',
        accessor: 'productGroup',
        width: 150,
        sortable: false,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
      },
      {
        id: 'product-size',
        Header: 'Size',
        accessor: 'productSize',
        sortable: false,
        width: 100,
        filterable: true,
        filterMethod: stringContainsFilterMethod,
      },
      {
        id: 'product-length',
        Header: 'Length',
        accessor: 'productLength',
        sortable: false,
        width: 80,
        Cell: (cell: CellInfo): string => toMeters(cell.value),
      },
      {
        id: 'each-weight',
        Header: 'Each Weight',
        accessor: 'eachWeight',
        style: { textAlign: 'right' },
        width: 130,
        Cell: (cell: CellInfo): string => tokilograms(cell.value),
      },
      {
        id: 'units-per-tonne',
        Header: 'Units Per Tonne',
        accessor: 'unitsPerTonne',
        width: 160,
      },
      {
        Header: 'Reviewed',
        accessor: 'reviewedProduct',
        width: 100,
        sortable: false,
        filterable: false,
        style: { justifyContent: 'center' },
        Cell: (cell: CellInfo): JSX.Element => {
          return cell.value === false ? <></> : <Icon icon={IconResources.GREENTICK} />;
        },
      },
    ];
  }
}
