import * as React from 'react';
import { observer } from 'mobx-react';
import { FormLabel } from '@material-ui/core';
import { Button, GridContainer, GridItem } from '@vulcan/vulcan-materialui-theme';
import {
  OptionModel,
  VulcanSelect,
  VulcanGroupSelect,
} from '@vulcan/vulcan-materialui-theme/build/components';
import { StockFilterStore } from './StockFilterStore';
import { toJS } from 'mobx';

interface StockFilterProps {
  stockFilterStore?: StockFilterStore;
  onFilterApplied: (
    branchCodes: string[],
    warehouseCodes: string[],
    productCategoryIds: number[],
    productGroupIds: number[],
    stockClassificationIds: number[]
  ) => void;
  showStockWarehouse?: boolean;
}

@observer
export class StockFilter extends React.Component<StockFilterProps> {
  public async componentDidMount(): Promise<void> {
    await this.props.stockFilterStore!.init();
  }

  public render(): JSX.Element {
    const { stockFilterStore } = this.props;

    return (
      <GridContainer>
        <GridItem lg={2} xs={12}>
          <FormLabel>Sales Branch</FormLabel>
          <VulcanGroupSelect
            placeholder="All Branches"
            options={stockFilterStore!.allBranchDataSource}
            handleChange={this.handleSaleBranchChange}
            isMulti={true}
            closeMenuOnSelect={false}
            isLoading={stockFilterStore!.loading}
            isDisabled={false}
            allowSelectAll={true}
            selected={stockFilterStore!.selectedSaleBranchCodes}
            badgeShowMaxSelectionNumber={1}
          />
        </GridItem>
        {this.props.showStockWarehouse === true && (
          <GridItem lg={2} xs={12}>
            <FormLabel>Stock Warehouse</FormLabel>
            <VulcanGroupSelect
              placeholder="All Warehouses"
              options={stockFilterStore!.allGroupedWarehouseDataSource}
              handleChange={this.handleStockWarehouseChange}
              isMulti={true}
              allowSelectAll={true}
              closeMenuOnSelect={false}
              isLoading={stockFilterStore!.loading}
              selected={stockFilterStore!.selectedStockWarehouseCodes}
              badgeShowMaxSelectionNumber={1}
            />
          </GridItem>
        )}
        <GridItem lg={2} xs={12}>
          <FormLabel>Product Category</FormLabel>
          <VulcanSelect
            placeholder="All Product Categories"
            options={stockFilterStore!.allProductCategoryDataSource}
            handleChange={this.handleProductCategoryChange}
            isMulti={true}
            allowSelectAll={true}
            closeMenuOnSelect={false}
            isLoading={stockFilterStore!.loading}
            selected={stockFilterStore!.selectedProductCategoryLabels}
            badgeShowMaxSelectionNumber={1}
          />
        </GridItem>
        <GridItem lg={2} xs={12}>
          <FormLabel>Product Group</FormLabel>
          <VulcanSelect
            placeholder="All Product Groups"
            options={stockFilterStore!.allProductGroupDataSource}
            handleChange={this.handleProductGroupChange}
            isMulti={true}
            allowSelectAll={true}
            closeMenuOnSelect={false}
            isLoading={stockFilterStore!.loading}
            selected={stockFilterStore!.selectedProductGroupLabels}
            badgeShowMaxSelectionNumber={1}
          />
        </GridItem>
        <GridItem lg={2} xs={12}>
          <FormLabel>Class</FormLabel>
          <VulcanSelect
            placeholder="All Stock Classifications"
            options={stockFilterStore!.allStockClassificationDataSource}
            handleChange={this.handleStockClassificationChange}
            isMulti={true}
            allowSelectAll={true}
            closeMenuOnSelect={false}
            isLoading={stockFilterStore!.loading}
            selected={stockFilterStore!.selectedStockClassificationLabels}
            badgeShowMaxSelectionNumber={1}
          />
        </GridItem>
        <GridItem lg={this.props.showStockWarehouse === true ? 2 : 4} xs={12} align="right">
          <Button
            disabled={this.props.stockFilterStore!.isLoading}
            className="action-button filter-apply-button"
            type="button"
            color="warning"
            onClick={this.handleButtonClick}
          >
            Apply Filter
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  private handleSaleBranchChange = (selectedBranch: OptionModel[]): void => {
    this.props.stockFilterStore!.selectedSaleBranchChanged(selectedBranch);
  };

  private handleStockWarehouseChange = (selectedWarehouses: OptionModel[]): void => {
    this.props.stockFilterStore!.selectedStockWarehouseChanged(selectedWarehouses);
  };

  private handleProductCategoryChange = (selectedCategories: OptionModel[]): void => {
    this.props.stockFilterStore!.selectedProductCategoryChanged(selectedCategories);
  };

  private handleProductGroupChange = (selectedGroups: OptionModel[]): void => {
    this.props.stockFilterStore!.selectedProductGroupChanged(selectedGroups);
  };

  private handleStockClassificationChange = (selectedClassifications: OptionModel[]): void => {
    this.props.stockFilterStore!.selectedStockClassificationChanged(selectedClassifications);
  };

  private handleButtonClick = async (): Promise<void> => {
    this.props.onFilterApplied(
      toJS(this.props.stockFilterStore!.selectedSaleBranchCodes),
      toJS(this.props.stockFilterStore!.selectedStockWarehouseCodes),
      toJS(this.props.stockFilterStore!.selectedProductCategoryIds),
      toJS(this.props.stockFilterStore!.selectedProductGroupIds),
      toJS(this.props.stockFilterStore!.selectedStockClassificationIds)
    );
  };
}
