import * as React from 'react';
import ReactTable, { Column, TableCellRenderer, RowInfo, CellInfo, Filter } from 'react-table';
import { ProductMapsStore } from 'src/ProductMaps/ProductMapsStore';
import { observer, inject } from 'mobx-react';
import ProductMapModal from 'src/ProductMaps/Modal/ProductMapModal';
import { observable, action, toJS, runInAction } from 'mobx';
import * as _ from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardIcon,
  GridContainer,
  GridItem,
  Snackbar,
  NotAuthorised,
  Icon,
  FormInput,
} from '@vulcan/vulcan-materialui-theme';
import CustomSweetAlert from 'src/Shared/Alert/CustomSweetAlert';
import ActionsCell from './ActionCell';
import { ProductCodeMapModel } from './ProductCodeMapModel';
import { ProductCodeMapResponse } from '@vulcan/purchasing-api-client/esm/models';
import { IconResources } from '../Shared/Constants';
import SecurityStore from '../App/Security/SecurityStore';
import Permissions from '../App/Security/Permissions';
import EditableCell from './EditableCell';

interface TableState {
  page: number;
  pageSize: number;
  filtered: Filter[];
}

interface ProductMapsProps {
  productMapsStore: ProductMapsStore;
  securityStore: SecurityStore;
}

@inject('productMapsStore')
@inject('securityStore')
@observer
class ProductMaps extends React.Component<ProductMapsProps, {}> {
  private fetchDataWithDebounce: Function;
  private handleSearchInputChangeWithDebounce: Function;
  private pageSize = 10;
  private pages = 0;
  private currentPage = 0;
  private activeRowId: number | null = null;
  private oldModel: ProductCodeMapModel | null;
  private securityPolicy: Record<string, boolean>;

  @observable private filtered: Filter[] = [];
  @observable private tableData: ProductCodeMapModel[] = [];
  @observable private filtering = false;
  @observable private isModalOpen = false;
  @observable private isAlertActive = false;
  @observable private editingModel: ProductCodeMapModel | null;
  @observable private editMode: boolean;
  @observable private searchValue: string;
  private previousSearchValue: string;

  constructor(props: ProductMapsProps) {
    super(props);
    this.fetchDataWithDebounce = _.debounce(this.fetchData, 1000);
    this.handleSearchInputChangeWithDebounce = _.debounce(this.handleSearchInputChange, 1000);
    this.editingModel = null;
    this.oldModel = null;
    this.editMode = false;
    this.searchValue = '';
    this.previousSearchValue = '';
    this.securityPolicy = {
      canViewProductCodeMap: this.props.securityStore!.hasPermission(
        Permissions.ProductCodeMapView
      ),
      canManageProductCodeMap: this.props.securityStore!.hasPermission(
        Permissions.ProductCodeMapManage
      ),
    };
  }

  public componentDidMount = async (): Promise<void> => {
    if (!this.securityPolicy.canViewProductCodeMap) {
      return;
    }

    const data = await this.props.productMapsStore!.searchProductMaps(
      this.filtered,
      this.pageSize,
      this.currentPage
    );
    this.updateTableDataAndPages(data.productCodeMaps, data.totalResults);
  };

  @action public updateTableDataAndPages(
    productCodeMaps: ProductCodeMapResponse[],
    totalResults: number | undefined
  ): void {
    const transformedData = productCodeMaps.map((p) => new ProductCodeMapModel({ ...p }));
    this.tableData.length = 0;
    this.tableData.push(...transformedData);
    if (totalResults) {
      this.pages = Math.ceil(totalResults / this.pageSize);
    }
  }

  public render(): React.ReactNode {
    if (!this.securityPolicy.canViewProductCodeMap) {
      return <NotAuthorised />;
    }

    const tableClass = `productmaps-table -striped -highlight ${
      this.editMode ? 'editable-table' : ''
    }`;
    return (
      <>
        <div className="container">
          <GridContainer>
            <GridItem className="add-code-map-container" xs={12}>
              {
                // tslint:disable-next-line: jsx-no-multiline-js
                this.securityPolicy.canManageProductCodeMap && (
                  <Button
                    disabled={this.props.productMapsStore!.isLoading}
                    className="action-button add-codemap-button"
                    type="button"
                    color="warning"
                    onClick={this.handleAddClick}
                  >
                    Add Code Map
                  </Button>
                )
              }
            </GridItem>
            <GridItem xs={12}>
              <Card className="card">
                <CardHeader color="primary" icon={true} className="card-header">
                  <CardIcon className="card-icon" color="warning">
                    <Icon icon={IconResources.PRODUCT_CODE_MAP} />
                  </CardIcon>
                  <div className="card-header-section">
                    <h4 className={'card-title'}>Available Product Code Maps</h4>
                    <FormInput
                      label=""
                      className="search-product-maps"
                      placeholder="Search Product Maps"
                      showAdornment={true}
                      validated={false}
                      showLabel={false}
                      handleInputChange={this.eventWrapper}
                      value={this.searchValue}
                      showClearIcon={this.searchValue.length > 0}
                      handleClearIconClick={this.handleSearchInputClear}
                    />
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.tableData.slice()}
                    loading={this.props.productMapsStore.isLoading}
                    noDataText={
                      this.tableData.length > 0 || this.props.productMapsStore.isLoading
                        ? ''
                        : 'No product code maps available.'
                    }
                    filterable={true}
                    columns={this.tableColumns}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className={tableClass}
                    collapseOnDataChange={false}
                    resizable={false}
                    onFetchData={this.fetchStrategy}
                    manual={true}
                    pages={this.pages}
                    pageSize={this.pageSize}
                    defaultPageSize={this.pageSize}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <ProductMapModal
          open={this.isModalOpen}
          handleAddProductMap={this.handleAddProductMap}
          handleClose={this.hideModal}
        />

        <CustomSweetAlert
          show={this.isAlertActive}
          hideAlert={this.hideAlert}
          title={'Are you sure?'}
          message={'Product Map will be permanently deleted.'}
          onSuccess={(): Promise<void> => this.handleRowDelete(this.activeRowId!)}
        />

        <Snackbar
          place="br"
          color={this.props.productMapsStore.snackbarType}
          // tslint:disable-next-line: jsx-no-lambda
          icon={(): JSX.Element => <Icon icon={IconResources.AddAlert} />}
          message={this.props.productMapsStore.snackbarMessage}
          open={this.props.productMapsStore.snackbar}
          // tslint:disable-next-line: jsx-no-lambda
          closeNotification={(): void => this.hideSnackbar()}
          close={true}
        />
      </>
    );
  }

  @action private handleSearchInputClear = (): void => {
    this.searchValue = '';
    this.handleSearchInputChange();
  };

  @action private eventWrapper = (e: React.ChangeEvent<HTMLInputElement>): Function | null => {
    const newValue = e.target.value;
    if (this.searchValue.trim().length === 0 && newValue.trim().length === 0) {
      return null;
    }
    this.searchValue = newValue;
    return this.handleSearchInputChangeWithDebounce(newValue);
  };

  @action private handleSearchInputChange = (): void => {
    if (this.previousSearchValue === this.searchValue) {
      return;
    }
    this.previousSearchValue = this.searchValue;
    const searchTerm = this.searchValue.trim();
    this.filtering = true;
    this.filtered.length = 0;
    const filtered = [{ id: 'all', value: searchTerm }];
    this.filtered.push(...filtered);
    this.fetchStrategy({
      page: this.currentPage,
      pageSize: this.pageSize,
      filtered: toJS(this.filtered),
    } as TableState);
  };

  @action private handleEdit = (rowProps: RowInfo): void => {
    if (this.editMode) {
      this.resetCellChanges();
      this.clearEditMode();
    }
    this.editingModel = rowProps.original;
    this.oldModel = new ProductCodeMapModel({ ...rowProps.original });
    this.editMode = true;
  };

  private handleCancel = (): void => {
    this.resetCellChanges();
    this.clearEditMode();
  };

  @action private resetCellChanges = (): void => {
    if (this.editingModel && this.oldModel) {
      const index = this.tableData
        .map((x) => x.productCodeMapId)
        .indexOf(this.editingModel.productCodeMapId);
      if (index > -1) {
        this.tableData[index] = new ProductCodeMapModel({ ...this.oldModel });
      }
    }
  };

  @action private clearEditMode = (): void => {
    this.oldModel = null;
    this.editingModel = null;
    this.editMode = false;
  };

  @action public handleSubmit = async (): Promise<void> => {
    const isModelValid = this.editingModel && this.editingModel.isValid();
    if (this.editMode && isModelValid && this.oldModel) {
      const index = this.tableData
        .map((x) => x.productCodeMapId)
        .indexOf(this.editingModel!.productCodeMapId);
      if (index > -1) {
        const result = await this.props.productMapsStore!.updateProductMap(this.editingModel!);
        if (result) {
          runInAction(() => {
            this.tableData[index] = new ProductCodeMapModel({ ...this.editingModel });
          });
        } else {
          runInAction(() => {
            this.tableData[index] = new ProductCodeMapModel({ ...this.oldModel });
          });
        }
      }
      this.clearEditMode();
    }
  };

  @action private handleBlur = (e: React.FocusEvent<HTMLDivElement>, cellInfo: CellInfo): void => {
    const newValue = e.target.innerText.trim();
    const data = [...this.tableData];
    data[cellInfo.index][cellInfo.column.id!] = newValue;
    this.editingModel![cellInfo.column.id!] = newValue;
    this.tableData.length = 0;
    this.tableData.push(...data);
  };

  private renderEditable = (cellInfo: CellInfo): JSX.Element => {
    const self = this;
    const editable =
      this.editMode &&
      (self.editingModel
        ? self.tableData[cellInfo.index].productCodeMapId === self.editingModel.productCodeMapId
        : false);

    const validationError =
      editable && self.tableData[cellInfo.index][cellInfo.column.id!].length === 0;
    const model = new ProductCodeMapModel({ ...self.tableData[cellInfo.index] });

    return (
      <EditableCell
        validationError={validationError}
        model={model}
        editable={editable}
        cellInfo={cellInfo}
        handleChange={(e, c) => this.handleBlur(e, c)}
      />
    );
  };

  private get tableColumns(): Column[] {
    const self = this;

    const actions = (rowProps: RowInfo): Record<string, Function> => ({
      onSubmit: this.handleSubmit,
      onEdit: (): void => this.handleEdit(rowProps),
      onCancel: (): void => this.handleCancel(),
      onDelete: (): void => this.handleRowDeleteClick(rowProps.original.productCodeMapId),
    });

    const columns: Column[] = [
      {
        id: 'supplierCode',
        Header: 'Supplier Code',
        accessor: 'supplierCode',
        width: 300,
        filterable: false,
        sortable: false,
        Cell: this.renderEditable,
      },
      {
        id: 'supplierItemCode',
        Header: 'Supplier Item Code',
        accessor: 'supplierItemCode',
        filterable: false,
        sortable: false,
        Cell: this.renderEditable,
      },
      {
        id: 'vulcanProductCode',
        Header: 'Vulcan Product Code',
        accessor: 'vulcanProductCode',
        sortable: false,
        filterable: false,
        Cell: this.renderEditable,
      },
      {
        id: 'vulcanProductTypeCode',
        Header: 'Vulcan Product Type Code',
        accessor: 'vulcanProductTypeCode',
        width: 300,
        sortable: false,
        filterable: false,
        Cell: this.renderEditable,
        headerStyle: { textAlign: 'left' },
      },
    ];

    if (this.securityPolicy.canManageProductCodeMap) {
      columns.push({
        id: 'actions',
        accessor: 'productCodeMapId',
        Header: 'Actions',
        width: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        headerStyle: { textAlign: 'center' },
        Cell: (rowProps: CellInfo): TableCellRenderer => {
          const editable =
            this.editMode &&
            (self.editingModel
              ? self.tableData[rowProps.index].productCodeMapId ===
                self.editingModel.productCodeMapId
              : false);
          return (
            <>
              <ActionsCell mode={editable ? 'edit' : 'view'} actions={actions(rowProps)} />
            </>
          );
        },
      });
    }

    return columns;
  }

  private fetchStrategy = (state: TableState): Promise<void> => {
    if (this.filtering) {
      return this.fetchDataWithDebounce(state);
    } else {
      return this.fetchData(state);
    }
  };

  @action private fetchData = async (state: TableState): Promise<void> => {
    if (this.props.productMapsStore!.isLoading) {
      return;
    }
    if (this.editMode) {
      this.clearEditMode();
    }
    this.filtering = false;
    const { page, pageSize, filtered } = state;

    this.currentPage = page;
    this.filtered.length = 0;
    this.filtered.push(...filtered);

    const data = await this.props.productMapsStore!.searchProductMaps(filtered, pageSize, page);
    this.updateTableDataAndPages(data.productCodeMaps, data.totalResults);
  };

  @action private handleAddClick = (): void => {
    this.isModalOpen = true;
  };

  @action private handleAddProductMap = async (productMap: ProductCodeMapModel): Promise<void> => {
    const newMap = await this.props.productMapsStore!.addProductMap(productMap);
    if (newMap) {
      if (this.editMode) {
        this.resetCellChanges();
        this.clearEditMode();
      }
      const data = await this.props.productMapsStore!.searchProductMaps(
        this.filtered,
        this.pageSize,
        this.currentPage
      );
      this.updateTableDataAndPages(data.productCodeMaps, data.totalResults);
    }
  };

  @action private handleRowDeleteClick = (productCodeMapId: number): void => {
    this.activeRowId = productCodeMapId;
    this.showAlert();
  };

  @action private handleRowDelete = async (productCodeMapId: number): Promise<void> => {
    if (!productCodeMapId) {
      this.hideAlert();
      return;
    }
    await this.props.productMapsStore!.deleteProductMap(productCodeMapId);
    const data = await this.props.productMapsStore!.searchProductMaps(
      this.filtered,
      this.pageSize,
      this.currentPage
    );
    this.updateTableDataAndPages(data.productCodeMaps, data.totalResults);
    this.activeRowId = null;
    this.hideAlert();
  };

  @action private hideModal = (): void => {
    this.props.productMapsStore!.setActiveModel();
    this.isModalOpen = false;
  };

  @action private showAlert = (): void => {
    this.isAlertActive = true;
  };

  @action private hideAlert = (): void => {
    this.isAlertActive = false;
  };

  @action private hideSnackbar = (): void => {
    this.props.productMapsStore.hideSnackbar();
  };
}

export default ProductMaps;
