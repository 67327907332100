import { BaseStore } from 'src/App/Infrastructure';
import { action, observable, runInAction } from 'mobx';
import { RequestHistoryTableDataRowModel } from './Models';
import { StringResources } from 'src/Shared/Constants';
import AlertType from 'src/Shared/Alert/AlertType';
import { RequestTableDataRowModel } from 'src/Reorder/Request/Models';
import { ExcelGenerator } from '@vulcan/component-lib/dist/utils';
import RequestTable from 'src/Reorder/Request/RequestTable';
import { formatEmailAsName, localeCompare, utcTimetoLocalTime, toFixedNumber } from 'src/Shared/Utils';
import { divide } from 'lodash';

export class RequestHistoryStore extends BaseStore {
  @observable public loading = false;
  @observable public tableData: RequestHistoryTableDataRowModel[] = [];
  @observable public pageNumber = 0;

  @action
  public async init(): Promise<void> {
    this.loading = true;

    const summaries = await this.getSummaries();
    runInAction(() => {
      this.tableData = summaries;
      this.loading = false;
    });
  }

  private async getSummaries(): Promise<RequestHistoryTableDataRowModel[]> {
    let summaries: RequestHistoryTableDataRowModel[] = [];
    try {
      const purchasingClient = await this.getPurchasingClient();
      const response = await purchasingClient.reorder.getConfirmedRequestSummaries();
      summaries = response
        .map((r) => {
          const confirmedByDate = r.confirmedDateTimeUtc
            ? utcTimetoLocalTime(r.confirmedDateTimeUtc)
            : undefined;

          return new RequestHistoryTableDataRowModel({
            ...r,
            id: r.confirmedReorderRequestId,
            reportName: confirmedByDate
              ? `ReorderRequest_${confirmedByDate.format('DD-MM-YYYY[_]hh-mm-A')}`
              : 'ReorderRequest_UnknownConfirmDate',
            confirmedByDate: confirmedByDate ? confirmedByDate.toString() : undefined,
            confirmedBy: formatEmailAsName(r.confirmedBy),
          });
        })
        .sort((a, b) => localeCompare(a.reportName, b.reportName));
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingReorderTableException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingReorderTableException);
    } finally {
      return summaries;
    }
  }

  @action
  public async exportExcel(row: RequestHistoryTableDataRowModel): Promise<void> {
    const historyItems = await this.getRequestHistoryItems(row.id);
    if (!historyItems.length) {
      return;
    }
    const generator = new ExcelGenerator<RequestTableDataRowModel>(
      'ReorderRequest',
      RequestTable.exportableTableColumns,
      historyItems,
      row.confirmedByDate
    );
    generator.generate();
  }

  @action public setPage(page: number): void {
    this.pageNumber = page;
  }

  private async getRequestHistoryItems(id: number): Promise<RequestTableDataRowModel[]> {
    let requestDetails: RequestTableDataRowModel[] = [];
    try {
      const purchasingClient = await this.getPurchasingClient();
      const response = await purchasingClient.reorder.getConfirmedRequest(id);
      requestDetails = response
        .entries!.map(
          (r) =>
            new RequestTableDataRowModel({
              ...r,
              productWeight: r.conversionFactor ? `${toFixedNumber(divide(1000, r.conversionFactor), 2)} KG` : '-',
              productGroup: r.productGroupName,
              orderingBranchCode: r.branchCode,
              stockClass: { code: r.stockClassificationCode, color: r.stockClassificationColour },
              availableStock: r.inStock,
              requestedBy: formatEmailAsName(r.requestedBy),
              amendedQuantity: r.amendedQuantity ? r.amendedQuantity : undefined,
              amendedBy: formatEmailAsName(r.amendedBy),
            })
        )
        .sort((a, b) => localeCompare(a.productCode, b.productCode));
    } catch (e) {
      this.log(`${StringResources.ErrorLoadingReorderTableException}`, e as Error);
      this.showAlert(AlertType.danger, StringResources.ErrorLoadingReorderTableException);
    } finally {
      return requestDetails;
    }
  }
}
export default RequestHistoryStore;
