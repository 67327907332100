import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  withStyles,
} from '@material-ui/core';

const TooltipWithNoPadding = withStyles({
  tooltip: {
    padding: 0,
  },
})(MuiTooltip);

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      borderBottom: '1px dotted',
      cursor: 'help',
      margin: '0 5px',
    },
  })
);

export interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  value: string;
}

export const Tooltip = (props: TooltipProps) => {
  const classes = useStyles();

  return (
    <TooltipWithNoPadding placement="top" {...props}>
      <Box className={classes.tooltip}>{props.value}</Box>
    </TooltipWithNoPadding>
  );
};
