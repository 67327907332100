import * as React from 'react';
import { inject, observer } from 'mobx-react';
import SupplierInvoiceStore from '../../SupplierInvoiceStore';
import SupplierInvoiceModel from '../../SupplierInvoiceModel';
import { ProgressBar } from '@vulcan/vulcan-materialui-theme';
import { isInternetExplorer } from '../../utils';
import { StringResources } from 'src/Shared/Constants/StringResources';
import NotSupported from './NotSupported';

interface PDFViewProps {
  selectedInvoice: SupplierInvoiceModel;
  supplierInvoiceStore?: SupplierInvoiceStore;
}

@inject('supplierInvoiceStore')
@observer
class PDFView extends React.Component<PDFViewProps, {}> {
  public componentDidMount = async (): Promise<void> => {
    await this.props.supplierInvoiceStore!.getSupplierInvoiceAttachment(
      this.props.selectedInvoice.documentId
    );
  };

  public componentWillUnmount(): void {
    this.props.supplierInvoiceStore!.setAttachmentUri(undefined, undefined);
  }

  public handleDownloadClick = (): void => {
    this.props.supplierInvoiceStore!.downloadPDFAttachment();
  };

  public render(): JSX.Element {
    if (this.props.supplierInvoiceStore!.loadingInvoiceAttachment) {
      return (
        <ProgressBar
          className="supplier-invoices-loading-bar"
          showProgressBar={true}
          type="circular"
        />
      );
    }

    const renderAttachment = (): JSX.Element => {
      const renderContentNotSupported =
        isInternetExplorer() &&
        this.props.supplierInvoiceStore!.attachmentMediaType === 'application/pdf';

      if (
        renderContentNotSupported ||
        !this.props.supplierInvoiceStore!.isSupportedAttachmentType(
          this.props.supplierInvoiceStore!.attachmentMediaType!
        )
      ) {
        return (
          <NotSupported
            attachmentUri={this.props.supplierInvoiceStore!.attachmentUri}
            invoiceNumber={this.props.selectedInvoice.invoiceNumber}
            handleDownloadClick={this.props.supplierInvoiceStore!.downloadPDFAttachment}
          />
        );
      }
      return (
        <object
          className="pdf-viewer"
          // the type attribute is mandatory
          type={this.props.supplierInvoiceStore!.attachmentMediaType}
          data={this.props.supplierInvoiceStore!.attachmentUri!}
          data-object-fit="none"
        >
          <NotSupported
            attachmentUri={this.props.supplierInvoiceStore!.attachmentUri}
            invoiceNumber={this.props.selectedInvoice.invoiceNumber}
            handleDownloadClick={this.props.supplierInvoiceStore!.downloadPDFAttachment}
          />
        </object>
      );
    };

    const renderUnexpectedError = (): JSX.Element => {
      return (
        <div className="unexpected-error-invoice-attachment">
          {StringResources.ErrorRetrievingInvoiceAttachment}
        </div>
      );
    };

    return (
      <div className="pdf-view-container">
        <div className="attachment-container">
          {this.props.supplierInvoiceStore!.attachmentMediaType
            ? renderAttachment()
            : renderUnexpectedError()}
        </div>
      </div>
    );
  }
}

export default PDFView;
