import * as React from 'react';
import { ListItem } from '@material-ui/core';
import { SupplierInvoiceLineItemResponse } from '@vulcan/purchasing-api-client/esm/models';

interface InvoiceLineItemProps {
  lineItem: SupplierInvoiceLineItemResponse;
}

const InvoiceLineItem = ({ lineItem }: InvoiceLineItemProps): JSX.Element => {
  return (
    <ListItem button={true} className="invoice-line-item-container">
      <div className="left-column">
        <div>
          <span>{lineItem.supplierItemCode}</span> <span>{lineItem.description}</span>
        </div>
        <div>
          <span>PO:</span> {lineItem.purchaseOrderNumber}
        </div>
        <div>
          <span>Confirmation:</span> {lineItem.supplierConfirmationNumber}
        </div>
      </div>
      <div className="right-column">
        <span>Qty: {lineItem.quantity}</span>
        <span>Amount: ${lineItem.totalAmount}</span>
      </div>
    </ListItem>
  );
};

export default InvoiceLineItem;
