import React from 'react';
import { inject, observer } from 'mobx-react';
import { StringResources } from 'src/Shared/Constants';
import ReactTable, { CellInfo, ReactTableDefaults } from 'react-table';
import { RequestHistoryStore } from './RequestHistoryStore';
import { formatDisplayDate, tableDateTimeSortMethod } from 'src/Shared/Utils';
import { Button } from '@vulcan/vulcan-materialui-theme';

interface RequestHistoryTableProps {
  requestHistoryStore?: RequestHistoryStore;
}

@inject('requestHistoryStore')
@observer
export class RequestHistoryTable extends React.Component<RequestHistoryTableProps> {
  private handlePageChange = (page: number): void => {
    this.props.requestHistoryStore!.setPage(page);
  };

  private ResetPageNumber = (): void => {
    this.props.requestHistoryStore!.setPage(0);
  };

  public render(): JSX.Element {
    const columns = [
      {
        id: 'reportName',
        Header: 'Report name',
        accessor: 'reportName',
        sortable: false,
      },
      {
        id: 'confirmedByDate',
        Header: 'Date confirmed',
        accessor: 'confirmedByDate',
        sortable: true,
        Cell: (cell: CellInfo): string => formatDisplayDate(cell.value),
        sortMethod: tableDateTimeSortMethod,
      },
      {
        id: 'confirmedBy',
        Header: 'Confirmed By',
        accessor: 'confirmedBy',
        sortable: true,
      },
      {
        id: 'download',
        sortable: false,
        width: 160,
        Cell: (cell: CellInfo): JSX.Element => (
          <Button
            className="action-button export-excel-button"
            type="button"
            color="warning"
            onClick={async (): Promise<void> =>
              await this.props.requestHistoryStore!.exportExcel(cell.original)
            }
          >
            Export
          </Button>
        ),
      },
    ];
    return (
      <ReactTable
        data={this.props.requestHistoryStore!.tableData.slice()}
        columns={columns}
        column={{
          ...ReactTableDefaults.column,
          style: { whiteSpace: 'unset' },
        }}
        loading={this.props.requestHistoryStore!.loading}
        noDataText={this.props.requestHistoryStore!.loading ? '' : StringResources.TableNoRowsFound}
        minRows={this.props.requestHistoryStore!.tableData.length > 0 ? 0 : 16}
        pageSize={14}
        page={this.props.requestHistoryStore!.pageNumber}
        onPageChange={this.handlePageChange}
        onSortedChange={this.ResetPageNumber}
        className="react-table -striped -highlight"
        filterable={false}
        sortable={true}
        defaultSorted={[{ id: 'confirmedByDate', desc: false }]}
        showPageJump={false}
        showPageSizeOptions={false}
        showPaginationTop={false}
        showPaginationBottom={true}
        showPagination={true}
        collapseOnDataChange={false}
        collapseOnSortingChange={false}
        collapseOnPageChange={false}
        resizable={false}
      />
    );
  }
}

export default RequestHistoryTable;
