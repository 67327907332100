import * as React from 'react';
import SupplierInvoiceModel from '../SupplierInvoiceModel';
import SupplierInvoiceStore from '../SupplierInvoiceStore';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { debounce } from '../utils';
import SupplierInvoicesListRenderer from './InvoiceCardList';
import InvoiceType from '../InvoiceType';
import EmptyInvoiceCardList from './EmptyInvoiceCardList';
import Filter from './Filter';
import { ProgressBar, FormInput } from '@vulcan/vulcan-materialui-theme';

interface ListSectionLayoutProps {
  supplierInvoiceStore?: SupplierInvoiceStore;
  type: InvoiceType;
}

@inject('supplierInvoiceStore')
@observer
export class ListSectionLayout extends React.Component<ListSectionLayoutProps, {}> {
  private fetchDataWithDebounce: Function;

  constructor(props: ListSectionLayoutProps) {
    super(props);
    this.fetchDataWithDebounce = debounce(this.props.supplierInvoiceStore!.loadSearchResults, 1000);
  }

  public async componentDidMount(): Promise<void> {
    const type = this.props.type === InvoiceType.Draft ? InvoiceType.Draft : InvoiceType.Complete;
    this.props.supplierInvoiceStore!.setActiveListType(type);
    await this.props.supplierInvoiceStore!.loadData(type);
  }

  public getSnapshotBeforeUpdate(prevProps: ListSectionLayoutProps): boolean | null {
    if (this.props.type !== prevProps.type) {
      return true;
    }
    return null;
  }

  public async componentDidUpdate(
    prevProps: ListSectionLayoutProps,
    prevState: {},
    snapshot: boolean
  ): Promise<void> {
    if (snapshot !== null) {
      this.props.supplierInvoiceStore!.init();
      const type = this.props.type === InvoiceType.Draft ? InvoiceType.Draft : InvoiceType.Complete;
      this.props.supplierInvoiceStore!.setActiveListType(type);
      await this.props.supplierInvoiceStore!.loadData(type);
    }
  }

  public render(): JSX.Element {
    return (
      <div className="supplier-invoices-container">
        <div className="supplier-invoices-list-header">
          <FormInput
            className="search-supplier-invoices"
            placeholder="Search Invoices"
            value={this.props.supplierInvoiceStore!.searchInputValue}
            showClearIcon={this.props.supplierInvoiceStore!.searchInputValue.length > 0}
            showAdornment={true}
            validated={false}
            showLabel={false}
            handleInputChange={this.handleSearchInputChange}
            handleClearIconClick={this.handleSearchInputClear}
          />
          <Filter type={this.props.type} />
          <ProgressBar
            className="supplier-invoices-list-progressbar"
            showProgressBar={this.props.supplierInvoiceStore!.isLoading}
            type="linear"
          />
        </div>

        <SupplierInvoicesListRenderer
          show={!this.props.supplierInvoiceStore!.showEmptyListMessage}
          handleInvoiceClick={this.handleInvoiceClick}
          type={this.props.type}
        />
        <EmptyInvoiceCardList show={this.props.supplierInvoiceStore!.showEmptyListMessage} />
      </div>
    );
  }

  @action private handleSearchInputClear = (): void => {
    this.props.supplierInvoiceStore!.clearSearchResults();
  };

  @action private handleSearchInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const self = this;
    self.props.supplierInvoiceStore!.searchInputValue = e.target.value;
    const searchTerm = e.target.value.trim();

    if (self.props.supplierInvoiceStore!.searching) {
      self.props.supplierInvoiceStore!.setSearchingProgress('active');
      await this.fetchDataWithDebounce(searchTerm, self.props.type);
    } else {
      // when the search input value is cleared, clear the search results.
      self.props.supplierInvoiceStore!.clearSearchResults();
      self.props.supplierInvoiceStore!.setSearchingProgress('completed');
    }
  };

  private handleInvoiceClick = (code: string): void => {
    const self = this;
    const selectedCode = code;
    let selectedInvoice: SupplierInvoiceModel;
    if (self.props.supplierInvoiceStore!.searching) {
      selectedInvoice = self.props.supplierInvoiceStore!.supplierInvoicesSearchResults.filter(
        (i) => i.supplierCode === selectedCode
      )[0];
    } else if (self.props.type === InvoiceType.Draft) {
      selectedInvoice = self.props.supplierInvoiceStore!.draftSupplierInvoices.filter(
        (i) => i.supplierCode === selectedCode
      )[0];
    } else {
      selectedInvoice = self.props.supplierInvoiceStore!.matchedSupplierInvoices.filter(
        (i) => i.supplierCode === selectedCode
      )[0];
    }
    self.props.supplierInvoiceStore!.setSelectedInvoice(selectedInvoice);
  };
}

export default ListSectionLayout;
