import * as React from 'react';

const NotSupported = ({ attachmentUri, invoiceNumber, handleDownloadClick }): JSX.Element => {
  return (
    <div className="content-not-supported">
      <p>
        Your browser cannot view this document, please click the &nbsp;
        <a onClick={handleDownloadClick} download={invoiceNumber} href={attachmentUri}>
          DOWNLOAD
        </a>
        &nbsp; link to download it.
      </p>
    </div>
  );
};

export default NotSupported;
