import * as React from 'react';
import moment from 'moment';

const DetailsViewHeader = ({ selectedInvoice }): JSX.Element => {
  return (
    <div className="supplier-invoice-details">
      <div>
        <span>
          <span className="item-header">Supplier:</span> {selectedInvoice.supplierCode}
        </span>
        <span>
          <span className="item-header">PO:</span>{' '}
          {selectedInvoice.lineItems[0] && selectedInvoice.lineItems[0].purchaseOrderNumber}
        </span>
        <span>
          <span className="item-header">Quantity:</span> {selectedInvoice.quantity}
        </span>
        <span>
          <span className="item-header">Total:</span> ${selectedInvoice.total}
        </span>
      </div>
      <div>
        <span>
          <span className="item-header">Due:</span>{' '}
          {moment(selectedInvoice.dueDate, 'YYYY/MM/DD').format('YYYY/MM/DD')}
        </span>
        <span>
          <span className="item-header">Invoice:</span> {selectedInvoice.invoiceNumber}
        </span>
        <span>
          <span className="item-header">Confirmation:</span>
          {selectedInvoice.lineItems[0] && selectedInvoice.lineItems[0].supplierConfirmationNumber}
        </span>
      </div>
    </div>
  );
};

export default DetailsViewHeader;
