import { useState, useCallback } from 'react';
import { getTenantUrl, getTokenAsync } from 'src/App/Infrastructure/API';

export interface StockClassifications {
  id: number;
  name: string;
  code: string;
  isDefault: boolean;
  colour: string;
}

export const useInventoryApi = () => {
  const [loading, setLoading] = useState(true);

  const getStockClassificationsAsync = useCallback(async (): Promise<StockClassifications[]> => {
    setLoading(true);
    const inventoryUrl = getTenantUrl(window.appSettings.inventoryApiUrl);
    const token = await getTokenAsync();

    const url = `${inventoryUrl}/api/v10/stock/available/stockClassifications`;
    const options = await fetch(url, {
      method: 'get',
      headers: {
        Authorization: `bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    try {
      const response = await fetch(url, options);
      const data = response.status === 200 ? await response.json() : [];
      return data as StockClassifications[];
    } catch (e) {
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  return { getStockClassificationsAsync, loading };
};
