import * as React from 'react';
import { FormInput } from '@vulcan/vulcan-materialui-theme';
import ProductMapsStore from './ProductMapsStore';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';

interface Props {
  productMapsStore?: ProductMapsStore;
  class?: string;
}

@inject('productMapsStore')
@observer
export class AddProductCodeMapForm extends React.Component<Props, {}> {
  public render(): JSX.Element {
    return (
      <div className={`add-product-map-form ${this.props.class}`}>
        <div className="product-map-form-body">
          <div>
            <h5>Supplier counter</h5>
            <FormInput
              label="Code"
              validated={true}
              handleInputValidation={this.props.productMapsStore!.activeModel.isSupplierCodeValid}
              placeholder="e.g. AMITYP"
              handleInputChange={this.handleSupplierCodeInputChange}
              defaultValue={this.props.productMapsStore!.activeModel.supplierCode}
            />
            <FormInput
              label="Item Code"
              validated={true}
              handleInputValidation={
                this.props.productMapsStore!.activeModel.isSupplierItemCodeValid
              }
              placeholder="e.g. SU-HRS040GG304P400+0600"
              handleInputChange={this.handleSupplierItemInputChange}
              defaultValue={this.props.productMapsStore!.activeModel.supplierItemCode}
            />
            <br />
            <h5>Vulcan</h5>
            <FormInput
              label="Product Code"
              validated={true}
              handleInputValidation={
                this.props.productMapsStore!.activeModel.isVulcanProductCodeValid
              }
              placeholder="e.g. HRS040020020GG304P400+0600"
              handleInputChange={this.handleVulcanProductCodeInputChange}
              defaultValue={this.props.productMapsStore!.activeModel.vulcanProductCode}
            />
            <FormInput
              label="Product Type Code"
              validated={true}
              handleInputValidation={
                this.props.productMapsStore!.activeModel.isVulcanProductTypeCodeValid
              }
              placeholder="e.g. HP04004040"
              handleInputChange={this.handleVulcanProductTypeCodeInputChange}
              defaultValue={this.props.productMapsStore!.activeModel.vulcanProductTypeCode}
            />
          </div>
        </div>
      </div>
    );
  }

  @action private handleSupplierCodeInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value.trim();
    this.props.productMapsStore!.activeModel.supplierCode = value;
  };

  @action private handleSupplierItemInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value.trim();
    this.props.productMapsStore!.activeModel.supplierItemCode = value;
  };

  @action private handleVulcanProductCodeInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value.trim();
    this.props.productMapsStore!.activeModel.vulcanProductCode = value;
  };

  @action private handleVulcanProductTypeCodeInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value.trim();
    this.props.productMapsStore!.activeModel.vulcanProductTypeCode = value;
  };
}

export default AddProductCodeMapForm;
